.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.textarea {
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 58px;
  padding-right: 5.5rem;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.promote-text {
  color: #677074;
  margin: 0;
  padding: 0;
}
