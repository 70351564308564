.requested-nft-container {
  border-radius: 10px;
  background: white;
  padding: 2rem;
  position: relative;
  border: 1px solid #e9ebeb;
}

.view-details-button {
  flex: 1;
}

.from-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.from-fullName-text {
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin: 0;
}

.view-details-container {
  margin-top: 2rem;
}

.received-on {
  color: #999999;
  font-size: 12px;
  margin: 0;
}

.actions-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.finished-status-container,
.pending-status-container,
.rejected-status-container {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 10;
}

.pending-status-container {
  background: #fff1cc;
}

.finished-status-container {
  background: #ccf0d6;
}

.rejected-status-container {
  background: #ff5252;
  color: white;
}

.pending-icon,
.finished-icon,
.rejected-icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rejected-icon {
  width: 8px;
}

.finished-icon {
  width: 18px;
}
