.explore-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  background: #fafafa;
}
.explore-header {
  border-bottom: 1px solid #e9ebeb;
}

.explore-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}
.explore-header,
.explore-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.explore-options-container {
  gap: 20px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.explore-option-text {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.explore-option-text-selected {
  color: #263238;
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .explore-page-container {
    display: flex;
    flex-direction: column;
  }
}
