.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thin-text {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.social-icon-name-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-icon {
  width: 16px;
}

.social-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 500;
}

.cover-photo-container {
  border-radius: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.camera-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.profile-camera-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 0px;
}

.cover-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-photo-image {
  width: 100%;
  height: 100%;
}
.profile-photo-container {
  overflow: hidden;
}
.profile-photo-container {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  margin: 20px 0;
}
.profile-photo-image {
  width: 120px;
  height: 120px;
}
.cover-photo-text {
  margin: 0;
  margin-top: 10px;
  margin-bottom: -5px;
}

.profile-photo-text {
  margin-top: 20px;
  margin-bottom: -20px;
}
.thin-text {
  color: black;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 2px;
}
