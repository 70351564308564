.brand-item-content-container {
  margin-top: 1rem;
  display: flex;
  align-items: stretch;
  gap: 20px;
  flex: 1;
}

.brand-item-image-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.brand-item-content-page-container {
  margin-top: 1rem;
  display: flex;
  align-items: stretch;
  gap: 20px;
  padding: 0.75rem 0.25rem;
  margin: auto 10%;
  margin-top: 10px;
}
.brand-item-image-container {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  border: 1px solid #e9ebeb;
  overflow: hidden;
}

.brand-item-image {
  width: 100%;
  height: 100%;
  max-width: 500px;
  min-width: 400px;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid #e9ebeb;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
  height: 5px;
}

.title-tags-collection-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-tags-container {
  padding: 1rem;
  flex: 1;
}
.title-text {
  color: #263238;
  font-weight: 600;
  margin: 0;
}
.title-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.tags {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.author-firstName,
.owner-firstName {
  color: #a8adaf;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.creator-text,
.owner-text {
  color: #677074;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.author-owner-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
  object-fit: cover;
}

.author-owner-container {
  display: flex;
  gap: 30px;
  position: relative;
}

.creator-container,
.owner-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.categories-text,
.tags-text {
  margin: 0;
  color: #263238;
}

.brand-item-information-container {
  flex: 1;
}

.tag {
  color: #a8adaf;
  padding: 0.25rem 0.4rem;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 54px;
}

.description-container {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.description {
  font-size: 16px;
  width: 97%;
  display: inline-block;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  color: #677074;
}

.description-container::-webkit-scrollbar {
  width: 4px;
}

.description-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.description-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.brand-item-actions-container {
  border-radius: 16px;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  margin-top: 30px;
}

.brand-item-price {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  margin-top: 5px;
  color: #ef00d7;
}

.usd-price {
  color: #263238 !important;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
}

.price-text {
  margin: 0;
  margin-top: 5px;
  color: #a8adaf;
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 1450px) {
  .buttons-container {
    flex-direction: column;
  }

  .buttons-container button {
    width: 100%;
    flex: 1;
  }
}

@media screen and (max-width: 1350px) {
  .brand-item-content-page-container {
    flex-direction: column;
    align-items: center;
  }

  .title-tags-container {
    flex: 0;
    min-width: 500px;
  }
}

@media screen and (max-width: 550px) {
  .brand-item-content-page-container {
    flex-direction: column;
    align-items: center;
  }

  .title-tags-container {
    flex: 1;
    min-width: 100%;
  }

  .brand-item-image {
    width: 300px;
  }
}
