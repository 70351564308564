.album-container {
  background-color: white;
  overflow: hidden;
  height: 180px;
  width: 180px;
  text-align: center;
  border: 1px solid #ffffff;
  position: relative;
}

.user-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.album-name {
  font-weight: 400;
  color: white;
  margin: 0;
}

.album-name-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 64px;
  padding: 0.3rem;
}

.empty-image {
  background-color: #eee;
}

.more-container {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.options {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  z-index: 11254365;
  padding-bottom: 0;
  min-width: 125px;
  position: absolute;
  right: 30px;
  /* padding: 0.2rem 1rem; */
}
.delete-text {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.25rem;
  cursor: pointer;
}

.view-album {
  padding: 0;
  margin: 0;
  padding: 0.25rem;
  cursor: pointer;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  cursor: pointer;
}
