.listings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 35px;
  row-gap: 10px;
}

.input-container {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
