.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.congrats-text {
  color: #f900b7;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.you-created-collection-text {
  color: #000000;
  margin: 5px 0 15px 0;
  opacity: 0.7;
}

.collection-links-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
  margin-bottom: 10px;
}

.created-collection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.link {
  text-decoration-line: underline;
  margin: 0;
  font-size: 12px;
  color: #f900b7;
}

.copy-icon {
  height: 13px;
  margin-left: 10px;
  cursor: pointer;
}

.qr-code-container {
  background: #ffffff;
  padding: 1rem;
  border: 1px solid #e9ebeb;

  border-radius: 16px;
  margin: 20px 0;
  position: relative;
}

.copied-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.4rem;
  width: 45%;
  margin-top: 10px;
}

.copied-text {
  padding: 0;
  margin: 0;
  color: #f900b7;
  font-weight: 500;
}

.twitter-icon-container,
.facebook-icon-container,
.linkedin-icon-container {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.twitter-icon,
.facebook-icon,
.linkedin-icon {
  height: 20px;
}

.share-icons-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.download-qr-code-container {
  position: absolute;
  right: -20px;
  bottom: -10px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.download-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
}
