.wallet-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  background: #fafafa;
}

.wallet-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 2rem;
}

.recent-sales-sidebar-container {
  padding: 1rem;
}

.wallet-text {
  font-size: 16px;
  color: black;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.wallet-content-container {
  background: white;
  border-radius: 10px;
  padding: 1rem;
  flex: 1;
}

.balance-text {
  color: black;
  font-weight: 500;
}

.balance-text,
.balance-usd,
.address,
.address-text,
.glimpse-balance-text {
  padding: 0;
  margin: 0;
}

.glimpse-balance-text {
  color: #ef00d7;
  font-weight: 500;

  margin-top: 5px;
}

.balance-usd,
.address {
  color: #a8adaf;
  font-weight: 400;
}

.address-text {
  margin-top: 15px;
  color: black;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 5px;
}

.horizontal-line {
  opacity: 0.05;
  border-bottom: 1.5px solid black;
  margin-top: 20px;
}

.deposit-send-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.wallet-deposit-send-container {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.wallet-deposit-buy-send-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
}

.wallet-header {
  border-bottom: 1px solid #e9ebeb;
  padding: 0.75rem 1rem;
}
.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.deposit-circle,
.send-circle {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 14px 30px rgba(249, 0, 183, 0.25);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  cursor: pointer;

  position: relative;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .deposit-container,
.send-container,
.metamask-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  align-items: center;
  padding: 1rem;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  flex: 1;
  padding: 0.75rem 2rem;
  align-self: stretch;
  min-width: 100px;
} */

.metamask-box-container {
  padding: 0.75rem 0.5rem;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 8px;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 100px;
}

.send-container {
  align-self: stretch;
}

.deposit-text,
.send-text,
.address-text,
.connect-metamask-text,
.install-metamask-text {
  color: black;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}

.transactions-container {
  background: white;
  border-radius: 10px;
  margin-top: 10px;
  padding: 1rem;
  border: 1px solid #e9ebeb;
}

.transactions-text {
  font-size: 16px;
  color: #263238;
}

.transactions-table {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.transactions-table p {
  color: #263238;
  font-weight: 500;

  padding: 0;
  margin: 0;
}
.table-horizontal-line {
  grid-column: 1/-1;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: 10px;

  margin-bottom: 15px;
}

.transaction {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin-bottom: 15px;

  align-items: center;
}

.transaction-action,
.nft-title {
  color: #000000;
  opacity: 0.4;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.nft-image {
  object-fit: cover;
  border-radius: 50%;
}

.green-price {
  color: #00b432 !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.red-price {
  color: #ff5252 !important;
  font-weight: 400;
}

.transaction-date {
  color: #000000;
  opacity: 0.4;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.getting-wallet-information-text {
  color: black;
  opacity: 0.7;
  font-weight: 500;
  margin: 1rem 0;
}

.copy-icon {
  cursor: pointer;
  height: 17px;
}

.coming-soon {
  opacity: 0.7;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: -5px;
}

.copied-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.4rem;
  width: 35%;
  margin-top: 10px;
}

.copied-text {
  padding: 0;
  margin: 0;
  color: #f900b7;
  font-weight: 500;
}

.you-can-copy {
  opacity: 1;
  font-weight: 500;
}

.vertical-line {
  opacity: 0.1;
  border: 1px solid #000000;
  background: black;
  transform: rotate(-180deg);
}

.signature-signed-text {
  margin: 0;
  font-weight: 500;
  color: #00b432;
}

.not-signed-text {
  margin: 0;
  font-weight: 500;
  color: #ff5252;
}

.fee-price {
  font-size: 10px;
  color: black !important;
  opacity: 0.4;
  font-size: 400;
  padding: 0;
  margin: 0;
}

.pending-offers-text,
.requested-nfts-text {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: #999999 !important;
}

.pending-offers-value,
.requested-nfts-value {
  color: #ef00d7;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}

.small-horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  height: 5px;
}

.balances-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 1rem;
}

/* .locked-balance-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
} */

.balances-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.available-balance-container,
.locked-balance-container,
.pending-balance-container,
.deposit-container,
.send-container,
.buy-container {
  background: #f8f8f9;
  border-radius: 12px;
  padding: 1rem;
  height: 140px;
}

.available-balance-text,
.locked-balance-text,
.pending-balance-text {
  color: #263238;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.pending-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.deposit-container,
.send-container,
.buy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.deposit-text,
.send-text,
.buy-text {
  color: #263238;
  font-weight: 500;
  margin: 0;
  margin-top: 10px;
}

.deposit-icon {
  height: 20px;
}

.send-icon {
  height: 22px;
}

.buy-icon {
  height: 20px;
}

.transactions-sections {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 1rem;
  margin-bottom: 0;
}

.transactions-sections p {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.selected-section {
  color: #263238;
  font-weight: 500;
}

@media only screen and (max-width: 1480px) {
  .wallet-deposit-send-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1280px) {
  .balances-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 1280px) {
  .pending-options-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 900px) {
  .wallet-page-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 675px) {
  .vertical-line {
    display: none;
  }
  .deposit-send-container {
    flex-direction: column;
  }
  .deposit-container {
    width: 100%;
  }

  .address-text {
    flex-direction: column;
  }

  .wallet-content-container {
    width: 95vw;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 550px) {
  .balances-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .pending-options-container {
    flex-direction: row;
  }
}
