.members-list {
  overflow-y: auto;
  height: 350px;
  margin-top: 10px;
  padding-right: 6px;
}

.members-list::-webkit-scrollbar {
  width: 7px;
}

.members-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.members-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
