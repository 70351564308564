.topic-container {
  padding: 0.5rem;
  cursor: pointer;
  padding-bottom: 0;
}

.selected-topic {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 6px;
}

.hashtag-topic {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hashtag-icon {
  align-self: flex-start;
  height: 15px;
  margin-top: 5px;
}

.topic-text {
  font-size: 16px;
  color: #677074;
  opacity: 1;
  font-weight: 500;
  margin: 0;
  margin-bottom: 5px;
}

.number-of-posts {
  color: #a8adaf;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1px solid #000000;
  margin-bottom: 0;
}
