.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.user-sidebar-container {
  padding: 1rem;
  padding-left: 2rem;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.nft-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.horizontal-line {
  width: 100%;

  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.section-container:first-child {
  margin-left: 30px;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.categories-sorting-container {
  margin-top: 20px;

  display: flex;
  align-items: center;

  gap: 15px;
}

.categories-container {
  background: white;
  border-radius: 42px;
  align-items: center;
  padding: 0.75rem;
  width: 200px;
  border: 1px solid #e9ebeb;
}

.category-text {
  color: #999999;
  font-weight: 400;
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 0.2rem;
  cursor: pointer;
}

.categories {
  display: grid;
  grid-template-columns: repeat(6, minmax(min-content, 110px));

  column-gap: 20px;
}

.arrow-left,
.arrow-right {
  cursor: pointer;
}

.create-reward-monetize-container {
  margin-right: 5%;
}

.marketplace-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  row-gap: 30px;
}
.no-nfts-found-text {
  color: #999999;
}

.sorting-container {
  background: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  flex-basis: 15%;
  padding: 0.75rem;
  position: relative;
  border: 1px solid #e9ebeb;
  border-radius: 42px;
  z-index: 7;
}

.sort-options {
  position: absolute;
  top: 48px;
  left: 0;
  background: white;
  border-radius: 12px;
  width: 100%;
  padding: 1rem 0.5rem;
  z-index: 1000;
  overflow: hidden;
}

.sorting-text {
  margin: 0;
  padding: 0;
  width: 100%;

  color: black;
  font-weight: 500;
  font-size: 14px;
}

.sort-option {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
  padding: 0.4rem;
  transition: all 0.2s ease;
}

.sort-option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.input-container {
  margin: 1rem auto;
  display: flex;
  align-items: center;
  flex: 1;
}

.selected-category-option-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.category-value {
  padding: 0;
  margin: 0;
  color: #677074;
}
.selected-category-value {
  color: #263238 !important;
  font-weight: 500 !important;
}
.categories-container {
  position: relative;
  min-width: 230px;
}
.category-options-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
  height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.category-options-container::-webkit-scrollbar {
  width: 5px;
}

.category-options-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.category-options-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.sort-option:not(:last-child) {
  margin-bottom: 10px;
}

.arrows-categories {
  display: flex;
  align-items: center;
  gap: 10px;
}

.featured-all-filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 40px;
  padding: 0.75rem;
  border: 1px solid #e9ebeb;
}

.featured-filter-container,
.all-filter-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.featured-filter-container p,
.all-filter-container p {
  padding: 0;
  margin: 0;
  color: #000000;
  font-weight: 500;
  opacity: 0.4;
  cursor: pointer;
}
.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
}

.search-featured-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-input {
  background: #f4f5f5;
  border-radius: 121px;
  padding: 0.5rem 1rem;
  border: none;
  border: 1px solid transparent;
  flex: 1;
}

.search-input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

@media only screen and (max-width: 1430px) {
  .marketplace-list {
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .search-featured-container {
    flex-wrap: wrap;
  }

  .sorting-container {
    flex: 1;
  }
}
