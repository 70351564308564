.image {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.album-photos-container {
  padding: 1rem;
}

.more-icon-container {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
}

.options {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  z-index: 11;
  padding-bottom: 0;
  min-width: 125px;
  position: absolute;
  right: 30px;
  top: 0;
}

.delete-text {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.5rem;
  cursor: pointer;
}
