.nft-collections-container {
  margin-top: 20px;
}

.collections-list {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  margin-top: 15px;
  border-radius: 12px;
}
