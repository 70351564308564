.link-your-bank-text {
  margin: 0;
  color: #a8adaf;
  font-weight: 400;
  height: 310px;
}

.stripe-account-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
