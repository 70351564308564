.tagged-users-container {
  overflow-y: auto;
  height: 500px;
  padding-right: 0.2rem;
}

.tagged-users-container::-webkit-scrollbar {
  width: 7px;
}

.tagged-users-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.tagged-users-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
