.unique-owners-list {
  height: 400px;
  overflow-y: auto;
  padding-right: 6px;
}

.unique-owners-list::-webkit-scrollbar {
  width: 7px;
}

.unique-owners-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.unique-owners-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
