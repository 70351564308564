.button {
  border: none;
  cursor: pointer;
}

.pink {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  border-radius: 6px;
  color: white;
  padding: 0.5rem 5rem;
  width: 100%;
}

.pink-color,
.pink-gray-disabled-color {
  background: transparent;
  color: #f900b7;
  border: 1px solid #f900b7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-weight: 500;
}

.reject {
  background: transparent;
  color: #ff5252;
  border: 1px solid #ff5252;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  font-weight: 500;
}

.gray {
  background: rgba(153, 153, 153, 0.2);
  color: #999999;
  border-radius: 16px;
  font-weight: 500;
}

.button:disabled {
  background: #999999;
  cursor: not-allowed;
}

.pink-color:disabled {
  background: #999999;
  cursor: not-allowed;
  border: none;
  color: white;
}

.pink-gray-disabled-color:disabled {
  background: #f8f8f8;
  border: none;
  cursor: not-allowed;
  color: #999999;
  opacity: 0.5;
}

.opacity-pink {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  color: #f900b7;
  font-weight: 500;
}

.pink-color-white-background {
  background: white;
  color: #f900b7;
  font-weight: 500;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  border: 1px solid #f900b7;
}

.modern-pink {
  background: linear-gradient(180deg, #ff7cdc 0%, #f900b7 100%);
  box-shadow: 0px 14px 30px rgba(249, 0, 183, 0.25);
  border-radius: 16px;
  padding: 0.5rem 2rem;
  color: white;
}

.modern-pink:disabled {
  box-shadow: none;
}

.modern-pink-color {
  background: linear-gradient(
    180deg,
    rgba(255, 124, 220, 0.2) 0%,
    rgba(249, 0, 183, 0.2) 100%
  );
  border-radius: 16px;
  color: #f900b7;
  padding: 0.5rem 2rem;
}

.modern-pink-color:disabled {
  color: white;
}

.dark-pink {
  background: #ef00d7;
  border-radius: 17px;
  color: white;
  padding: 0.5rem 2rem;
}

.dark-pink-color {
  background: white;
  color: #f900b7;
  font-weight: 500;
  border-radius: 17px;
  padding: 0.5rem 2rem;
  border: 1px solid #f900b7;
}

.dark-pink-color:disabled {
  background: #f8f8f8;
  border: 1px solid #999999;
  cursor: not-allowed;
  color: #999999;
  opacity: 0.5;
}
