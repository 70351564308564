.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.poll-answers-container {
  height: 210px;
  overflow-y: auto;
  padding-right: 10px;
}

.poll-answers-container::-webkit-scrollbar {
  width: 7px;
}

.poll-answers-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.poll-answers-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.poll-duration-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.duration-container {
  background: rgba(249, 0, 183, 0.1);
  border-radius: 16px;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.duration-value {
  color: rgba(249, 0, 183, 0.4);
  margin: 0;
}

.answers-sections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin: 0 10%;
}

.answers-section {
  margin: 0;
  padding: 0;
  color: #000000;
  opacity: 0.4;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
  margin-bottom: 10px;
}
