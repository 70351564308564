.no-cover-photo {
  width: 100%;
  height: 300px;
  background: white;
  /* opacity: 0.4; */
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.2));
  border-radius: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem;
  background: white;
  position: relative;
}

.name-button-options {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.group-name {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 1;
  color: #ffffff;
  font-size: 18px;
}

.members-text {
  color: #b2b2b2;
  padding: 0;
  margin: 0;
  cursor: pointer;
  margin-right: 10px;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.members-privacy-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.group-privacy {
  padding: 0;
  margin: 0;
  color: black;
  opacity: 0.7;
}

.user-image {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
}

.user-image:not(:last-child) {
  margin-left: -10px;
}

.profile-images-container {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.empty-space {
  flex-basis: 50%;
  width: 400px;
}

.add-cover-photo-btn {
  background: #ffffff;
  border: 1.5px solid #f900b7;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.5rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
  width: 300px;
  z-index: 14;
}

.add-cover-photo-text {
  color: #f900b7;
  padding: 0;
  margin: 0;
  z-index: 100;
  opacity: 1;
}

.plus-icon {
  margin-bottom: 2px;
}

.cover-photo-container {
  position: relative;
}
.cover-photo {
  width: 100%;
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
}

.cover-photo-camera-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cover-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.user-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;

  margin-left: 30px;
  margin-top: -60px;
  z-index: 100;
}

.horizontal-line {
  width: 100%;

  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

.group-sections {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
  margin-top: 20px;
  z-index: 100;
}

.horizontal-line {
  width: 100%;

  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.group-information {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;

  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.discussion-about-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 1360px) {
  .selected-group-container {
    grid-column: 1/-1;
  }
}
