.discover-friends-container {
  /* background: #f8f8f8; */
  gap: 20px;
  min-height: 91.5vh;
}

.user-sidebar-container {
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
}

.discover-friends-content-container,
.recent-sales-sidebar-container {
  padding-top: 2rem;
}
.discover-friends-content-container {
  max-width: calc(100vw - 810px);
}

.recent-sales-sidebar-container {
  padding: 1rem;
  padding-right: 2rem;
}

.discover-friends {
  margin: 2rem;
  margin-left: 0;
}

.people-you-might-know {
  border-radius: 16px;
  padding: 0;
  min-height: 500px;
}

.suggestions-arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.people-text,
.no-suggestions-found {
  text-align: center;
  font-weight: 500;
  color: #263238;
}

.people-text {
  text-align: left;
}

.recently-registered-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
}

.recently-registered-text {
  font-weight: 500;
  margin: 30px 0;
}

.user-suggestions-container {
  display: flex;
  align-items: center;
}

.go-left-container,
.go-right-container {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  position: relative;
}

.go-left-icon,
.go-right-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .discover-friends-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .discover-friends-content-container {
    grid-row: 2/3;
    grid-column: 1/-1;
    padding: 2rem;
    max-width: 98vw;
  }

  .user-sidebar-container {
    width: 100vw;
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  .discover-friends-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .discover-friends-content-container {
    grid-row: 3/4;
    grid-column: 1/-1;
    padding: 2rem;
  }
}

@media only screen and (max-width: 550px) {
  .discover-friends-content-container {
    padding: 0;
  }
}

@media only screen and (max-width: 550px) {
  .recent-sales-sidebar-container {
    margin: 0 auto;
    padding: 0;
  }
}

.no-suggestions-icon {
  width: 200px;
  object-fit: cover;
}

.no-suggestions-page {
  margin: 2rem;
  margin-bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-suggested-text {
  color: #000000;
  opacity: 0.4;
  margin-top: 20px;
}

@media only screen and (max-width: 1300px) {
  .discover-friends-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1300px) {
  .go-left-container,
  .go-right-container {
    display: none;
    width: 0;
  }
  .suggestions-arrows {
    display: block;
    text-align: center;
  }

  .user-suggestions-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 50px;
  }
  .user-suggestions-container > * {
    margin: 0 !important;
  }

  .people-you-might-know {
    display: none;
    width: 0;
  }
}

@media only screen and (max-width: 900px) {
  .people-you-might-know {
    margin: 0;
  }

  .discover-friends {
    margin: 0;
    margin-top: 1rem;
  }
}
