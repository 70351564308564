.login-container {
  display: flex;
}

.login-form {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-basis: 48%;
}

.form-container {
  background: white;
  min-width: 50%;
}

.signup-link,
.signup-link:hover {
  color: #f900b7;
  border-bottom: 1px solid #f900b7;
}

.first-last {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.email,
.password,
.username {
  margin-bottom: 20px;
}

.password {
  position: relative;
}

.hidden-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}

.create-account-container {
  color: black;
  margin-bottom: 20px;
}

.create-account-container h2 {
  color: #263238;
  margin: 0;
  padding: 0;
}

.forgot-password,
.signup-text {
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;

  color: #ef00d7;
}
.forgot-password-signup-container {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
}
.empty-space {
  height: 10px;
}

.login-google {
  color: black;
  opacity: 0.7;
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.paragraph {
  color: black;
  opacity: 0.7;
}

.agree {
  font-size: 12px;
  margin-left: 5px;
}

.info {
  flex: 1;
  position: relative;
  background: linear-gradient(
    103.45deg,
    rgba(255, 126, 161, 0.2) 4.26%,
    rgba(246, 67, 198, 0.2) 30.75%,
    rgba(160, 116, 242, 0.2) 55.56%,
    rgba(113, 185, 216, 0.2) 85%
  );
  padding-left: 5rem;
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}

.login-image-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info-center {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glimpse-vector {
  margin-bottom: 30px;
  margin-left: 8%;
}

.info-center > * {
  color: white;
}

.through {
  margin-top: 30px;
  opacity: 0.7;
  color: #ffffff;
}

.facebook-google-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.facebook-icon {
  height: 18px;
  margin-right: 5px;
  object-fit: cover;
}

.signup-metamask-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.08); */
  padding: 0.6rem 1.7rem;
  margin-top: 10px;
  cursor: pointer;

  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 67px;
}

.metamask-icon {
  height: 20px;
}

.signup-metamask-text {
  color: #a8adaf;
  font-weight: 400;
  opacity: 1;
  margin: 0;
}

.glimpse-logo {
  z-index: 2;
  opacity: 1;
  height: 40px;
}

.apply-as-creator-container {
  background: #ffffff;
  border-radius: 67px;
  border: 1px solid rgba(255, 255, 255, 1);
  width: 160px;
  text-align: center;
  padding: 0.5rem 1rem;
}

.apply-as-creator-text {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  padding: 0;
}

.info-header {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: flex-start;
  position: absolute;
  top: 2rem;
  left: 5rem;
}

.info-description {
  width: 70%;
}

.info-description h3 {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
  font-size: 62px;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
.info-description p {
  color: #263238;
  margin: 20px 0;
  padding: 0;
  font-size: 20px;
}

.app-store-icon {
  height: 50px;
  object-fit: cover;
}
.play-store-icon {
  height: 54px;
  object-fit: cover;
}
.app-store-play-store-container {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
}

@media only screen and (max-width: 1580px) {
  .info-description h3 {
    font-size: 59px;
  }

  .info-description p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1520px) {
  .info-description h3 {
    font-size: 55px;
  }
}

@media only screen and (max-width: 1300px) {
  .login-container {
    flex-direction: column;
    height: 200vh;
  }

  .form-container {
    padding: 3rem;
    width: 50%;
  }

  .info {
    height: 100%;
  }

  .info-description {
    margin-top: 5%;
  }

  .info-description h3 {
    font-size: 72px;
  }

  .info-description p {
    font-size: 26px;
    margin: 10% 0;
  }
}

@media only screen and (max-width: 400px) {
  .login-container {
    flex-direction: column;
    height: 200vh;
  }

  .form-container {
    padding: 1rem;
    min-width: 300px;
  }

  .info {
    height: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .info-description h3 {
    font-size: 56px;
  }

  .info-description p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 850px) {
  .facebook-google-container {
    flex-direction: column;
  }

  .form-container {
    width: 60%;
    padding: 1rem;
  }

  .info-description {
    width: 90%;
  }
}

@media only screen and (max-width: 650px) {
  .form-container {
    width: 70%;
    padding: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .info-description h3 {
    font-size: 40px;
  }

  .app-store-play-store-container {
    flex-direction: column;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 500px) {
  .info-description h3 {
    font-size: 36px;
  }
  .info-description p {
    font-size: 18px;
  }

  .info {
    padding-left: 1rem;
  }

  .info-header {
    left: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .login-form {
    padding: 0rem;
  }

  .form-container {
    min-width: 95vw;
    margin: 0;
  }

  .glimpse-vector {
    margin-bottom: 30px;
    margin-left: 0;
  }

  .info-description h3 {
    font-size: 40px;
  }
  .info-description p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 330px) {
  .info-description h3 {
    font-size: 34px;
  }
  .info-description p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2500px) {
  .info-description h3 {
    font-size: 80px;
  }

  .info-description p {
    font-size: 24px;
  }

  .info-description {
    padding: 1rem;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2800px) {
  .info-description h3 {
    font-size: 100px;
  }

  .info-description {
    padding: 1rem;
  }

  .info-description p {
    font-size: 24px;
  }
}
