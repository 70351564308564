.introduction-page-container {
  max-height: 180px;
  overflow-y: auto;
  padding-right: 3px;
}

.introduction-page-container::-webkit-scrollbar {
  width: 5px;
}

.introduction-page-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.introduction-page-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.introduction-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.introduction-text {
  font-size: 16px;
  color: #263238;
}

.introduction-text,
.edit,
.introduction {
  padding: 0;
  margin: 0;
}

.edit {
  cursor: pointer;
  text-decoration-line: underline;
  color: #a8adaf;
}

.introduction {
  color: #677074;
  margin-top: 10px;

  white-space: pre-line;
  overflow-wrap: break-word;
}
