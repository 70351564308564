.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mutual-followers-list {
  margin-top: 20px;
  overflow-y: auto;
  height: 400px;
  padding-right: 0.4rem;
}

.mutual-followers-list::-webkit-scrollbar {
  width: 7px;
}

.mutual-followers-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mutual-followers-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
