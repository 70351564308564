.selected-group-container {
  margin-top: 1rem;
}
.no-cover-photo {
  width: 100%;
  height: 300px;
  background: white;
  opacity: 0.4;
  border-radius: 14px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column-reverse;
  padding: 0.5rem;
  background: white;
}

.name-button-options {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 2%;
}

.button-options {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-right: 10px;
}

.group-name {
  padding: 0;
  margin: 0;
  color: white;
}

.members-text {
  padding: 0;
  margin: 0;
  color: #b2b2b2;
  cursor: pointer;
  margin-right: 5px;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.members-privacy-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2%;
  margin-right: 5%;
}

.group-privacy {
  padding: 0;
  margin: 0;
  color: black;
  opacity: 0.7;
}

.user-image {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}

.user-image:not(:last-child) {
  margin-left: -10px;
}

.profile-images-container {
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: 2%;
}
.empty-space {
  flex-basis: 50%;
  width: 400px;
}

.add-cover-photo-btn {
  background: #ffffff;
  border: 1.5px solid #f900b7;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.5rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
  width: 250px;
  z-index: 14;
}

.add-cover-photo-text {
  color: #f900b7;
  padding: 0;
  margin: 0;
  z-index: 100;
  opacity: 1;
}

.plus-icon {
  margin-bottom: 2px;
}

.cover-photo-container {
  position: relative;
}
.group-information {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  padding: 10px 0;
}
.cover-photo {
  width: 100%;
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
}

.cover-photo-camera-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cover-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.user-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 30px;
  margin-top: -60px;
  z-index: 100;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

.group-sections {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
  z-index: 100;
  flex-wrap: wrap;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.menu-options-container {
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ef00d7;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
}

.share-icons-container {
  position: absolute;
  bottom: 20px;
  right: 150px;
  padding: 1rem;
  background: white;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 100;
}
.share-icon {
  cursor: pointer;
}

.more-options {
  background: white;
  border-radius: 8px;
  position: absolute;
  top: 25px;
  right: 22px;
  width: 200px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  z-index: 100;
  padding: 0.6rem;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  cursor: pointer;
  color: black;
  font-weight: 400;
  padding: 0;
  padding: 0.4rem;
  margin: 0;
}

.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.leave {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .single-section-container {
    margin-left: 0;
    margin-right: 0;
  }

  .group-information {
    bottom: 10px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .user-image {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2800px) {
  .selected-group-container {
    width: 1100px;
    margin: 0 auto;
  }

  .single-section-container {
    max-width: 1100px;
  }
}
