.friend-container {
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  border-radius: 10px;
  padding: 0.25rem;
  position: relative;
  width: 250px;
  height: 150px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

.friend-photo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}
.follower-cover-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.friend-fullName {
  color: white;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  z-index: 1;
}

.photo-fullName-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.username {
  color: #ffffff;
  padding: 0;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.more-icon-container {
  position: absolute;
  right: 6px;
  top: 6px;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.more-options-container {
  background: white;
  border-radius: 8px;

  position: absolute;
  top: 20px;
  right: 22px;
  width: 200px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  z-index: 100;
  padding: 0.6rem;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  cursor: pointer;
  color: black;
  font-weight: 400;
  padding: 0;
  padding: 0.4rem;
  margin: 0;
}

.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}
