.top-messages-sidebar-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px 0px 0px 0px;
  width: 300px;
  position: fixed;
  height: 100%;
  top: 1rem;
  right: 0;
}
.bottom-messages-sidebar-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px 0px 0px 0px;
  width: 300px;
  position: fixed;
  bottom: 0rem;
  right: 0;
}

.messages-text-icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
}

.messages-icon {
  width: 20px;
  height: 20px;
}

.messages-text {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.up-down-icon {
  width: 21px;
  margin-left: auto;
  cursor: pointer;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.chat-sections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.chat-section {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.4);
}

.chats {
  padding: 0.1rem;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #263238 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.section-text {
  color: rgba(0, 0, 0, 0.4);

  padding: 0;
  margin: 0;
  display: flex;
  gap: 5px;
}

.chat-list {
  height: 80vh;
  overflow-y: scroll;
}

.chat-list::-webkit-scrollbar {
  width: 7px;
}

.chat-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.chat-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}
.more-icon {
  padding: 0;
  margin: 0;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 67%;

  transform: translateY(-70%);
  z-index: 3;
}

.no-chats {
  padding: 1rem 0;
  opacity: 0.7;
}

.number-of-unread-messages {
  background: rgba(159, 132, 132, 0.1);
  opacity: 1 !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}

.number-of-unread-messages-text {
  color: #263238 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  opacity: 1 !important;
}

.total-unread-messages-container {
  background: #ef00d7;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin-right: auto;
}

.unread-messages-up-icon-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 15px;
}

.total-unread-messages-container p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
}

@media screen and (max-width: 900px) {
  .top-messages-sidebar-container,
  .bottom-messages-sidebar-container {
    position: relative;
  }
}
