.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-selected-users-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  padding: 0.1rem 0.1rem;
  height: 50px;
  border-radius: 10px;
}

.to-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  margin-right: 10px;
}

.selected-user-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.selected-user-text {
  font-weight: 400;
  color: #f900b7;
  padding: 0;
  margin: 0;
}

.users-container {
  background: white;

  border-radius: 6px;
  min-width: 300px;
  position: absolute;
  top: 51px;
  left: 80px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 1rem 0.4rem;
  max-height: 200px;
  overflow-y: auto;
}

.users-container::-webkit-scrollbar {
  width: 7px;
}

.users-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.users-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-fullName {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 0.3rem;
  transition: all 0.2s ease;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
}

.no-users-found {
  color: black;
  padding: 0;
  margin: 0;
}

.input-send-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.selected-user-information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-10%);

  height: 80%;
}

.selected-user-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;
}

.selected-user-fullName {
  font-weight: 500;
  color: black;

  padding: 0;
  margin: 0;
}

.start-message {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
}

.selected-users-to-invite-as-collaborator-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 2px;
}
