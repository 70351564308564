.chat-container {
  cursor: pointer;
}
.chat-container:not(:last-child) {
  margin-bottom: 15px;
  padding: 0.5rem;
}
.chat-container:first-child {
  margin-top: 10px;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
}

.other-user-fullName {
  font-weight: 500;
  color: #263238;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.last-message-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.last-message,
.last-message-date {
  color: #a8adaf;
  font-weight: 400;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.dot-icon {
  width: 4px;
  height: 4px;
  margin-right: 5px;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-image {
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}

.profile-image:not(:last-child) {
  margin-left: -10px;
}

.profile-images-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.read-icons-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.half-read-icon {
  margin-left: -5px;
}

.pink-circle {
  background: #f900b7;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.unread-messages {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;

  background: #ef00d7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
}

.unread-messages-text {
  font-size: 12px;
  padding: 0;
  margin: 0;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
}

.heart-icon {
  align-self: center;
}
