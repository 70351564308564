.nft-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.nft-image-container {
  position: relative;
}

.add-to-watchlist-container {
  position: absolute;
  top: 3px;
  right: 3px;
  background: rgba(38, 50, 56, 0.5);
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.remove-watchlist-container {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #ef00d7;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
