.channel-name {
  color: #000000;
  opacity: 1;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
}

.header {
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
}

.info-icon {
  cursor: pointer;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}
.user-image:not(:last-child) {
  margin-left: -15px;
}

.members-list {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  align-items: center;
}

.more-options {
  background: white;
  border-radius: 8px;

  position: absolute;
  top: 20px;
  right: 2px;
  width: 200px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  z-index: 100;
  padding: 0.6rem;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  cursor: pointer;
  color: black;
  font-weight: 400;
  padding: 0;
  padding: 0.4rem;
  margin: 0;
}

.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.delete {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.5rem;
  cursor: pointer;
}
