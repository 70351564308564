.empty-chat-container {
  background: white;
  /* border-bottom-right-radius: 10px; */
  padding: 1rem;
  border-left: 1px solid #e9ebeb;
  height: 100%;
}

.horizontal-line {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

.input-users-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #f4f5f5;
  padding: 0.3rem 0.8rem;
  height: 50px;
  border-radius: 30px;
}
.to-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  margin-right: 10px;
}

.remove-icon-container {
  cursor: pointer;

  padding: 0;
  margin: 0;
}

.selected-user-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.selected-user-text {
  font-weight: 400;
  color: #f900b7;
  padding: 0;
  margin: 0;
}

.friends-container {
  background: white;
  filter: drop-shadow(0px 1.5px 5px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  min-width: 300px;
  position: absolute;
  top: 50px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 1rem 0.4rem;
  max-height: 200px;
  overflow-y: auto;
}

.friends-container::-webkit-scrollbar {
  width: 7px;
}

.friends-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.friends-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.follower-fullName {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.friend-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 0.3rem;
  transition: all 0.2s ease;
}

.friend-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 1.5px 5px rgba(0, 0, 0, 0.2));
}

.no-users-found {
  color: black;
  padding: 0;
  margin: 0;
}

.input-send-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.selected-user-information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-10%);

  height: 80%;
}

.selected-user-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.selected-user-fullName {
  font-weight: 500;
  color: black;

  padding: 0;
  margin: 0;
}

.start-message {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .empty-chat-container {
    grid-column: 1/-1;
    min-height: 80vh;
  }
}

@media only screen and (max-width: 450px) {
  .friends-container {
    left: 0;
  }
}
