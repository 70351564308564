.group-about-container {
  padding: 1rem;
  background: white;
  border-radius: 16px;
  position: sticky;
  top: 1%;
  height: 65vh;
  min-width: 320px;
  overflow-y: auto;
}

.group-about-container::-webkit-scrollbar {
  width: 7px;
}

.group-about-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-about-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.introduction-text,
.about-text {
  font-weight: 400;
  opacity: 1;
  color: black;
  padding: 0;
  margin: 0;
}

.introduction-content {
  color: #000000;
  margin-top: 10px;
  padding: 0;
  opacity: 0.4;
  overflow-wrap: break-word;
}

.about-container {
  margin-top: 25px;
}
.private-icon {
  width: 18px;
  align-self: flex-start;
}

.status-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.only-members,
.anyone-text {
  opacity: 0.4;
}

.status-text,
.visible-text {
  opacity: 1;
  color: black;
  padding: 0;
  margin: 0;
}

.visibility-icon {
  width: 20px;
  align-self: flex-start;
  margin-top: 2px;
}

.general-text {
  opacity: 1;
  font-size: 14px;
  color: black;
  margin: 0;
}

.general-icon {
  width: 25px;
}

.general-container {
  display: flex;
  align-items: center;
  gap: 11px;
}

.circle {
  background: #999999;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.members-text {
  padding: 0;
  margin: 0;
  opacity: 1;
  color: black;
  font-weight: 400;
}

.members-count-text {
  color: #999999;
  padding: 0;
  margin: 0;
  margin-top: 1px;
}

.members-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-image {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}
.user-image:not(:first-child) {
  margin-left: -10px;
}

.admins-list {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.admin-numbers {
  color: #000000;
  opacity: 0.7;
  margin-top: 10px;
}

.general-createdAt {
  display: flex;
  align-items: center;
  gap: 10px;
}

.createdAt-text {
  padding: 0;
  margin: 0;
  color: black;
}

@media only screen and (max-width: 1360px) {
  .group-about-container {
    position: relative;
    grid-row: 1/2;
    width: 50vw;
  }
}

@media only screen and (max-width: 700px) {
  .group-about-container {
    position: relative;
    grid-row: 2/3;
    width: 50vw;
  }
}
