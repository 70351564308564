.create-container {
  display: grid;
  grid-template-columns: 260px 1fr;
}

.form-container {
  background: white;
  border-radius: 16px;
  padding: 2rem 3.5rem;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-self: start;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 700px;
}

.create-container {
  display: grid;
  grid-template-columns: 260px 1fr;
}

.nft-form-container {
  background: white;
  border-radius: 16px;
  padding: 2rem 3.5rem;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-self: start;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 700px;
}

.actions-container {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin: 10px 0;
  margin-top: 45px;
  justify-content: flex-end;
  align-self: flex-end;
}

.add-custom-text {
  margin: 0;
  color: #677074;
  margin-right: -5px;
}

.popular-benefits-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.benefit-container {
  background: #f4f5f5;
  text-align: center;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selected-benefit-container {
  background: #f4f5f5;
  text-align: center;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid #ef00d7;
}
.selected-benefit-container p {
  color: #ef00d7 !important;
  margin: 0;
}

.benefit-container p {
  color: #a8adaf;
  margin: 0;
}

.nft-image,
.nft-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;

  max-height: 280px;
  max-width: 280px;
}

.click-to-upload-text {
  color: #999999;
  font-size: 400;
  margin: 0;
}

.upload-img-container {
  background: #f8f8f8;
  border: 1.5px dashed #cbcbcb;
  border-radius: 16px;
  width: 280px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.upload-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px 0;
}

.audio-file-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.upload-nft-text {
  font-size: 12px;
  opacity: 0.4;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.price-royalty-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.description-container,
.categories-container {
  margin-top: 10px;
}

.category-text,
.royalte-fee-text,
.description-text {
  margin-top: 10px;
}

.details-container {
  flex: 1;
}

.details-container h3 {
  color: #263238;
  margin: 0;
  margin-bottom: 10px;
}

.categories-input {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #f8f8f8;
}

.category,
.tag {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  font-weight: 400;
  color: #f900b7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.remove-icon {
  cursor: pointer;
  color: white;
}

.dollar-equivalent-text {
  color: #f900b7;
  font-weight: 500;
  opacity: 1 !important;
  margin: 0;
  padding: 0;
}

.upload-image-video-text {
  font-weight: 500;
  padding: 0;
  margin: 0;

  margin-bottom: 20px;
}

.title-text,
.category-text,
.royalte-fee-text,
.description-text {
  font-size: 12px;
  opacity: 0.4;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.vertical-line {
  height: 510px;
}
.thin-text {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.trending-categories,
.trending-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
}

.trending-category-container,
.trending-tag-container {
  border-radius: 20px;
  padding: 0.5rem 0.8rem;
  border: 1px solid #999999;
  cursor: pointer;
}

.trending-category-text,
.trending-tag-text {
  color: #999999;
  margin: 0;
}

.unlock-content-container {
  margin-top: 10px;
}

.unlock-content-text,
.benefits-content-text,
.included-text {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 10.75%,
    #a074f2 95.56%,
    #71b9d8 35%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  font-size: 14px;
}

.unlock-slider-container,
.benefits-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enter-details-type-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.image-video-audio-sections-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.section-text {
  font-size: 14px;
  font-weight: 500;

  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.section-text-selected {
  color: #263238;
  font-weight: 500;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

@media only screen and (max-width: 1200px) {
  .nft-form-container {
    flex-direction: column;
  }

  .vertical-line {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .create-container {
    display: flex;
    flex-direction: column;
  }
}
