.terms-of-use-container {
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  margin: 2% 8%;
}

.terms-of-use-title {
  color: #ff7cdc;
  font-weight: 500;
  font-size: 20px;
}

.terms-of-use-text {
  margin: 0;
  margin-top: 2px;
}
