.all-users-container {
    background: #0a1322;
    padding: 30px;
    color: white;
  }
  
  .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .all-users-heading {
    color: #f92baf;
  
    font-weight: bold;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 0;
  }
  
  .pagination-col {
    font-weight: bold;
  }
  
  .likes-container {
    background: #0a1322;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    padding-bottom: 0;
    min-height: 95vh;
    margin-top: 25px;
  }
  
  .sections-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
  }
  
  .section {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: white;
  }
  
  .selected-section {
    color: #f92baf;
  }
  