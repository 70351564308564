.user-container {
  border: 1px solid rgba(197, 193, 193, 0.438);
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0.5rem;
  border-radius: 5px;
}
.user-container:not(:last-child) {
  margin-bottom: 10px;
}

.user-image {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
}

.user-container p {
  padding: 0;
  margin: 0;
}

.friends-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  gap: 10px;
  padding: 2rem 0rem;
  padding-bottom: 5rem;
  justify-content: center;
}

.search-input-container {
  color: white;
}
