.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.public-text,
.private-text {
  margin: 0;
  opacity: 1;
  color: black;
  padding: 0;
}

.slider-container {
  margin-top: 1rem;
}
