.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0a1322;
  padding: 0 1.5rem;
}

.notification-container {
  position: absolute;
  top: 10%;
  right: 100px;
  padding: 2rem;
  z-index: 10000000;
}

@media only screen and (max-width: 768px) {
  .header-container {
    display: none;
  }
}

.content-container {
  /* margin-top: 60px; */
  /* min-height: 90vh; */
}

@media only screen and (max-width: 1400px) {
  .content-container {
    margin-top: 0;
  }
}
