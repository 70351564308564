.all-events-container {
  background: #0a1322;
  padding: 30px;
  color: white;
  width: 100%;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-events-heading {
  color: #f92baf;

  font-weight: bold;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0;
}

.pagination-col {
  font-weight: bold;
}
.likes-container {
  background: #0a1322;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  padding-bottom: 0;
  min-height: 95vh;
  margin-top: 25px;
}
