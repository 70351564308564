.nft-list-container {
  height: 350px;
  overflow-y: auto;
  padding-right: 6px;
  margin-top: 20px;
}

.nft-list-container::-webkit-scrollbar {
  width: 7px;
}

.nft-list-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.nft-list-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.nft-list-container p {
  color: black;
  font-weight: 500;

  padding: 0;
  margin: 0;
}
.table-horizontal-line {
  grid-column: 1/-1;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: 10px;

  margin-bottom: 15px;
}

.nft {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 100px 250px 1fr;
  margin-bottom: 15px;
}

.nft-title {
  color: #000000;
  opacity: 0.4;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.nft-image {
  object-fit: cover;
  border-radius: 50%;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 60%;
  transform: translateY(-70%);
  z-index: 3;
}

.table-header {
  display: grid;
  margin-top: 20px;
  margin-bottom: -20px;
  grid-template-columns: 100px 250px 1fr;
}
