.no-suggestions-icon {
  width: 100px;
  object-fit: cover;
}

.no-suggestions-page {
  margin-bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.no-suggested-text {
  color: #000000;
  opacity: 0.4;
  margin-top: 20px;
}

.user-image {
  border: 2px solid #eeeeee;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.recentGroupMemberInvitationsActivity,
.recentGroupChannelsActivity,
.recentGroupPostTopicsActivity {
  padding: 1rem;
  background: white;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  width: calc(100vw - 920px);
}

.user-fullName,
.user-fullName:hover {
  font-weight: 500;
  opacity: 1 !important;
  color: black;
}

.joined-text,
.channel-name {
  color: black;
  opacity: 0.7;
}

.updatedAt-text {
  color: black;
  opacity: 0.4;
}

.joined-text,
.updatedAt-text,
.channel-name {
  margin: 0;
}

.no-nfts-container {
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 2rem;
  background: white;
  border-radius: 16px;
}

.no-nfts-container {
  margin: 0;
}

.get-back-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}

@media only screen and (max-width: 1360px) {
  .recentGroupMemberInvitationsActivity,
  .recentGroupChannelsActivity,
  .recentGroupPostTopicsActivity {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .no-suggestions-page {
    height: 100%;
    margin-bottom: 3rem;
    width: 100%;
  }

  .no-suggestions-page img {
    width: 100vw;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2800px) {
  .recentGroupMemberInvitationsActivity,
  .recentGroupChannelsActivity,
  .recentGroupPostTopicsActivity {
    width: 750px;
    margin: 0 auto;
  }
}
