.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.trending-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
}

.trending-category-container {
  border-radius: 20px;
  padding: 0.5rem 0.8rem;
  border: 1px solid #999999;
  cursor: pointer;
}

.trending-category-text {
  color: #999999;
  margin: 0;
}

.categories-input {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #f8f8f8;
}

.category {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  font-weight: 400;
  color: #f900b7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.remove-icon {
  cursor: pointer;
  color: white;
}
