.no-posts-found-container {
  position: relative;
  height: 400px;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
}

.no-posts-found-icon {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-posts-found-text {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d4d6d7;
  font-weight: 500;
}
