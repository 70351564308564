.user-information-container {
  margin-top: 1rem;
  position: sticky;
  top: -125%;
}

.followers-socials-about-container {
  background: white;
  padding: 1rem;
  border-radius: 16px;
}
.about-user-container {
  position: relative;
  max-width: 40%;
  z-index: 1;
  min-width: 327px;
}

.followings-container,
.followers-container {
  cursor: pointer;
}

.number-followers-followings-container {
  background-color: #f4f5f5;
  border: 1px solid #e9ebeb;
  border-radius: 16px;
  padding: 1rem 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.number-followers-followings-text {
  color: #263238;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.followings-text,
.followers-text {
  color: #a8adaf;
  margin: 0;
}

.vertical-line {
  border-bottom: 1.5px solid #eaeaea;
  transform: rotate(90deg);
  width: 70px;
}

.horizontal-line {
  opacity: 0.1;
  width: 100%;
  border-bottom: 1.5px solid #000000;
  margin: 20px 0;
}

.social-links-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-container {
  background: #f4f5f5;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.social-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
}

.no-socials-found {
  margin: 0;
}

.no-socials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photos-container {
  background: white;
  border-radius: 10px;
  min-height: 530px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ebeb;
}

.photos-see-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.see-all-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.photos-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}

.photo {
  width: 100%;
  height: 100%;
  max-height: 180px;
  object-fit: cover;
}

.check-photos-button {
  background: linear-gradient(
    180deg,
    rgba(255, 124, 220, 0.2) 0%,
    rgba(249, 0, 183, 0.2) 100%
  );
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 2rem;
  gap: 10px;
  margin-top: 10px;
  cursor: pointer;
  margin-top: auto;
}
.horizontal-line {
  opacity: 0.1;
  width: 100%;
  border-bottom: 1.5px solid #000000;
  margin: 20px 0;
}

.check-photos-button p {
  color: #f900b7;
  margin: 0;
}

.no-photos-found {
  margin: 0;
}

.photos-text {
  color: #263238;
  margin: 0;
}

.see-all-text {
  text-decoration-line: underline;
  color: #a8adaf;
  margin: 0;
}

@media only screen and (max-width: 750px) {
  .about-user-container {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 430px) {
  .photos-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1450px) {
  .about-user-container {
    max-width: 100%;
  }
}
