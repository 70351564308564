.benefit-text {
  white-space: pre-line;
  overflow-wrap: break-word;
  margin: 0;
  font-size: 14px;
  color: #677074;
  margin-bottom: 5px;
}

.benefits-container {
  overflow-y: auto;
  max-height: 300px;
}

.benefits-container::-webkit-scrollbar {
  width: 10px;
}

.benefits-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 6px;
}

.benefits-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 6px;
}
