.send-comment-container {
  display: flex;
  align-items: flex-start;
  padding: 0.2rem 0.8rem;
  margin-top: 0.4rem;
  gap: 10px;
}

.post-comments-container {
  max-height: 550px;
  overflow-y: auto;
  width: 100%;
  margin-top: 20px;
  padding: 0 1rem;
}

.post-comments-container::-webkit-scrollbar {
  width: 7px;
}

.post-comments-container::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 4px;
}

.post-comments-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.cancel-replying-text {
  color: red;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}

.reply-container {
  height: 55px;
  padding: 0.2rem;
}
.reply-text {
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
}

.user-image {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.2));
  border-radius: 100px;
  object-fit: cover;
  margin: 0;
  padding: 0;
}

.no-comments {
  color: black;
  margin-top: 10px;
  margin-left: 10px;
}

.textarea {
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
  padding-right: 1.5rem;
  background: #f4f5f5;
  border-radius: 20px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.textarea-emoji-container {
  width: 100%;
  position: relative;
}

.emoji-icon {
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-60%);
  cursor: pointer;
}

.emoji-container {
  position: absolute;
  bottom: 100%;
  right: 0px;
}

@media only screen and (max-width: 600px) {
  .send-comment-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .send-comment-container button {
    grid-column: 1/-1;
  }

  .textarea-emoji-container {
    width: 100%;
  }
}

.mention-input-container {
  position: relative;
  width: 100%;
}

.mention-input-container {
  position: relative;
}

.mention-container {
  background: white;
  border-radius: 6px;
  height: 210px;
  overflow-y: auto;
  width: 340px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));

  position: absolute;
  top: 0px;

  left: 65px;
  top: -210px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.textarea-mention-container {
  position: relative;
  width: 100%;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.mention-container::-webkit-scrollbar {
  width: 7px;
}

.mention-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mention-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.no-users-found {
  color: black;
  opacity: 1;

  padding: 0;
  margin: 0.5rem 0;
}
.mention-text {
  color: black !important;
  opacity: 1 !important;
  font-weight: 500;
  cursor: pointer;

  font-size: 12px;
  padding: 0;
  margin: 0;
}

.topics-container {
  position: absolute;

  background: white;
  border-radius: 10px;
  background: white;
  border-radius: 6px;
  height: 210px;
  overflow-y: auto;
  width: 240px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);

  position: absolute;
  top: 0px;

  left: 65px;
  top: -210px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-topics-found {
  padding: 0.5rem;
  margin: 0;
}

.topics-container::-webkit-scrollbar {
  width: 7px;
}

.topics-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.topics-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.topic-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 0;
  padding: 0.2rem 0.5rem;
}

.topic-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.topic-container:hover .topic-text {
  color: white;
}

.topic-text {
  margin: 0;
  padding: 0.4rem;
}
