.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  row-gap: 30px;
}

@media only screen and (max-width: 1050px) {
  .nft-list {
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
}
