.address-text,
.amount-text {
  color: black;

  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.amount-text {
  margin-top: 5px;
}

.available-balance-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
}

.glimpse-balance,
.available-balance {
  padding: 0;
  margin: 0;
}

.available-balance {
  color: black;
  font-weight: 400;
}

.glimpse-balance {
  font-weight: 500;
  color: #f900b7;
}

.glimpse-balance-usd {
  color: black;
  opacity: 0.4;
  margin-left: 5px;
  font-size: 12px;
}

.footer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.horizontal-line {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.amount-container {
  margin-top: 10px;
}

.amount-container,
.remaining-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallet-address-text {
  padding: 0;
  margin: 0;

  color: black;
  opacity: 0.7;
  font-weight: 400;
}

.verification-code-text {
  color: black;
  opacity: 0.4;
  font-weight: 500;
  font-size: 16px;

  margin: 30px 0;
  margin-bottom: 20px;
}

.verification-code-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
}

.box-number {
  width: 50px;
  height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);

  color: black;
  text-align: center;
  background: #f8f8f8;
  padding: 2rem 1.85rem;
  border: 1.5px solid #eeeeee;
  border-radius: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
