.events-container {
  background-color: #0a1322;
  flex: 1;
}

.event-photo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.event-inputs-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.categories {
  width: 50%;
  padding: 10px;
  padding-top: 0;
}
