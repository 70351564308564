.options-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.option-container,
.claimable-container {
  background: #f4f5f5;
  /* box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1); */
  border-radius: 16px;
  padding: 2rem;
  width: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.selected-container {
  background: #fde5fb;
  /* box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1); */
  border-radius: 16px;
  padding: 2rem;
  width: 150px;
  text-align: center;
  cursor: pointer;
}

.white-text {
  margin: 0;
  color: white;
  font-weight: 500;
}

.selected-container p {
  margin: 0;
  color: #ef00d7 !important;
  opacity: 1 !important;
}

.option-text {
  color: #a8adaf;
  font-weight: 500;
  margin: 0;
  /* opacity: 0.7; */
}

.create-text {
  margin-top: 20px;
}

.options-info-container {
  margin: 20px 0;
  width: 450px;
}
.single-info-container h3,
.collection-info-container h3,
.claimable-info-container h3 {
  margin: 0;
  color: #263238;
}
.single-info-container,
.collection-info-container,
.claimable-info-container {
  margin-top: 10px;
}

.single-info-container p,
.collection-info-container p,
.claimable-info-container p {
  color: #a8adaf;
  margin: 0;
  margin-top: 10px;
  width: 100%;
  font-weight: 400;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  height: 30px;
  margin-bottom: 4px;
}
