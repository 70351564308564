.sent-collaborator-nft-requests-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 10px;

  margin-top: 30px;
  row-gap: 30px;
}

@media only screen and (max-width: 1050px) {
  .sent-collaborator-nft-requests-list {
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
  }
}
