.nft-offers-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.offers-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}

.offers-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.offers-sections {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 30px;
  margin-left: 3.5rem;
}

.offers-section {
  margin: 0;
  padding: 0;
  color: #000000;
  opacity: 0.4;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.price-text {
  color: #999999 !important;
}

.filters-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 10px 0 10px;
}

.input-search-container {
  display: flex;
  align-items: center;
  width: 70%;
}

.search-icon {
  margin-left: -35px;
  cursor: pointer;
  width: 18px;
}

.sorting-container {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  padding: 0.7rem;
  position: relative;
  flex: 1;
  border-radius: 6px;
  z-index: 7;
}

.sort-options {
  position: absolute;
  top: 45px;
  left: 0;
  background: white;
  border-radius: 6px;
  width: 100%;
  padding: 0.4rem;
  z-index: 1000;
  overflow: hidden;
}

.sorting-text {
  margin: 0;
  padding: 0;
  width: 100%;
  color: black;
  font-weight: 500;
  font-size: 14px;
}

.sort-option {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
  padding: 0.4rem;
  transition: all 0.2s ease;
}

.input-container {
  margin: 1rem auto;
}

.sort-option:not(:last-child) {
  margin-bottom: 10px;
}

.sent-received-offers-container {
  margin-top: 1rem;
}

.offers-requests-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  background: #fafafa;
}
.offers-requests-header {
  border-bottom: 1px solid #e9ebeb;
}

.offers-requests-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}
.offers-requests-header,
.offers-requests-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offers-requests-options-container {
  gap: 20px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.offers-requests-option-text {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.offers-requests-option-text-selected {
  color: #263238;
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .filters-container {
    flex-direction: column;
  }

  .nft-offers-page-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .offers-container {
    margin-left: 6%;
    margin-right: 6%;
  }
}
