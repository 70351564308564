.textarea {
  /* background: #f8f8f8; */
  background: #f4f5f5;

  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
