.upload-photo-btn {
  background: #ffffff;
  border: 1.5px solid #f900b7;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.5rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  width: 40%;
  cursor: pointer;
}

.upload-photo-text {
  color: #f900b7;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.plus-icon {
  margin-bottom: 2px;
  cursor: pointer;
}

.photo {
  width: 180px;
  height: 180px;
  object-fit: cover;
  /* border-radius: 10px; */
  border: 1px solid white;
}

.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  justify-content: center;
  gap: 7px;
  margin-top: 20px;
}

.no-photos-found {
  opacity: 0.7;
  font-size: 16px;
}
