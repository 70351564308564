.buy-points-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
}

.usd-points-info-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.usd-value {
  color: #263238;
  margin: 0;
  font-weight: 500;
  font-size: 38px;
}
.you-will-get {
  color: #677074;
  margin: 0;
  text-align: center;
}

.points-text {
  color: #ef00d7;
  font-weight: 500;
  margin: 0;
  font-size: 28px;
  display: flex;
  align-items: center;
}

.points-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  margin: 20px 0;
  margin-bottom: 40px;
}

.box-option {
  background: #f8f8f9;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  position: relative;
}

.selected-box-option {
  background: #fde5fb;
  border: 1px solid #ef00d7;
}

.most-popular-container {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ef00d7;
  border: 1px solid #ef00d7;
  border-radius: 6px;
  padding: 0 0.25rem;
}

.most-popular-container p {
  color: white;
  margin: 0;
  font-size: 12px;
}

.points-value {
  color: #ef00d7;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}

.usd-option-value {
  color: #263238;
  margin: 0;
}
.points-option-text {
  color: rgba(168, 173, 175, 1);
  font-weight: 400;
  margin: 0;
  font-size: 12px;
}

.diamond-icon {
  width: 18px;
  margin-right: 7px;
}

.big-diamond-icon {
  width: 24px;
  margin-right: 5px;
}
