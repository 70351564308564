.no-cover-photo-container {
  border-radius: 16px;
  background: #f8f8f8;
  width: 320px;
  height: 120px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px dashed #d4d6d7;
  cursor: pointer;
}

.cover-photo-container {
  border-radius: 16px;
  width: 320px;
  height: 120px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  object-fit: cover;
  margin-top: 10px;
  border: 1px dashed #d4d6d7;
  cursor: pointer;
}

.camera-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.profile-camera-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 0px;
}

.cover-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-photo-camera-icon {
  width: 18px;
  position: absolute;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-photo-image {
  width: 100%;
  height: 100%;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 20px;
}

.thin-text {
  color: black;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.cover-photo-text {
  margin: 0;
  margin-top: 10px;
  margin-bottom: -5px;
  color: #677074;
}

.no-profile-photo-container,
.profile-photo-container {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  margin: 10px 0;
  border: 1px dashed #d4d6d7;
}
.profile-photo-container {
  overflow: hidden;
  cursor: pointer;
}
.no-profile-photo-container {
  border-radius: 16px;
  background: #f8f8f8;
  border: 1px dashed #d4d6d7;
  position: relative;
  cursor: pointer;
}
.upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  cursor: pointer;
}

.profile-photo-image {
  width: 120px;
  height: 120px;
}

.upload-img-container {
  background: #f8f8f8;
  border-radius: 10px;

  width: 150px;
  height: 110px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 2px solid #eeeeee;
}

.upload-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-drop-text {
  color: #999999;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.upload-file-and-nfts-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nft-image,
.nft-video {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  margin-bottom: 20px;
  border-radius: 6px;
}

.more-nfts-container {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
}

.more-text-container {
  background: rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 500;
}

.nft-container {
  position: relative;
}
.remove-icon {
  position: absolute;
  top: 50%;
  right: 3%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.remove-icon-container {
  position: absolute;
  top: 12%;
  right: -10px;
  z-index: 10;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #677074;
  font-size: 12px;
  margin-top: 10px;
}

.claimable-container {
  margin: 10px 0;
}

.capped-description {
  padding: 0;
  margin: 0 auto;
  font-size: 12px;
  color: #999999;
}

.profile-photo-cover-photo-container {
  display: flex;
  gap: 10px;
}

.upload-nfts-text {
  color: #677074;
  margin: 0;
  padding: 0;
}

.dollar-equivalent-text {
  color: #f900b7;
  font-weight: 500;
  opacity: 1 !important;
  margin: 0;
  padding: 0;
}
