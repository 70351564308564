.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.members-list {
  height: 500px;
  overflow-y: auto;
}
