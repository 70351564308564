@import './colors.scss';
@import '~video-react/styles/scss/video-react';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

body {
  background: white;
}

.margin-small {
  margin: 10px;
}

.margin-left-small {
  margin-left: 10px;
}

.margin-right-small {
  margin-right: 10px;
}

.margin-bottom-small {
  margin-bottom: 10px;
}

.margin-top-small {
  margin-top: 10px;
}

.titles-bold {
  font-family: 'Montserrat-Bold', sans-serif;
  color: white;
  font-size: 42px;
}

.btn-pink-glimpse {
  background-color: $PinkGlimpse;
  border-color: $PinkGlimpse;
  padding: 0.4rem 2rem;
  font-size: 14px;
  border-radius: 32px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border: none;
  font-weight: bold;

  line-height: 1.5715;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  user-select: none;
  touch-action: manipulation;

  outline: none;
  :hover {
    background-color: $PinkGlimpse;
    border-color: $PinkGlimpse;
  }
}

.btn-white-glimpse {
  background-color: white;
  border-color: white;
  padding: 0.4rem 2rem;

  font-size: 14px;
  border-radius: 32px;
  color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border: none;
  font-weight: bold;

  line-height: 1.5715;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  user-select: none;
  touch-action: manipulation;

  outline: none;
  :hover {
    background-color: white;
    border-color: white;
  }
}

.btn-pink-glimpse:hover,
.btn-pink-glimpse:focus {
  background: $PinkGlimpse;
  border-color: $PinkGlimpse;
}

.color-pink {
  color: $PinkGlimpse;
}

.input-glimpse {
  width: 100%;
  border-radius: 10px;
  height: 45px;
  color: #5b6068;
  padding: 0 1em;
  background: transparent;
  border: 1px solid #5b6068;
  outline: none;
  margin-bottom: 1em;
  transition: background-color 0.5s ease;
  &:focus {
    background-color: $DarkGlimpse;
  }
}

.input-search {
  background-color: $GreyGlimpse;
  border-radius: 50px;
}

.input-background {
  background: #253042;
}

.link-g {
  color: $PinkGlimpse;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  width: 40px !important;
  height: 40px !important;
}

.message-color {
  color: black !important;
}

.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 120px !important;
  height: 120px !important;
  margin: 0 8px 8px 0;
  vertical-align: top;
  border-radius: 50px;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
  border-radius: 60px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
  border-radius: 60px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 120px;
  height: 120px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 60px !important;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $PinkGlimpse !important;
}

.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $PinkGlimpse !important;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: $PinkGlimpse !important;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.card-nft {
  padding: 1em 2em;
  width: 100%;
  min-height: 200px;
  background-color: #0a1322;
  color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-img-nft {
    padding: 0.5em;
    width: 140px;
    height: 140px;
    border: 1px solid #2b323f;
    border-radius: 73px;
  }
  .title-nft-card-litte {
    font-weight: bold;
    text-align: left;
    color: white;
    margin: 0.5em 0;
  }
  .info-nft-litte {
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    .vl {
      border-left: 3px solid #212936;
    }
    .price {
      color: $PinkGlimpse;
      font-weight: bold;
      margin-left: 1em;
    }
  }

  .img-card-nft-large {
    width: 100%;
    border-radius: 15px;
  }
  .data-nft-card-large {
    display: flex;
    justify-content: space-between;
    width: 100%;
    span {
      font-weight: bold;
      font-size: 16px;
    }
    .price {
      color: $PinkGlimpse;
    }
  }
  hr {
    opacity: 0.1;
  }
}

.card-categories {
  width: 100%;
  // min-height: 200px;
  background-color: #0a1322;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  .img-card-nft-category {
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
  .title-nft-card-category {
    font-weight: bold;
    text-align: left;
    color: white;
    margin: 0.5em 0;
    padding: 0 1em;
  }
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #060e19 !important;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: -1px 1px 5px 0px rgba(2, 7, 15, 0.75);
  -webkit-box-shadow: -1px 1px 5px 0px rgba(2, 7, 15, 0.75);
  -moz-box-shadow: -1px 1px 5px 0px rgba(2, 7, 15, 0.75);
  border-radius: 13px !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #253042 !important;
  border-radius: 13px !important;
}
.ant-radio-wrapper > * {
  color: white;
}

.cancel-button {
  background-color: rgba(255, 0, 0, 0.8);
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 40px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.success-button {
  background-color: rgb(65, 199, 65);
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 40px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.btn-gold-glimpse {
  background-color: rgba(255, 255, 0, 0.95);
  padding: 0.4rem 2rem;
  color: rgba(0, 0, 0, 1);
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.warning-button {
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 40px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  background-color: #f0b90b;
}

.btn-transfer {
  background-color: #4d565f9d;
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.btn-disabled {
  background-color: #253042;
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 40px;
  font-weight: bold;
  border: none;
  cursor: not-allowed;
}

.pink-text {
  color: #f92baf;
  font-weight: 100;
}

.pink-bold-text {
  color: #f92baf;
  font-weight: bold;
}

.thin-text {
  color: white;
  font-weight: 100;
}

.bold-text {
  color: white;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  border-bottom: 1px solid white;
  margin: 10px 0;
}

.responsive-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 50px;
}

.marketplace-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  column-gap: 10px;
}

.center {
  text-align: center;
}

@media (max-width: 850px) {
  .card-nft {
    .nft-image {
      height: 220px;
      object-fit: contain;
      object-position: center;
    }
  }
}
.marcket-place-button {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 30px;
}

.profile-grid {
  display: grid;
  grid-template-columns: minmax(300px, 400px) 1fr;
  gap: 30px;
}

.ant-popover-inner-content {
  background: #0a1322 !important;
}
.ant-popover-title {
  background: #0a1322 !important;
  color: white !important;
  font-size: 20px;
  font-weight: bold !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

@media (max-width: 970px) {
  .profile-grid {
    grid-template-columns: 300px 1fr;
  }
}
@media (max-width: 738px) {
  .profile-grid {
    display: flex;
    flex-direction: column;
  }
}

.center-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.center-vertically-horizontally {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea-glimpse {
  border-radius: 5px;
  margin: 0 10px;
  min-height: 50px;
}
.textarea-glimpse::-webkit-scrollbar {
  width: 5px;
}

.textarea-glimpse::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 4px;
}

.textarea-glimpse::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.searchInput {
  background: #253042;
  outline: none;
  border: none;
  border-radius: 100px;
  padding: 0.5rem 0.7rem;
  width: 50%;
  margin-bottom: 0.4rem;
}

.searchInput::-webkit-scrollbar {
  width: 11px;
}

.searchInput::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 8px;
}

.searchInput::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.select-input {
  background: #253042;
  border-radius: 100px;
  outline: none;
  border: none;
  border-radius: 100px;
  padding: 0.5rem 0.7rem;
  width: 100%;
  color: white;
  margin-bottom: 0.4rem;
  padding: 12px;
}
.select-input::-ms-expand {
  display: none;
}
