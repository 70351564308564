.my-groups-sidebar-container {
  background: white;
  border-radius: 16px;
  padding: 1rem;
  position: fixed;
  height: 85vh;
  width: 320px;
}
.groups-create-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.groups-text {
  opacity: 1;
  margin: 0;
}
.groups-you-manage-text {
  margin: 0;
  color: #263238;
}

.groups-as-admin-container {
  margin-top: 20px;
}

.groups-list {
  overflow-y: auto;
  max-height: 190px;
}

.no-groups-found {
  margin: 0;
  color: #263238;
  font-weight: 500;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-name,
.last-active {
  padding: 0;
  margin: 0;
}
.group-name {
  color: #263238;
  opacity: 1;
  font-weight: 500;
}

.last-active {
  font-size: 12px;
  color: #8d8d8d;
}

.cover-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.group {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0.6rem;
  cursor: pointer;
  margin-left: -0.6rem;
  transition: all 0.2s ease;
}

/* .group:hover {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 8px 0px 0px 8px;
  margin-left: 0rem;
} */

.selected-group {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 8px 0px 0px 8px;
  margin-left: 0rem;
}

.group-list {
  height: 500px;
  overflow-y: auto;
}

.group-list::-webkit-scrollbar {
  width: 7px;
}

.group-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-list > * {
  margin-top: 15px;
}

.no-groups-found {
  opacity: 1;
  margin-top: 20px;
}

.create-btn {
  padding: 0.5rem 2.2rem;
  display: flex;
  align-items: center;
  gap: 7px;
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  border-radius: 10px;
  margin-right: 1rem;
}

.create-text {
  color: white;
  font-weight: 500;

  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1360px) {
  .my-groups-sidebar-container {
    position: relative;
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .my-groups-sidebar-container {
    position: relative;
    width: 100%;
    grid-column: 1/-1;
    grid-row: 1/2;
  }
}
