.top-creators-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-right: -5rem;
  top: 0%;
  position: sticky;
}

.top-creators-text-container {
  padding: 1rem 2.5rem;
  text-align: center;
}

.top-creators-text {
  color: #263238;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.top-creator {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  height: 100px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.top-creators {
  padding: 0 0.5rem;
}

.user-cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.user-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.user-info-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  width: 100%;
  border-radius: 10px;
  padding: 3px 5px;
}

.user-name-container {
  color: #ffffff;
  display: flex;
  z-index: 2;
  flex-direction: column;
}
.user-fullName,
.username {
  margin: 0;
  padding: 0;
}

.user-fullName {
  color: white;
  margin-bottom: -3px;
}
.username {
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
}

.fullName-verified-container {
  display: flex;
  align-items: center;
}

.right-icon {
  height: 12px;
  margin-left: 1rem;
  cursor: pointer;
}
