.topics-page-container {
  background: #ffffff;
  border-radius: 16px;
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #e9ebeb;
}

.group-topics-text {
  /* font-weight: 500; */
  font-size: 18px;
  color: #263238;
}

.topics-list::-webkit-scrollbar {
  width: 7px;
}

.topics-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.topics-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
