.buy-now-text {
  color: black;
  font-weight: 500;
}

.info-container {
  background: white;
  border-radius: 12px;
  width: 300px;
  overflow: hidden;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
}

.buy-now-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.price-text,
.owner-address-text {
  color: black;
  opacity: 0.4;
}
.owner-address {
  opacity: 0.4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.usd-price {
  color: #00b432;
  padding: 0;
  margin: 0;
}

.price {
  color: black;
  opacity: 0.7;
  font-size: 12px;
  padding: 0;
  margin: 0;
  text-align: right;
}

.owner-address-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
