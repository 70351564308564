.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 10px;
  row-gap: 30px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.metamask-nfts-container {
  padding-top: 1rem;
}

.locked-nfts-container,
.no-nfts-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 2rem;
  background: white;
  border-radius: 16px;
}

.no-nfts-container {
  margin: 0;
}

.private-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
  width: 40%;
  text-align: center;
}

.get-back-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}
