.input-selected-users-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.to-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  margin-right: 10px;
}

.selected-user-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.selected-user-text {
  font-weight: 400;
  color: #f900b7;
  padding: 0;
  margin: 0;
}

.add-container {
  border-radius: 50%;
  padding: 0.1rem;
  width: 22px;
  height: 22px;
  margin-left: auto;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.friends-container {
  background: white;

  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  min-width: 300px;
  position: absolute;
  top: 51px;
  left: 80px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 1rem 0.4rem;
  max-height: 200px;
  overflow-y: auto;
}

.friends-container::-webkit-scrollbar {
  width: 7px;
}

.friends-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.friends-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.follower-fullName {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.friend-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 0.3rem;
  transition: all 0.2s ease;
}

.friend-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 1.5px 5px rgba(0, 0, 0, 0.2));
}

.user-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.selected-members-container {
  height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.selected-members-container::-webkit-scrollbar {
  width: 8px;
}

.selected-members-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.selected-members-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.selected-member-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-fullName,
.user-username {
  padding: 0;
  margin: 0;
}
.user-fullName {
  color: #263238;
}
.user-username {
  color: #a8adaf;
  font-size: 12px;
}

.no-users-found {
  color: black;
  padding: 0;
  margin: 0;
}

.input-send-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.selected-user-information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-10%);

  height: 80%;
}

.selected-user-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.selected-user-fullName {
  font-weight: 500;
  color: black;

  padding: 0;
  margin: 0;
}

.start-message {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
}

.selected-users-to-join-group-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 450px) {
  .friends-container {
    left: 0;
  }
}
