.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: black;
  margin-top: 5px;
}
