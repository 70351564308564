.nft-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto 35%;
  margin-bottom: 20px;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 1rem;
  min-width: 400px;
}

.nft-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.creator-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 50px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}
.creator-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}

.creator-text {
  margin: 0;
  color: #677074;
}

.creator {
  color: #a8adaf;
  margin: 0;
  font-weight: 400;
}

.collection-name {
  color: #263238;
  margin: 0;
  font-weight: 500;
}

.description {
  color: #677074;
  margin: 10px 0;
}

.collection-profile-photo-small {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  object-fit: cover;
}

.collection-name-header {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}

.collection-header-text {
  color: #a8adaf;
  margin: 0;
}

.collection-text .collection-info-container {
  background: #f8f8f8;
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 0.5rem 0.75rem;
}

.collection-profile-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 50px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.collection-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}

.collection-profile-photo-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.claim-button-container {
  text-align: center;
  width: 80%;
}

.horizontal-line {
  opacity: 0.1;
  width: 100%;
  margin: 20px 0;
  border-bottom: 1.5px solid #000000;
}

.creator-collection-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.non-custom-text-container {
  text-align: center;
}
.custom-non-custom-benefits-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  column-gap: 10px;
  row-gap: 0px;
  margin: 10px 0;
}

.custom-non-custom-benefits-container::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

.custom-non-custom-benefits-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 1px;
}

.custom-non-custom-benefits-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 1px;
}

.custom-benefits-container {
  background: linear-gradient(225deg, #ff057c 0%, #7c64d5 47.87%, #4cc3ff 100%);
  border-radius: 12px;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 0.5rem;
  width: 100%;
}

.non-custom-benefits-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 0.5rem;
  width: 100%;
}
.non-custom-benefits-container img {
  width: 25px;
}

.non-custom-benefit-text-bold {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  width: 180px;
  margin: 0;
}

.custom-benefits-text {
  color: #ffffff;
  margin: 0;
  opacity: 0.7;
  text-align: center;
  width: 180px;
}
.custom-benefits-text-bold {
  color: white;
  font-weight: 500;
  margin: 0;
  width: 180px;
}
.benefits-text {
  color: #263238;
  margin: 0;
  text-align: center;
  width: 180px;
}
.star-icon {
  width: 25px;
}

.name-description-container {
  align-self: flex-start;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .nft-container {
    margin: 5%;
    min-width: auto;
  }

  .nft-image {
    width: 100%;
  }

  .creator-collection-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .claim-nft-page {
    background: white;
    margin: 0;
    height: 100vh;
    margin-top: -44px;
  }
  .nft-container {
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .nft-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 0px;
  }

  .name-description-container {
    padding: 0 0.5rem;
    margin: 0;
  }

  .creator-collection-container {
    flex-direction: row;
    margin: 0 10px;
  }
  .collection-name-header {
    margin-top: 10px;
  }
}
