.create-group-form-container {
  background: #ffffff;
  padding: 1rem;
  flex-basis: 40%;
}

.create-group-text {
  color: #263238;
  font-size: 16px;
  font-weight: 500;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
}

.privacy-container,
.about-container,
.group-username-container {
  margin-top: 15px;
}

.buttons-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttons-container > * {
  margin-top: 15px;
}

.cancel-text {
  cursor: pointer;
  font-weight: 500;
  text-decoration-line: underline;
  color: #f900b7;
}

.privacy-text {
  color: #000000;
  margin: 0;
}

.upload-cover-photo-container {
  background: #f8f8f8;
  border: 1.5px dashed #cbcbcb;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 1.5rem 0;
  margin-top: 10px;
  cursor: pointer;
}

.upload-icon {
  width: 30px;
}

.click-to-upload-text {
  color: #999999;
  font-weight: 500;
  margin: 0;
}
