.upload-photo-btn {
  background: #ffffff;
  border: 1.5px solid #f900b7;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0.5rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  cursor: pointer;
  flex: 1;
}

.upload-photo-text {
  color: #f900b7;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.plus-icon {
  margin-bottom: 2px;
  cursor: pointer;
}

.photo {
  width: 180px;
  height: 180px;
  object-fit: cover;
  /* border-radius: 10px; */
  border: 1px solid white;
}

.photos-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  gap: 7px;
  justify-content: center;
  margin-top: 20px;
}

.no-photos-found {
  opacity: 0.7;
  font-size: 14px;
}

.photo-container {
  position: relative;
}
.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 18px;
  cursor: pointer;
}

.options {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  z-index: 11254365;
  padding-bottom: 0;
  min-width: 75px;
  position: absolute;
  right: 40px;
  top: 10px;
  padding: 0.2rem 0.5rem;
}
.delete-text {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.25rem;
  cursor: pointer;
  text-align: center;
}

.single-multiple-photos-container {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

/* .photos-page-container {
  margin-left: 100px;
} */

.more-icon-container {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}
