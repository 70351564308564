.follow-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  border-bottom: 1px solid #e9ebeb;
  padding: 5px;
  padding-left: 0;
}

.follow-people-text {
  color: #a8adaf;
  margin: 0;
}

.number-of-followings-text {
  color: #263238;
  margin: 0;
  margin-right: 10px;
}

.user-photo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
}

.user-fullName {
  color: #263238;
  font-weight: 400;
  margin: 0;
}

.user-list {
  height: 300px;
  overflow-y: auto;
}

.user-list::-webkit-scrollbar {
  width: 6px;
}

.user-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.user-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
