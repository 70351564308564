.albums-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, max-content));
  justify-content: center;
  gap: 7px;
}

.no-albums-container {
  background: white;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0.7rem;
  margin: 1rem 0;
}

.no-albums-text {
  color: black;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}
