.post-comment-container {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-self: flex-start;
  object-fit: cover;
}

.comment-container {
  /* background: #f8f8f8; */
  border-radius: 10px;
  padding: 0.5rem;
}

.empty-space {
  margin: 0;
}

.comment-text {
  overflow-wrap: break-word;
  font-size: 12px;
  color: #677074;
}
.user-fullName,
.user-fullName:hover {
  color: #263238;
  font-size: 14px;
  margin-right: 10px;
}

.comment-container p {
  padding: 0;
  margin: 0;
}

.liked-text {
  cursor: pointer;
  color: #677074;
  font-weight: 500;
  font-size: 12px;
  margin-top: 8px;
  margin-right: 15px;
}

.like,
.reply {
  cursor: pointer;
  color: #a8adaf;
  font-size: 12px;
  margin-top: 8px;
}

.like {
  margin-right: 20px;
}

.replying {
  cursor: pointer;
  color: #677074;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 500;
}

.createdAt {
  color: #a8adaf;
  align-self: center;
  font-size: 12px;
  margin-left: 15px;
}

.recycle-bin {
  color: #ff5252;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.more {
  position: absolute;
  top: 0;
  right: -200px;
  z-index: 10;
}

.mention,
.mention:hover {
  font-weight: 500;
  color: #677074;
  font-size: 12px;
}

.more-options {
  width: 200px;
  background: white;
  padding: 0.75rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.reply-input-container {
  margin-left: 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  position: relative;
}

.mention-input-container {
  position: relative;
  width: 100%;
}

.mention-input-container {
  position: relative;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.mention-container {
  background: white;
  border-radius: 6px;
  height: 210px;
  overflow-y: auto;
  width: 340px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));

  position: absolute;
  top: 0px;

  left: 65px;
  top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.textarea-mention-container {
  position: relative;
  width: 100%;
}

.mention-container::-webkit-scrollbar {
  width: 7px;
}

.mention-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mention-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.no-users-found {
  color: black;
  opacity: 1;

  padding: 0;
  margin: 0.5rem 0;
}
.mention-text {
  color: black !important;
  opacity: 1 !important;
  font-weight: 500;
  cursor: pointer;

  font-size: 12px;
  padding: 0;
  margin: 0;
}

.topics-container {
  position: absolute;

  background: white;
  border-radius: 10px;
  background: white;
  border-radius: 6px;
  height: 210px;
  overflow-y: auto;
  width: 240px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);

  position: absolute;
  top: 0px;

  left: 65px;
  top: 50px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-topics-found {
  padding: 0.5rem;
  margin: 0;
}

.topics-container::-webkit-scrollbar {
  width: 7px;
}

.topics-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.topics-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.topic-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 0;
  padding: 0.2rem 0.5rem;
}

.topic-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.topic-container:hover .topic-text {
  color: white;
}

.topic-text {
  margin: 0;
  padding: 0.4rem;
}
