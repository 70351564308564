.personal-details-container,
.change-password-container,
.request-nft-container,
.metamask-nfts-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personal-details-container p {
  padding: 0;
  margin: 0;
}

.change-password-container p {
  padding: 0;
  margin: 0;
}

.request-nft-container p {
  padding: 0;
  margin: 0;
}

.horizontal-line {
  margin: 10px 0;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
}

.slider-text {
  margin: 0;
  color: #677074;
  font-weight: 500;
  font-size: 14px;
}

.text {
  color: #263238;
}
