.admin-panel-container {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 700px) {
  .admin-panel-container {
    flex-direction: column;
  }
}
