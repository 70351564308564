.pending-offers-container {
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  height: 300px;
  padding: 0.4rem 0.2rem;
}

.user-image {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 1px solid #e9ebeb;
  object-fit: cover;
}

.pending-offers-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.price-info,
.hour-info {
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 0;
  margin: 0;
}

.hour-info {
  color: #a8adaf;

  font-size: 12px;
}

.price-text {
  color: #ef00d7;
  font-weight: 500;
  opacity: 1 !important;
}
.by {
  color: #677074;
  font-weight: 500;
}

.to-fullName {
  color: #263238;
  font-weight: 500;
  opacity: 1;
}
