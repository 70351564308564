.chats-container {
  display: grid;
  grid-template-columns: 260px 1fr;
}

.chats-messages-container {
  display: flex;
  align-items: center;
  border: 1px solid #e9ebeb;
  border-top: 0;
}

.go-back-icon {
  width: 15px;
  cursor: pointer;
}

.messages-text {
  color: #263238;
  padding: 0;
  margin: 0;
}

.messages-header {
  border: 1px solid #e9ebeb;
  background: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}
.chat-list {
  height: 560px;
  overflow-y: auto;
}

.chat-list::-webkit-scrollbar {
  width: 7px;
}

.chat-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.chat-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.chats {
  background: white;
  padding: 1rem;
  /* padding-top: 0; */
  height: 100%;
  /* border-bottom-left-radius: 10px; */
  padding-right: 0;
  padding-left: 0.5rem;

  padding-top: 0;
  padding-bottom: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-text {
  padding: 0;
  margin: 0;
}

.create-chat-icon {
  padding: 0;
  margin: 0;
  margin-right: 12px;
  cursor: pointer;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}
.more-icon {
  padding: 0;
  margin: 0;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 67%;

  transform: translateY(-70%);
  z-index: 3;
}

.no-chats {
  padding: 1rem 0;
  opacity: 0.7;
}

.selected-chat-container {
  width: 94.5%;
  height: 100%;
}

.chat-sections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: 5px;
}

.chat-section {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.4);
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #263238 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.section-text {
  color: rgba(0, 0, 0, 0.4);

  padding: 0;
  margin: 0;
  display: flex;
  gap: 5px;
}

.nft-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

@media only screen and (max-width: 1400px) {
  .chat-list {
    grid-row: 1/2;
  }

  .recent-sales-sidebar-container {
    grid-column: 1/-1;
  }
}

.community-messages-container {
  background: white;
  display: flex;
  width: 94.5%;
  border-left: 1px solid #e9ebeb;
  height: 100%;
  /* border-bottom-right-radius: 10px; */
}

.empty-chat-container {
  width: 94.5%;
  height: 100%;
}

.number-of-unread-messages {
  background: rgba(159, 132, 132, 0.1);
  opacity: 1 !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
}

.number-of-unread-messages-text {
  color: #263238 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  opacity: 1 !important;
}

.messages-box {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0 12%;
  border-radius: 20px;
}

@media only screen and (max-width: 1400px) {
  .messages-box {
    margin: 10px;
  }
}

@media only screen and (max-width: 1300px) {
  .chats-container {
    display: grid;
    grid-template-columns: 260px 1fr;
    gap: 0;
    column-gap: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .chats-messages-container {
    flex-direction: column;
  }

  .selected-chat-container {
    width: 100%;
  }

  .chats {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .chats-container {
    display: flex;
    flex-direction: column;
  }
}
