.refer-friends-text {
  margin: 0;
  color: #a8adaf;
  font-weight: 400;
}

.five-friends-text {
  color: #ef00d7 !important;
}

.refer-friends-text-container {
  margin: 0 auto;
}

.qr-code-container {
  background: #f4f5f5;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  margin: 10px auto;
}

.canvas,
.copy-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-socials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.copy-icon-container {
  background: #fde5fb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ef00d7;
  position: relative;
  margin: 0;
  cursor: pointer;
  align-self: flex-start;
}

.copy-icon {
  width: 14px;
}

.success-copy {
  background: #fde5fb;
  padding: 0.25rem 1rem;
  border-radius: 10px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 0;
  color: #ef00d7 !important;
}
