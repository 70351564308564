.event-container {
  width: 350px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.event-photo {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.event-name {
  font-size: 18px;
  margin: 10px 0;
}

.event-info-container {
  padding: 0.5rem;
}

.location-icon {
  height: 16px;
}

.calendar-icon {
  height: 16px;
}

.location,
.date {
  margin: 0;
  margin-left: 5px;
}

.location-text,
.date-text {
  color: #999999;
  font-size: 12px;
}

.location-value,
.date-value {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}

.location-date-container,
.date-container,
.location-container {
  display: flex;
  align-items: center;
}

.location-date-container {
  gap: 3px;
}

.description {
  color: #000000;
  margin: 10px 0;
  opacity: 0.7;
}

.creator-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 50px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.creator-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}
.creator-fullName {
  color: #000000;
  margin: 0;
  font-weight: 500;
}

.creator-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin: 0;
}

.event-photos-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 5px;
}
