.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.benefits-container {
  height: 300px;
  overflow-y: auto;
  padding-right: 6px;
}

.benefits-container::-webkit-scrollbar {
  width: 7px;
}

.benefits-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.benefits-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
