.messages-container {
  min-height: 540px;
  overflow-y: auto;
}

.messages {
  height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0.2rem;
}

.messages::-webkit-scrollbar {
  width: 7px;
}

.messages::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar {
  width: 7px;
}

.messages-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.sender-photo {
  border: 1px solid #eeeeee;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.textarea {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 100%;
  resize: none;
  height: 48px;
  padding-right: 5.5rem;
  background: #f4f5f5;
  border-radius: 12px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.other-user-message-container {
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
}
.date-text {
  text-align: center;
  opacity: 0.4;
  font-size: 12px;
  margin: 0.5rem 0;
}

.message-content {
  background: #f4f5f5;

  border-radius: 8px 8px 8px 0;
  max-width: 50%;

  display: inline-block;

  padding: 0.5rem 0.6rem;
}
.message-text {
  color: #263238;
  font-weight: 400;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  overflow-wrap: anywhere;
}

.mention {
  color: black;
  opacity: 1;
  font-weight: 500;
  cursor: pointer;
}

.my-mention {
  color: white;
  opacity: 1;
  font-weight: 500;
  cursor: pointer;
}

.created-at {
  text-align: right;
  color: #a8adaf;
  font-size: 12px;

  padding: 0;
  margin: 0;
}

.my-message-container {
  display: flex;
  justify-content: flex-end;

  margin-bottom: 6px;
}

.my-message-content {
  background: rgba(47, 128, 237, 0.1);
  color: #2f80ed;
  border-radius: 8px 8px 0px 8px;
  padding: 0.5rem 0.6rem;
  display: inline-block;
  max-width: 50%;
}

.my-message-text {
  overflow-wrap: anywhere;
  width: 100%;
}

.my-created-at {
  color: #a8adaf;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.deliver-icon-container {
  opacity: 1;
}

.date-delivered-icon-container {
  display: flex;
  align-items: center;
  text-align: right;
  gap: 7px;

  justify-content: flex-end;
}

.half-white-icon,
.half-pink-icon {
  margin-left: -5px;
}
.half-white-icon {
  margin-top: 2px;
  margin-left: -5.3px;
}

.send-message-container {
  display: flex;
  align-items: center;
  /* gap: 15px; */
  position: relative;
  margin-top: 30px;
}

.typing-message,
.typing {
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.no-messages {
  margin-top: 1rem;
  margin-bottom: 0;
  opacity: 0.7;
}

.selected-user-information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-10%);

  height: 80%;
}

.selected-user-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.selected-user-fullName {
  font-weight: 500;
  color: black;

  padding: 0;
  margin: 0;
}

.start-message {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
}

.mentions-send-message-container {
  position: relative;
}

.mention-container {
  background: white;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  width: 340px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));

  position: absolute;
  bottom: 50px;
  left: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.mention-container::-webkit-scrollbar {
  width: 7px;
}

.mention-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mention-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.no-users-found {
  color: black;
  opacity: 1;

  padding: 0;
  margin: 0.5rem 0;
}

.message-content {
  min-width: 80px;
}

.my-message-text {
  margin: 0;
}

.emoji-icon {
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 105px;

  cursor: pointer;
}

.emoji-container {
  position: absolute;
  bottom: 100%;
  right: 0px;
}

.accept-reject-requested-chat-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.file-icon {
  width: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 135px;

  cursor: pointer;
}

.my-uploaded-files {
  text-align: right;
  margin-left: 50%;
}
.other-user-uploaded-files {
  text-align: left;
}
.uploaded-files-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pdf-message-content {
  /* display: flex; */
  gap: 10px;
  background: #ebebeb;
  color: #2f80ed;

  border-radius: 8px 8px 8px 0;
  padding: 0.5rem 0.6rem;
  cursor: pointer;
  margin: 5px 0;
}

.my-pdf-message-content {
  gap: 10px;
  background: rgba(47, 128, 237, 0.1);
  color: #2f80ed;
  border-radius: 8px 8px 8px 0;
  padding: 0.5rem 0.6rem;
  cursor: pointer;
  margin: 5px 0;
  text-align: right;
}

.file-fileName-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fileName {
  color: #2f80ed;

  cursor: pointer;
  margin: 0;
}

.my-fileName {
  color: #2f80ed;

  cursor: pointer;
  margin: 0;
}

.empty-space {
  margin: 0;
}

.fileName-fileSize-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.file-size {
  margin: 0;
  color: #2f80ed;
}
.my-file-size {
  margin: 0;
  color: #2f80ed;
}

.uploaded-files-preview {
  background: #f8f8f8;
  width: 100%;
  margin-top: 5px;
  border-radius: 6px;
  border: none;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: wrap;
}

.file-preview {
  max-width: 150px;
  max-height: 150px;
}
.file-remove-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.other-user-link,
.my-message-link {
  color: #2f80ed;
  text-decoration: underline;
}
