.locked-nfts-container {
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 2rem;
  background: white;
  border-radius: 16px;
}

.private-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
  width: 40%;
  text-align: center;
}
