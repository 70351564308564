.input {
  background: #f4f5f5;
  border-radius: 30px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
}

.input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.search-input-container {
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 5%;
  top: 60%;
  transform: translateY(-70%);
  z-index: 3;
}
