.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.options-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 12px;
}

.single-container,
.multiple-container {
  background: #f4f5f5;
  border-radius: 12px;
  padding: 2rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.single-container p,
.multiple-container p {
  margin: 0;
  color: #a8adaf;
  font-weight: 500;
}

.selected-container {
  background: #fde5fb;
  border-radius: 12px;
}

.selected-container p {
  color: #ef00d7 !important;
  opacity: 1 !important;
}

.single-info-container h3,
.multiple-upload-info-container h3 {
  margin: 0;
  color: #263238;
}

.single-info-container,
.multiple-upload-info-container {
  margin-top: 10px;
}

.multiple-upload-info-container {
  margin-bottom: 40px;
}

.single-info-container p,
.multiple-upload-info-container p {
  margin: 0;
  color: #a8adaf;
  width: 95%;
}

.options-info-container {
  margin: 20px 0;
}
