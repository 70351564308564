.wallet-address {
  color: #999999;
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.address {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.copy-icon {
  width: 16px;
  margin-left: 5px;
  cursor: pointer;
}

.copied-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.4rem;
  width: 50%;
}

.copied-text {
  padding: 0;
  margin: 0;
  color: #f900b7;
  font-weight: 500;
}

.you-can-copy {
  color: #000000;
  opacity: 0.4;
}
