.footer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.introduction-text {
  opacity: 0.4;
  font-size: 12px;
}
