.details-container {
  padding: 0.4rem 0.2rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  height: 300px;
}

.owner-address-container,
.author-address-container,
.token-address-container,
.ipfs-hash-container,
.token-id-container {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.bold {
  font-weight: 500;
  color: #677074;
  margin: 0;
}

.thin {
  color: #a8adaf;
  margin: 0;
}

.ipfs-link:hover {
  color: #a8adaf;
}
