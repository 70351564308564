.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.invite-friends-container {
  display: flex;
  gap: 20px;
  background: white;
  padding: 1rem;
  border-radius: 16px;
  min-width: 40vw;
}

.user-container,
.suggested-select-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.suggested-select-all {
  margin: 10px 0;
}

.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
}

.select-text,
.suggested-text {
  margin: 0;
  padding: 0;
}
.suggested-text {
  font-size: 16px;
  font-weight: 500;
}

.select-text,
.remove-all-text {
  text-decoration-line: underline;
  color: #a8adaf;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.friends-selected-remove-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-image-name {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-image {
  border: 2px solid #eeeeee;
  object-fit: cover;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.user-name {
  font-weight: 500;
  padding: 0;
  margin: 0;
  opacity: 1;
}

.friends-list,
.selected-friends-list {
  overflow-y: auto;
  height: 330px;
  padding: 0.3rem;
  padding-left: 0;
  padding-top: 0;
}

.selected-friends-list {
  margin-top: 30px;
}

.friends-list::-webkit-scrollbar,
.selected-friends-list::-webkit-scrollbar {
  width: 7px;
}

.friends-list::-webkit-scrollbar-track,
.selected-friends-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.friends-list::-webkit-scrollbar-thumb,
.selected-friends-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.friends-to-invite-container {
  flex-basis: 50%;
}

.invited-friends-container {
  border-radius: 24px 0px 0px 0px;
  padding: 1rem;
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.horizontal-line {
  border-bottom: 1.5px solid #000000;
  opacity: 0.1;
  margin-top: 10px;
}

.approve-text {
  color: #000000;
  opacity: 1;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  font-size: 13.8px;
}

.number-selected-friends {
  opacity: 0.4;
}

.add-admins-text {
  color: black;
  margin: 10px 0;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 60%;
  transform: translateY(-70%);
  z-index: 3;
}

@media only screen and (max-width: 650px) {
  .invite-friends-container {
    flex-direction: column;
  }
}
