.replies-container {
  margin-left: 60px;
}

.post-comment-container {
  display: flex;
  gap: 10px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-self: flex-start;
  object-fit: cover;
}

.empty-space {
  margin: 0;
}

.comment-container {
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
}

.comment-text {
  font-size: 12px;
}
.user-fullName {
  color: #263238;

  font-size: 14px;
}

.comment-container p {
  padding: 0;
  margin: 0;
}

.liked-text {
  cursor: pointer;
  color: #f900b7;

  font-size: 12px;
  margin-top: 8px;
  margin-right: 20px;
}

.like,
.reply {
  cursor: pointer;
  color: black;
  font-size: 12px;
  margin-top: 8px;
}

.like {
  margin-right: 20px;
}

.replying {
  cursor: pointer;
  color: black;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 500;
}

.createdAt {
  color: black;
  opacity: 0.4;
  align-self: center;
  font-size: 12px;
}

.view-more-replies {
  display: inline-block;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.mention,
.mention:hover {
  font-weight: 500;
  color: black;
  font-size: 12px;
}
