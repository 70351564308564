.input {
  background: #f8f8f8;
  border-radius: 50px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
}

.input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}
