.collaborator-requests-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.collaborator-requests-container {
  margin-top: 2rem;
}

.collaborator-requests-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.collaborator-requests-sections {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-top: 10px;
}

.collaborator-requests-section {
  margin: 0;
  padding: 0;
  color: #000000;
  opacity: 0.4;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.price-text {
  color: #999999 !important;
}

.input-search-container {
  display: flex;
  align-items: center;
  width: 70%;
}

.search-icon {
  margin-left: -35px;
  cursor: pointer;
  width: 18px;
}

.input-container {
  margin: 1rem auto;
}

.sort-option:not(:last-child) {
  margin-bottom: 10px;
}

.sent-received-collaborator-requests-container {
  margin-top: 1rem;
}

.collaborator-requests-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  /* background: #fafafa; */
}
.collaborator-requests-header {
  border-bottom: 1px solid #e9ebeb;
}

.collaborator-requests-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}
.collaborator-requests-header,
.collaborator-requests-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.collaborator-requests-options-container {
  gap: 20px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.collaborator-requests-option-text {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.collaborator-requests-option-text-selected {
  color: #263238;
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .collaborator-requests-page-container {
    display: flex;
    flex-direction: column;
  }
  .filters-container {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .collaborator-requests {
    margin-left: 6%;
    margin-right: 6%;
  }
}
