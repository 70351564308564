.my-groups-list {
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 20px;
  padding: 1rem;
  margin-top: 1rem;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cover-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.suggested-text {
  font-size: 16px;
  font-weight: 500;
  color: black;
  padding: 0;
  margin: 0;
}

.group-name {
  font-size: 16px;
  opacity: 1;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
  overflow-wrap: break-word;

  color: black;
  font-weight: 500;
}

.groups-list {
  background: white;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 20px;
  padding: 1rem;
  margin-top: 1rem;
}

.group {
  box-sizing: border-box;
  border-radius: 16px;
  padding-bottom: 20px;
  margin: 10px 0;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suggested-groups-container {
  padding: 1rem;
  background: white;
  border-radius: 16px;
  height: 100%;
}

.see-all-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.see-all-container a {
  margin-left: auto;
}

.see-all-text {
  color: #f900b7;
  text-decoration: underline;
  margin: 0;
  margin-left: auto;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-cover-photo {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
  object-fit: cover;
}

.number-of-members {
  color: black;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.status-icon {
  height: 20px;
}

.status-text {
  margin: 0;
  opacity: 0.7;
  color: black;
}

.status-container,
.members-group-status-container {
  margin: 5px 0;
  display: flex;
  gap: 5px;
  align-items: center;
}

.name-members-status-container {
  align-self: flex-start;
  padding: 0.25rem;
  padding-left: 1rem;
}

.suggested-groups-container {
  padding: 1rem;
  background: white;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
}

.groups-list {
  padding-right: 10px;
}

.see-all-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.see-all-container a {
  margin-left: auto;
}

.see-all-text {
  color: #a8adaf;
  text-decoration: underline;
  margin: 0;
  margin-left: auto;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-cover-photo {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 12px;
}

.suggested-group,
.private-suggested-group {
  width: 100%;
  height: 120px;
  border-radius: 12px;
  position: relative;
  background: linear-gradient(225deg, #ff3cac 0%, #562b7c 51.71%, #2b86c5 100%);
  margin-top: 15px;
}
.private-suggested-group {
  background: linear-gradient(
    225deg,
    #231557 0%,
    #44107a 28.6%,
    #ff1361 66.82%,
    #fff800 100%
  );
}

.communities-icon-container {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}

.communities-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.group-name-status-container {
  position: absolute;
  top: 5px;
  left: 60px;
}

.number-of-members {
  color: white;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.members-container {
  position: absolute;
  bottom: 0px;
  left: 10px;
}
.members-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
  margin: 0;
  padding: 0;
}

.members-value {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-weight: 500;
  text-align: center;
}
.join-request-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.join-container,
.request-container {
  background: #e9ebeb;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 22px;
  cursor: pointer;
}
.request-container {
  background: linear-gradient(180deg, #00b1ff 0%, #c25eff 100%);
}

.join-text,
.request-text {
  color: #ef00d7;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.request-text {
  color: white;
}

.group-name {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  overflow-wrap: break-word;
  color: #ffffff;
}

.status-icon {
  height: 20px;
}

.status-text {
  margin: 0;
  opacity: 0.7;
  color: white;
  font-size: 14px;
}

.status-container,
.members-group-status-container {
  margin: 5px 0;
  display: flex;
  gap: 5px;
  align-items: center;
}

.name-members-status-container {
  position: absolute;
  bottom: 60px;
  left: 10px;
}

.circle {
  background: rgba(255, 255, 255, 0.7);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.suggested-groups-text {
  color: #263238;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

@media only screen and (max-width: 800px) {
  .suggested-groups-page {
    grid-template-columns: 1fr;
  }
}
