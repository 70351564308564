.get-started-page {
  display: grid;
  grid-template-columns: 260px 1fr;
  min-height: 100vh;
  background: #fafafa;
}

.get-started-container {
  padding: 0.75rem 0.25rem;
  margin: 2rem auto;
  margin-top: 10px;
  max-width: 1200px;
}

.get-started-header {
  border-bottom: 1px solid #e9ebeb;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.to-do-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-top: 2rem;
}

.to-do-text-container {
  padding: 1rem;
}

.to-do-text {
  color: #263238;
  border-bottom: 1px solid #e9ebeb;
  margin: 0;
}

.tasks-container {
  display: flex;
  gap: 20px;

  flex-wrap: wrap;
  padding: 1rem;
  padding-top: 0;
}

.task-container {
  background: #f8f8f9;
  border-radius: 2px 12px 12px 2px;
  padding: 1rem;
  cursor: pointer;
}

.selected-task-container {
  background: rgb(220, 218, 218, 0.4);
}

.task-title {
  color: #263238;
  margin: 0;
  font-size: 14px;
}

.task-description {
  color: #a8adaf;
  margin: 0;
  font-size: 12px;
  width: 300px;
  height: 55px;
}
.task-button-text {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
}

.how-to-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-top: 2rem;
}

.how-to-text-container {
  padding: 1rem;
}

.how-to-text {
  color: #263238;
  margin: 0;
  border-bottom: 1px solid #e9ebeb;
}

.questions-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
  padding-top: 0;
}
.question-container {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e9ebeb;
  cursor: pointer;
}

.plus-icon {
  width: 14px;
}
.question-text {
  color: #677074;
  margin: 0;
}

.answer-container {
  margin: 10px 1.5rem;
  margin-bottom: 0;
  border-bottom: 1px solid #e9ebeb;
}
.answer-text {
  color: #a8adaf;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}

.completed-claim-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.claim-text {
  color: #ef00d7;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .tasks-container {
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .get-started-page {
    display: flex;
    flex-direction: column;
  }
}
