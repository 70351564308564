.poll-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  margin-top: 10px;
}

.header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.creator-photo {
  width: 40px;
  object-fit: cover;
  height: 40px;
  border: 2px solid #eeeeee;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.creator-fullName,
.created-at {
  margin: 0;
}

.creator-fullName {
  color: #000000;
  font-weight: 500;
}

.created-at {
  color: #8d8d8d;
}

.creator-line {
  display: flex;
  align-items: center;
  gap: 10px;
}

.circle {
  width: 5px;
  height: 5px;
  background: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}

.created-poll-text {
  margin: 20px;

  color: #000000;
  margin: 0;
  opacity: 0.4 !important;
}

.question-text {
  color: #000000;
  font-weight: 500;
  margin: 0;
  overflow-wrap: break-word;
  width: 60%;
}

.question-duration-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;
}

.has-ended {
  color: #ff5252;
  font-weight: 500;
}

.ends-in {
  color: #ff9900;
  font-weight: 500;
  width: 220px;
  margin-right: -40px;
}

.answer-container {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 10px;
  padding: 1rem;
  cursor: pointer;
  flex: 1;
  position: relative;
}

.answer-text {
  color: black;
  margin: 0;
  opacity: 0.4;
  overflow-wrap: break-word;
}

.my-vote-container {
  background: rgba(0, 180, 50, 0.2);
  border-radius: 16px 0px 0px 16px;
  padding: 1rem;
  width: 50px;
  height: 54px;
  position: relative;
  margin: 0;
  top: 5px;
  left: 0px;
}

.answer-votes-container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 40px;
}

.my-voted-answer-container {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
}

.green-ok-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.number-of-votes-text {
  color: #000000;
  opacity: 0.4;
  margin: 0;
  margin-right: 24px;
  margin-top: 10px;
}

.voted-by-photo {
  border: 1.25px solid #eeeeee;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -10px;
}

.voted-by-container {
  display: flex;
  align-items: center;
  margin-left: -15px;
  cursor: pointer;
}

.extra-votes-container {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  position: relative;
  border-radius: 50%;
  margin-left: -15px;
}

.extra-votes-text {
  color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  opacity: 0.4;
}

.votes-results {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
  width: 100px;
}
