.upload-later-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.schedule-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 20px;
}

.input-label-container {
  display: flex;
  flex-direction: column;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #677074;
  font-size: 12px;
  margin-top: 10px;
}

.upload-later-text {
  margin: 0;
  font-weight: 400;
  color: #677074;
}

.upload-now-text {
  margin: 0;
  font-weight: 400;

  color: #677074;
}

.selected {
  background: #ef00d7 !important;
}

.selected p {
  color: white !important;
}
.am-container {
  background: #f4f5f5;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
}
.am-container p,
.pm-container p {
  margin: 0;
  color: #a8adaf;
  font-weight: 400;
  opacity: 1;
}
.pm-container {
  background: #f4f5f5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.hours-minutes-container {
  display: flex;
  align-items: center;
}
