.user-info-container {
  background-color: #0a1322;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  border-radius: 10px;
}

.user-photo {
  object-fit: cover;
  border-radius: 100px;
}

.user-stats-container {
  background: #0a1322;
  padding: 30px;
  color: white;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-stats-heading {
  color: #f92baf;

  font-weight: bold;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0;
}

.pagination-col {
  font-weight: bold;
}
.likes-container {
  background: #0a1322;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  padding-bottom: 0;
  margin-top: 25px;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  gap: 50px;
}
