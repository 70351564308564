.no-posts-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
}

.no-posts-found-container p {
  color: #a8adaf;
  padding: 0;
  margin: 0;
}
