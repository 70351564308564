.post-container {
  background: white;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid #e9ebeb;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.right-arrow {
  margin: 0 5px;
}

.user {
  display: flex;
  align-items: center;
  gap: 15px;
}

.fullName {
  color: black;
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.created-at {
  color: #8d8d8d;
  font-size: 12px;
  margin: 0;
}

.more {
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.post-content-container {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  color: #263238;
}

.post-content-text {
  color: #263238;
  font-weight: 400;
  margin: 0;
  padding: 0;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
}

.image-container {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
}
.post-image-container {
  position: relative;
}
.post-image {
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.08));
  border-radius: 14px;
  max-height: 360px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.watchlist-likes-share-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 42px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 0rem;
  height: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.watchlist-container,
.likes-container,
.share-container {
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}
.watchlist-icon,
.likes-icon,
.share-icon {
  width: 15px;
}

.watchlist-number,
.likes-number {
  color: #263238;
  font-size: 12px;
  margin: 0;
  padding: 0;
  width: 2px;
}

.share-container {
  position: relative;
}

.share-icons-container {
  position: absolute;
  top: 10px;
  right: 10px;
  position: absolute;
  top: -235px;
  right: -60px;
  padding: 1rem;
  background: white;
  border: 1px solid #e9ebeb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 100;
}

.tip-buy-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 42px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 0rem;
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.tip-container {
  cursor: pointer;
}
.diamond-icon {
  width: 15px;
  margin-left: 5px;
}
.buy-text {
  color: #ef00d7;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.buy-text-disabled {
  cursor: not-allowed;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #263238;
}
.buy-container {
  cursor: pointer;
}

.vertical-line {
  height: 100%;
  width: 1px;
  color: rgba(233, 235, 235, 1);
  background: rgba(233, 235, 235, 1);
}

.tip-options-container {
  display: flex;
  align-items: center;
  gap: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  background: white;
  position: absolute;
  bottom: 40px;
  right: 0;
  border-radius: 12px;
  z-index: 12;
}

.box-option {
  background: #f8f8f9;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.4rem;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  position: relative;
}

.box-option:hover {
  background: #fde5fb;
}
.box-option:hover p {
  color: #ef00d7;
}
.go-back-tip-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 15%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  padding: 10px;
  cursor: pointer;
  border-right: 1px solid #e9ebeb;
}
.go-back-icon {
  height: 12px;
}

.points-value {
  color: #a8adaf;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
}
.diamond-icon {
  width: 14px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.unlock-post-container {
  position: absolute;
  bottom: 0;
  left: 0;

  background: #f8f8f9;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  text-align: center;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.unlock-post-text {
  margin: 0;
  color: #677074;
}

.price-text {
  font-weight: 500 !important;
}

.followers-post-type-container,
.collectors-post-type-container,
.public-post-type-container {
  border-radius: 13px;
  padding: 0.3rem 0.7rem;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
  margin-right: 20px;
  background: linear-gradient(
    103.45deg,
    rgba(255, 126, 161, 0.2) 4.26%,
    rgba(246, 67, 198, 0.2) 30.75%,
    rgba(160, 116, 242, 0.2) 55.56%,
    rgba(113, 185, 216, 0.2) 85%
  );
  border-radius: 24px;
}

.exclusive-followers-text,
.exclusive-collectors-text,
.exclusive-public-text {
  font-weight: 500;
  padding: 0;
  margin: 0;
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.exclusive-followers-text {
  color: rgba(255, 168, 0, 1);
}

.exclusive-collectors-text {
  color: rgba(0, 148, 255, 1);
}

.public-post-type-container {
  color: rgba(249, 0, 183, 1);
}

.followers-post-type-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 400px) {
  .watchlist-likes-share-container {
    width: 120px;
    gap: 10px;
    padding: 0.2rem 0.4rem;
  }

  .tip-buy-container {
    width: 90px;
    gap: 10px;
    padding: 0.2rem 0.4rem;
  }
}
