.nft-collection-container {
  display: grid;
  grid-template-columns: 260px 1fr;
  min-height: 100vh;
  background: #fafafa;
}

.nft-collection-information-container {
  background: white;
  margin-top: 2rem;
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .nft-collection-container {
    display: flex;
    flex-direction: column;
  }
}
