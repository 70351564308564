.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collection-container {
  padding: 0.75rem 1rem;
}

.cover-photo {
  width: 100%;
  height: 300px;
  cursor: pointer;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.profile-photo {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  cursor: pointer;
}

.collection-name {
  margin: 0;
  color: #263238;
  font-weight: 500;
}

.created-by {
  font-size: 12px;
  color: #999999;
  margin: 0;
}

.username,
.username:hover {
  font-size: 12px;
  color: #263238;
  font-weight: 500;
  margin: 0;
  margin-left: 2px;
}

.profile-photo-creator-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.new-collection-text {
  color: #f900b7;
  font-weight: 500;
  margin: 0;
}

.live-date-capped-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: flex-start;
  margin-top: 10px;
}

.limited-text,
.unlimited-text {
  color: #263238;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
}

.limited-collection-container {
  background: #f4f5f5;
  border-radius: 119px;
  width: 150px;
  padding: 0.75rem 0.5rem;
  text-align: center;
}

.glimpse-collection-link-container {
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  flex: 1;
  border-radius: 119px;
  width: 150px;
  padding: 0.75rem 0.5rem;
  text-align: center;

  background: #f4f5f5;
}

.associated-group-text {
  color: #263238;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
}

.description {
  color: #677074;
  margin: 10px;
  width: 50%;
}

.verified-icon {
  width: 15px;
  margin-bottom: 2px;
  margin-left: 5px;
}

.statistics-container {
  background: rgba(196, 196, 196, 0.05);
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px auto;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}

.stats-text {
  color: #677074;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.stats-description {
  color: #677074;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.vertical-line {
  border-bottom: 1.5px solid #eaeaea;
  transform: rotate(90deg);
  width: 50px;
  height: 10px;
}

.add-to-watchlist-socials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.unique-owners-container {
  cursor: pointer;
}

.social-container {
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
}

.social-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
}

.more-options-container {
  position: relative;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1.5px solid #eeeeee;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ef00d7;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.more-options {
  width: 200px;
  background: white;
  padding: 0.2rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  position: absolute;
  right: 30px;
  bottom: 0;
  z-index: 2;
}

.option {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0;
}

.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.socials-container {
  display: flex;
  gap: 5px;
}

.social-vertical-line {
  opacity: 0.1;
  transform: rotate(90deg);
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 1px;
  align-self: center;
}

.profile-photo-container {
  position: relative;
}

.private-status-container {
  position: absolute;
  bottom: -14%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.private-key-icon {
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-socials-found {
  color: #263238;
  margin: 0;
  align-self: center;
  font-weight: 500;
}

.items-container {
  margin-left: 7%;
}
.floor-price-container {
  margin-right: 7%;
}

@media only screen and (max-width: 700px) {
  .statistics-container {
    flex-direction: column;
  }

  .add-to-watchlist-socials-container {
    flex-wrap: wrap;
  }

  .items-container {
    margin-left: 0;
  }

  .floor-price-container {
    margin-right: 0%;
  }
}

@media only screen and (max-width: 1300px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 500px) {
  .live-date-capped-info-container {
    flex-wrap: wrap;
  }

  .vertical-line {
    display: none;
  }

  .socials-container {
    margin-top: 10px;
  }
}
