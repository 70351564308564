.watchlist-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}

.selected-section {
  color: #f900b7;
  font-weight: 500;
  opacity: 1 !important;
}

.horizontal-line {
  width: 95%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
  margin-bottom: 30px;
}

.section-content {
  margin-top: 10px;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.watchlist-text {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.recent-sales-sidebar-container {
  padding: 1rem;
  height: 100%;
}

.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 10px;
  row-gap: 30px;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.sections-container {
  margin-top: 2rem;
}

.watchlist-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  background: #fafafa;
}
.watchlist-header {
  border-bottom: 1px solid #e9ebeb;
  margin-bottom: 20px;
}

.watchlist-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}
.watchlist-header,
.watchlist-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.watchlist-options-container {
  gap: 20px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.watchlist-option-text {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.watchlist-option-text-selected {
  color: #263238;
  font-weight: 500;
}

@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .watchlist-container {
    margin-left: 6%;
    margin-right: 6%;
  }
}

@media only screen and (max-width: 900px) {
  .watchlist-page-container {
    display: flex;
    flex-direction: column;
  }
}
