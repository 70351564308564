.replies-container {
  margin-left: 60px;
}

.post-comment-container {
  display: flex;
  gap: 10px;
}

.user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-self: flex-start;
  object-fit: cover;
}

.empty-space {
  margin: 0;
}

.comment-container {
  /* background: #f8f8f8; */
  border-radius: 10px;
  padding: 0.5rem;
  position: relative;
}

.comment-text {
  font-size: 12px;
}
.user-fullName,
.user-fullName:hover {
  color: #263238;
  font-size: 14px;
}

.comment-container p {
  padding: 0;
  margin: 0;
}

.liked-text {
  cursor: pointer;
  color: #f900b7;

  font-size: 12px;
  margin-top: 8px;
  margin-right: 20px;
}

.like,
.reply {
  cursor: pointer;
  color: black;
  font-size: 12px;
  margin-top: 8px;
}

.like {
  margin-right: 20px;
}

.replying {
  cursor: pointer;
  color: black;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 500;
}

.createdAt {
  color: #a8adaf;
  font-size: 12px;
  align-self: flex-start;
  margin: 0;
  padding: 0;
  margin-top: 3px;
}

.view-more-replies {
  display: inline-block;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.recycle-bin {
  color: #ff5252;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.more {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 10;
}

.more-options {
  width: 120px;
  background: white;
  padding: 0.75rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.mention,
.mention:hover {
  font-weight: 500;
  color: #677074;

  font-size: 12px;
}

@media only screen and (max-width: 1400px) {
}
