.choose-who-can-see-text {
  padding: 0;
  margin: 0;
  color: #a8adaf;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-status-option {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.world-container {
  background: rgba(0, 0, 0, 0.05);
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.world-icon {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -50%);
}

.status-text,
.status-description {
  padding: 0;
  margin: 0;
}

.status-text {
  color: black;
  font-size: 16px;
}

.status-description {
  color: #677074;
}

.post-status-option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected {
  border: 2px solid #f900b7;
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  position: relative;
}

.selected::after {
  content: '';
  box-sizing: border-box;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #f900b7;
  transform: translate(-50%, -50%);
}

.not-selected {
  border: 2px solid #999999;
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.select-post-status-modal-container {
  overflow-y: auto;
  height: 440px;
  padding-right: 5px;
}

.select-post-status-modal-container::-webkit-scrollbar {
  width: 10px;
}

.select-post-status-modal-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 6px;
}

.select-post-status-modal-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 6px;
}
