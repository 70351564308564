.received-collaborator-nft-request-container {
  border-radius: 10px;
  background: #ffffff;
  max-width: 330px;
  overflow: hidden;
  border: 1px solid #e9ebeb;
}

.nft-image {
  width: 330px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.from-info-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 10px 0;
}

.from-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
}

.received-collaborator-nft-request-info-container {
  padding: 0.5rem;
}

.from-fullName {
  margin: 0;
  opacity: 1;
  color: black;
}

.received-on {
  color: #999999;
  font-size: 12px;
  margin: 0;
}

.button-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0;
}

.button-actions-container button {
  width: 100%;
}

.collection-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}

.collection-container {
  background: #f8f8f8;
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 10px 0;
}

.collection-cover-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.collection-title {
  color: #000000;
  margin: 0;
  font-weight: 500;
}

.from-text {
  color: #999999;
  margin: 0;
}
