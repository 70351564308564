.join-community-modal-container {
  height: 400px;
}
.join-community-modal-container::-webkit-scrollbar {
  width: 6px;
}

.join-community-modal-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.join-community-modal-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.different-type-text {
  color: #677074;
}

.community-icon-container {
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  align-self: flex-start;
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.community-icon {
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.public-groups,
.private-groups,
.collectible-gated-groups {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e9ebeb;
  margin: 10px 0;
}

.group-description {
  font-size: 12px;
  color: #677074;
  margin: 0;
}

.group-type {
  color: #263238;
  font-size: 14px;
  margin: 0;
}

.group-cover-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
  object-fit: cover;
}

.suggested-group {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9ebeb;
  gap: 10px;
  margin: 10px 0;
  padding: 5px;
  padding-left: 0;
}

.group-list {
  margin: 10px 0;
}

.join-container,
.request-container {
  background: #fde5fb;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border-radius: 12px;
}
.request-container {
  padding: 0.5rem 1.1rem;
}
.join-text,
.request-text {
  margin: 0;
  color: #ef00d7;
}
.join-request-container {
  margin-left: auto;
}

.group-name {
  margin: 0;
  color: #263238;
  font-size: 14px;
}
