.apply-as-creator-container {
  margin: 0 25%;
}

.glimpse-logo {
  height: 40px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 5%;
}

.apply-container {
  background: white;
  border: 1px solid #e9ebeb;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 2rem;
}

.apply-as-creator-container-go-back-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.apply-as-a-creator-image-container {
  padding: 0 2rem;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.content-creator-image {
  height: 200px;
}

.apply-as-a-creator-text {
  font-weight: 700;
  font-size: 35px;
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.join-community-text {
  font-weight: 400;
  color: #263238;
  width: 55%;
  text-align: center;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.apply-form-container {
  background: white;
  border-radius: 10px;
  padding: 0 1rem;
  margin: 0 10%;
}

.first-last-name-container {
  display: flex;
  align-items: center;
  gap: 5%;
}

.first-name-container,
.last-name-container {
  flex: 1;
}

.apply-button-container {
  margin: 1rem 0;
  margin-bottom: 1rem;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .apply-as-a-creator-image-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .apply-as-creator-container {
    margin: 0 15%;
  }

  .content-creator-image {
    height: 300px;
  }
}

@media only screen and (max-width: 800px) {
  .apply-as-creator-container {
    margin: 0 3%;
  }

  .first-last-name-container {
    flex-direction: column;
  }

  .first-name-container,
  .last-name-container {
    width: 100%;
  }
}
