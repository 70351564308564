.requested-service-page-container {
  display: grid;
  grid-template-columns: 260px 1fr;
  min-height: 100vh;
}

.requested-service-header {
  border-bottom: 1px solid #e9ebeb;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.requested-service-container {
  padding: 0.75rem 0.25rem;
  margin: 0 15%;
  margin-top: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.from-fullName-header {
  color: rgba(168, 173, 175, 1) !important;
  margin: 0;
  font-weight: 400;
}

.from-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.photo-fullName-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.from-fullName {
  color: #263238;
  margin: 0;
  font-weight: 500;
}

.created-at {
  color: #263238;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.service-icon {
  width: 20px;
  height: 20px;
}

.service-icon-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.service-container {
  background: #f8f8f9;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  margin: 0.5rem 1rem;
  width: 300px;
}
.service-text {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}

.price {
  color: #263238;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
}

.buyer-details-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-top: 10px;
}

.buyer-details-text {
  color: #263238;
  margin: 0;
  padding: 1rem;
}

.buyer-info {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delivery-order-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin: 10px 0;
}

.delivery-order-text {
  padding: 1rem;
  color: #263238;
  margin: 0;
}

.upload-text {
  color: #677074;
  margin: 0;
}

.upload-container {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.upload-file-container {
  background: #f4f5f5;
  width: 400px;
  height: 200px;
  border: 1px dashed #d4d6d7;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textarea {
  background: #f4f5f5;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.actions-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.uploaded-file {
  width: 400px;
  height: 200px;
}

.note-container {
  padding-left: 1rem;
  margin-bottom: 10px;
}

.note-text {
  color: #263238;
  margin: 0;
  font-weight: 500;
}

.note-value {
  margin: 0;
  color: #a8adaf;
}

@media only screen and (max-width: 1480px) {
  .requested-service-container {
    margin: 0 10%;
  }
}

@media only screen and (max-width: 1320px) {
  .requested-service-container {
    margin: 0 5%;
  }
}

@media only screen and (max-width: 1200px) {
  .upload-container {
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .buyer-info {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .requested-service-page-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .upload-container {
    justify-content: space-between;
  }

  .textarea {
    width: 100% !important;
    min-width: 250px;
  }

  .upload-file-container {
    width: 100% !important;
    min-width: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .upload-container {
    flex-direction: column;
  }
}
