.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.glms-usd-conversion-text {
  color: #f900b7;
  opacity: 1 !important;
  margin: 0;
  padding: 0;
  margin-left: 4px;
}
