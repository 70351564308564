.my-groups-page-container {
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 30px;
  margin-right: 10%;
}

@media only screen and (max-width: 1200px) {
  .my-groups-page-container {
    grid-template-columns: 280px 1fr;
    margin-right: 2%;
  }
}

@media only screen and (max-width: 900px) {
  .my-groups-page-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .my-groups-page-container {
    grid-template-columns: 1fr;
  }

  .about-container {
    grid-row: 3/4;
    grid-column: 1/-1;
    width: 100%;
  }
}
