.support-container {
  background: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 3rem 2rem;
  margin: 2% 8%;
  text-align: center;
}

.support-text {
  margin: 0;
  margin-top: 2px;
  font-size: 18px;
  font-weight: 500;
}
