.card-container {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.2s ease;
}

.user-image {
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 50px rgba(0, 0, 0, 0.2));
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.verified-icon {
  width: 17px;
  margin-left: 5px;
  margin-bottom: 2px;
}

.mutual-followers {
  color: #999999;
  padding: 0;
  cursor: pointer;
}
