* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 700px) {
  body {
    font-size: 80%;
  }
}

@media only screen and (max-width: 500px) {
  body {
    font-size: 70%;
  }
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 6px;
}

.emoji-mart-scroll {
  height: 150px !important;
}

.emoji-mart-scroll::-webkit-scrollbar {
  width: 5px;
}

.emoji-mart-scroll::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 2.5px;
}

.emoji-mart-scroll::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 2.5px;
}

.emoji-mart-search input {
  background: #f8f8f8;
  border-radius: 6px;
  padding: 6px;
  border: none;
  border: 1px solid transparent;
  margin-bottom: 10px;
  font-size: 14px;
}

.emoji-mart-search input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}
.emoji-mart-search-icon {
  display: none;
}

.emoji-mart-category-label span {
  font-family: 'Poppins' !important;
  font-size: 14px;
  opacity: 0.7;
}

.emoji-mart {
  border: none;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-family: 'Poppins' !important;
}

.emoji-mart-bar {
  display: none;
}

.notification-points-container {
  background: #ffffff;
  box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 1.5rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000000;
  position: fixed;
  top: 75px;
  right: 20px;
  max-width: 400px;
}

.notification-points-message {
  color: #000000;
  opacity: 0.4;
  width: 90%;
  text-align: center;
}

.you-won-points {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.my-facebook-button-class {
  background: linear-gradient(96.77deg, #4267b2 -10.47%, #4267b2 127.35%);
  border-radius: 2px;
  color: white;
  padding: 0.8rem 1.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.react-pdf__Page {
  height: 25px;
  width: 25px;
}

.login-with-google-btn,
.my-facebook-button-class {
  background: #ffffff !important;
  box-shadow: none !important;
  border-radius: 56px !important;
  overflow: hidden;
  border: 1px solid #e9ebeb !important;
}

.login-with-google-btn div {
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 3px;

  background: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.login-with-google-btn p {
  color: #a8adaf;
  font-weight: 400;
  opacity: 1;
  margin: 0;
}

.my-facebook-button-class {
  display: flex;
  align-items: center;
  color: #a8adaf;
  font-weight: 400;
  opacity: 1;
  margin: 0;
  position: relative;
  padding: 0.6rem 1.7rem;
  padding-right: 1.4rem;
  border: 1px solid #e9ebeb !important;
}

.ant-layout-content {
  min-height: 100vh;
}

.login-with-google-btn,
.facebook-login-container span,
.facebook-login-container,
.my-facebook-button-class {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.my-facebook-button-class {
  align-self: center;
  margin: 0;
}
