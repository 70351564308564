.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.label:first-child {
  margin-top: 5px;
}

.status-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  gap: 5px;
}

.status,
.status:hover {
  font-size: 12px;
  color: #f900b7;
}

.paragraph {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.checkbox {
  margin-bottom: 11px;
  accent-color: #f900b7;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
