.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thin-text {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.social-icon-name-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-icon {
  width: 16px;
}

.social-text {
  padding: 0;
  margin: 0;
  color: #677074;
  font-size: 12px;
  font-weight: 400;
}

.social-details-text {
  color: #263238;
  font-weight: 500;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 20px;
}
