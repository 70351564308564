.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-custom-new-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-custom-service-text {
  color: #677074;
  margin: 0;
}

.add-new-text {
  text-decoration-line: underline;
  color: #ef00d7;
  margin: 0;
  cursor: pointer;
}
.popular-services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 5px;
  padding-right: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.popular-services-container::-webkit-scrollbar {
  width: 6px;
}

.popular-services-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.popular-services-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.service-container {
  background: #f4f5f5;
  text-align: center;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selected-service-container {
  background: #f4f5f5;
  text-align: center;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid #ef00d7;
}
.selected-service-container p {
  color: #ef00d7 !important;
  margin: 0;
}

.service-container p {
  color: #a8adaf;
  margin: 0;
}

.community-text {
  color: #677074;
  margin: 0;
  margin-top: 5px;
}
.group-container {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photo-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cover-photo {
  border: 1px solid #e9ebeb;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.group-name {
  color: #263238;
  margin: 0;
}

.group-list-container {
  height: 140px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.group-list-container::-webkit-scrollbar {
  width: 6px;
}

.group-list-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
