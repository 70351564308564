.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.label:first-child {
  margin-top: 5px;
}

.username-change-container,
.email-change-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.change-text {
  cursor: pointer;
  text-decoration-line: underline;
  color: #f900b7;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
