.nft-created-successfully-page {
  display: grid;
  grid-template-columns: 300px 1fr;
}

.nft-created-successfully-container {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 90vh;

  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}

.success-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.success-circle {
  position: relative;
}

.success-image {
  height: 250px;
}

.done-text {
  color: black;
  font-weight: 500;

  margin-top: 10px;
}

.success-upload-text {
  color: #a8adaf;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  text-align: center;
}

.copy-link-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copy-text {
  color: #ef00d7;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
}

.copy-icon {
  cursor: pointer;
  height: 16px;
}

@media only screen and (max-width: 900px) {
  .nft-created-successfully-page {
    display: flex;
    flex-direction: column;
  }

  .nft-created-successfully-container {
    margin: 1%;
  }
}
