.create-first-collectible-modal-container {
  height: 390px;
}
.different-type-text {
  color: #677074;
}

.single-nft-icon-container,
.collection-icon-container,
.airdrop-icon-container {
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  align-self: flex-start;
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}

.single-nft-icon,
.collection-icon,
.airdrop-icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-nfts,
.collections,
.airdrops {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e9ebeb;
  margin: 10px 0;
}

.create-collectible-type,
.create-airdrop-type {
  color: #263238;
  font-size: 14px;
  margin: 0;
}

.create-collectible-description,
.create-airdrop-description {
  font-size: 12px;
  color: #677074;
  margin: 0;
}
