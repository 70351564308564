.nft-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  background: #f8f8f8;
  min-height: 100vh;
}
.recent-sales-sidebar-container {
  padding: 1rem;
}

@media only screen and (max-width: 900px) {
  .nft-page-container {
    display: flex;
    flex-direction: column;
  }
}
