.groups-page-container {
  display: grid;
  grid-template-columns: 260px calc(100vw - 680px) 320px;
  gap: 20px;
  column-gap: 0;
  min-height: 100vh;
}

.recent-activity-container {
  grid-column: 2/3;
  grid-row: 1/-1;
  margin-left: 18%;
  margin-top: 2rem;
}

.suggested-groups-container {
  position: fixed;
  top: 20px;
  right: 0;
  width: 320px;
}

.groups-sidebar-container {
  grid-row: 1/-1;
  grid-column: 1/2;
  justify-content: start;
}
@media only screen and (max-width: 1400px) {
  .groups-sidebar-container {
    grid-row: 2/-1;
  }
}

@media only screen and (max-width: 1250px) {
  .suggested-groups-container {
    grid-row: 3/4;
    grid-column: 2/-1;
    margin-left: 3rem;
    position: relative;
    width: 100%;
  }
  .recent-activity-container {
    grid-column: 2/-1;
    margin-left: 3rem;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .groups-page-container {
    display: flex;
    flex-direction: column;
  }

  .recent-activity-container,
  .suggested-groups-container {
    margin: 0;
  }
}
@media only screen and (max-width: 750px) {
  .suggested-groups-container {
    grid-row: 4/5;
    grid-column: 1/-1;
  }

  .groups-page-container {
    margin: 0;
    padding: 1rem;
    width: 100vw;
    grid-template-columns: 1fr;
  }

  .groups-sidebar-container {
    grid-column: 1/-1;
  }
}

@media only screen and (max-width: 450px) {
  .recent-activity-container,
  .suggested-groups-container,
  .groups-sidebar-container {
    align-content: center;
    justify-content: center;
  }
}
