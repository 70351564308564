.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-image {
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.user-post-status {
  display: flex;
  gap: 10px;
  align-items: center;
}
.user-fullName {
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 500;
}
.post-status-text {
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.post-status-container {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 0.1rem 0.5rem;
  align-self: flex-start;
}

.post-status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-container {
  position: relative;
  margin-top: 10px;
}

.emoji-icon {
  position: absolute;
  bottom: 15px;
  width: 18px;
  right: 20px;
  cursor: pointer;
}

.tag-icon {
  position: absolute;
  bottom: 10px;
  width: 30px;
  right: 90px;
  height: 24px;
  cursor: pointer;
}

.image-post-icon {
  position: absolute;
  bottom: 15px;
  width: 18px;
  right: 45px;
  cursor: pointer;
}

.emoji-container {
  position: absolute;
  bottom: -50px;
  right: 0px;
}

.fullName-feeling-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.circle {
  background: #f2994a;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.feeling-container {
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 5px;
}

.feeling-text {
  padding: 0;
  margin: 0;
  color: #f2994a;
}

.fullName-post-status-container {
  display: flex;
  flex-direction: column;
}

.upload-image-container {
  background: rgba(0, 0, 0, 0.02);
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 93%;
  height: 215px;
  position: relative;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.4rem;
  overflow-y: auto;
  flex-wrap: wrap;
}

.upload-image-container::-webkit-scrollbar {
  width: 7px;
}

.upload-image-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.upload-image-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.uploaded-image {
  width: 100px;
  height: 155px;
  object-fit: cover;
  margin-top: 27px;
}

.image-container,
.video-container {
  position: relative;
}

.remove-video-icon-container {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 150px;
  left: 262px;
}

.remove-icon-container {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 150px;
  left: 70px;
}
.remove-video-icon-container::after,
.remove-icon-container::after {
  content: '';
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 7px;
  transform: translate(-50%, -50%);
}

.is-with {
  opacity: 0.4;
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.tags-container {
  display: flex;
  align-items: center;
}

.tagged-user {
  padding: 0;
  margin: 0;
  margin-left: 5px;
}
