.nft-requests-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
}

.pending-requests-container {
  background: white;
  border-radius: 10px;
  margin: 20px 0;
}

.from-container {
  display: flex;
  align-items: center;
  gap: 10%;
}

.from-text {
  opacity: 0.4 !important;
}

.nft-fee-container,
.description-container {
  display: flex;
  align-items: center;
  gap: 10%;
}

.actions-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.requested-nfts-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.requested-nfts-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 67%;

  transform: translateY(-70%);
  z-index: 3;
}

.requested-nfts-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 15px;

  margin-top: 30px;
  row-gap: 30px;
}

.upload-requested-nft-container {
  padding-right: 2rem;
  margin-top: 2rem;
  margin-left: 1rem;
  min-height: 90vh;
}

.selected-container {
  width: 100%;
  margin-top: 1rem;
}

.sections {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  margin-left: 30px;
}

.section {
  margin: 0;
  padding: 0;
  color: #000000;
  opacity: 0.4;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #f900b7 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #f900b7;
  border-radius: 222px 222px 0px 0px;
}

.nft-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.no-results {
  margin-top: 10px;
  opacity: 1;
}

.requested-nfts-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  background: #fafafa;
}
.requested-nfts-header {
  border-bottom: 1px solid #e9ebeb;
}

.requested-nfts-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}
.requested-nfts-header,
.requested-nfts-options-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.requested-nfts-options-container {
  gap: 20px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

.requested-nfts-option-text {
  color: #677074;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.requested-nfts-option-text-selected {
  color: #263238;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .nft-requests-container {
    display: flex;
    flex-direction: column;
  }
}
