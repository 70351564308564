.delivery-speed-container,
.summary-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
  -ms-transform: scale(1.35);
  -webkit-transform: scale(1.35);
  transform: scale(1.35);
  margin-top: 5px;
}

.delivery-option-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.delivery-option-text-container p {
  margin: 0;
}

.free-text,
.fee-text {
  color: #f900b7;
  font-weight: 500;
}

.standard-delivery-text,
.hr-text {
  font-weight: 500;
  color: black;
}

.may-take-text,
.get-in-a-day-text {
  color: #a8adaf;
  font-size: 12px;
}

.fee-container {
  display: flex;
  align-items: center;
  gap: 20%;
}

.fee-description {
  width: 150px;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  width: 100%;
}

.total-container {
  display: flex;
  align-items: center;
  gap: 20%;
  margin-top: 20px;
}

.total-description,
.total-price {
  width: 170px;
  color: black;
  font-weight: 500;
  margin-right: -20px;
}

.actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.summary-text {
  margin-top: 20px;
}
