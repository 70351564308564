.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.service-text {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
  font-size: 22px;
}

.service-icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.service-icon {
  width: 30px;
  height: 30px;
}
.request-user-service-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.price {
  font-size: 40px;
  color: #263238;
  margin: 0;
  font-weight: 500;
  margin-top: 20px;
}

.price-text {
  color: #677074;
  font-size: 16px;
  margin: 0;
}

.fetching-best-price-text {
  color: #677074;
  font-size: 16px;
  margin: 40px 0;
}

.note-container {
  align-self: flex-start;
  margin-top: 20px;
}

.send-note-text {
  color: #677074;
  margin: 0;
  text-align: left;
}

.textarea {
  background: #f4f5f5;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
