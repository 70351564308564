.card-container {
  border-radius: 10px;
  position: relative;
  background: white;
  height: 200px;
  width: 146px;
  margin: 0 0.4rem;
}

.nft-img {
  height: 200px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.owner-container {
  position: relative;
  bottom: 58px;
  display: flex;
  gap: 10px;
  left: 0px;
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  border-radius: 10px;
  padding: 10px;
  padding-left: 0.6rem;
}

.owner-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3px;
  object-fit: cover;
}

.owner-firstName {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.buy-price {
  margin: 0;
  padding: 0;
  font-size: 12px;

  font-weight: 600;
  color: #ffffff;
  opacity: 0.7;
}
