.image {
  width: 270px;
  height: 270px;
  object-fit: cover;
  border-radius: 16px;
}

.upload-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
