.introduction-page-container {
  max-height: 180px;
  overflow-y: auto;
  padding-right: 3px;
}

.introduction-page-container::-webkit-scrollbar {
  width: 5px;
}

.introduction-page-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.introduction-page-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.introduction-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.introduction-text {
  font-size: 16px;
  color: black;
}

.introduction-text,
.edit,
.introduction {
  padding: 0;
  margin: 0;
}

.edit {
  cursor: pointer;
  text-decoration-line: underline;
  color: #f900b7;
}

.introduction {
  color: #000000;
  opacity: 0.7;
  margin-top: 10px;

  overflow-wrap: break-word;
  white-space: pre-line;
}

.no-introduction {
  opacity: 0.7;
  color: #000000;
  margin-top: 10px;
}
