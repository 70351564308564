.channel-name {
  color: #677074;
  padding: 0;
  margin: 0;
}

.channels-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-right: 1.5px solid rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.channels {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: scroll;
}

.channels::-webkit-scrollbar {
  width: 6px;
}

.channels::-webkit-scrollbar-track {
  background: #d4d6d7;
  border-radius: 4px;
}

.channels::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.channel-name {
  cursor: pointer;
  padding: 0.75rem 1rem;
}

.selected-channel {
  color: #677074;
  font-weight: 500;
  padding: 0;
  margin: 0;
  position: relative;
  background: #f4f5f5;
  border: 1px solid #e9ebeb;
  padding: 0.75rem 1rem;
}

/* .selected-channel::before {
  content: '';
  position: absolute;
  top: 0px;
  left: -2rem;

  background: #677074;
  width: 20px;
  height: 20px;
  border-radius: 0px 16px 16px 0px;
} */

.add-container {
  cursor: pointer;
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  display: inline-block;
  border-radius: 100px;
  width: 35px;
  height: 35px;
  position: relative;
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-text {
  color: #f900b7;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}

.channels-add {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

.create-channel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 124, 220, 0.2) 0%,
    rgba(249, 0, 183, 0.2) 100%
  );
  border-radius: 16px;
  padding: 0.5rem 2rem;
}

.create-channel-button p {
  color: #f900b7;
  margin: 0;
  font-weight: 500;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  width: 100%;
}

.group-channels-button-container {
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  gap: 60px;
}

.left-sidebar-container {
  border-right: 1.5px solid rgba(0, 0, 0, 0.1);
}

.group-channels-text {
  margin: 0;
  color: black;
  font-size: 400;
}

.private-channel-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.private-icon {
  width: 14px;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  margin-left: 20px;
}

.plus-container {
  background: linear-gradient(
    180deg,
    rgba(0, 177, 255, 0.2) 0%,
    rgba(194, 94, 255, 0.2) 100%
  );
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  z-index: 2;
}
