.community-text {
  color: #677074;
  margin: 0;
  margin-top: 5px;
}
.group-container {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photo-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cover-photo {
  border: 1px solid #e9ebeb;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.group-name {
  color: #263238;
  margin: 0;
}

.group-list-container {
  height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.group-list-container::-webkit-scrollbar {
  width: 6px;
}

.group-list-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
