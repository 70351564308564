.items-activity-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  gap: 20px;
  margin-top: 20px;
}

.selected-section {
  font-weight: 500;
  color: #263238 !important;
}

.selected-border {
  height: 3px;
  background: #263238;
  border-radius: 222px 222px 0px 0px;
}

.items-text,
.activity-text {
  margin: 0;
  color: rgba(0, 0, 0, 0.4);
}
