.footer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.group-details-arrow,
.group-members-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-details-text,
.group-name-text {
  color: #263238;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.group-name-text {
  font-size: 12px;
  opacity: 0.4;
  font-weight: 500;
  margin-bottom: 5px;
}
.group-members-text {
  color: #263238;
  font-weight: 500;
}

.images {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
}

.user-username {
  color: #a8adaf;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.fullName-username-container {
}

.others-text {
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  color: #f900b7;
  margin-left: 15px;
}

.group-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin: 20px 0;
}

.group-members {
  max-height: 180px;
  overflow-y: auto;
}

.member {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.group-members::-webkit-scrollbar {
  width: 7px;
}

.group-members::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-members::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  width: 40px;
  height: 40px;
  z-index: 100;
}

.user-fullName {
  font-weight: 500;
  padding: 0;
  margin: 0;
}
