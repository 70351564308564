.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.popular-benefits-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.benefit-container {
  background: #f4f5f5;
  text-align: center;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selected-benefit-container {
  background: #f4f5f5;
  text-align: center;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: 1px solid #ef00d7;
}
.selected-benefit-container p {
  color: #ef00d7 !important;
  margin: 0;
}

.benefit-container p {
  color: #a8adaf;
  margin: 0;
}

.benefits-container {
  height: 300px;
  overflow-y: auto;
  padding-right: 6px;
}

.benefits-container::-webkit-scrollbar {
  width: 7px;
}

.benefits-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.benefits-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
