.selected-chat-container {
  background: white;
  padding: 1rem;
  /* border-bottom-right-radius: 10px; */
  border-left: 1px solid #e9ebeb;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
}

.options {
  display: flex;
  align-items: center;
  gap: 15px;

  position: relative;
}

.options img {
  cursor: pointer;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}

.other-user-fullName {
  font-weight: 500;
  color: black;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.user-information {
  display: flex;
  align-items: center;
  gap: 15px;
}

.offline {
  color: #8d8d8d;
  font-size: 14px;

  padding: 0;
  margin: 0;
  margin-top: 2px;
}

.online {
  color: #00b432;
  font-size: 14px;
  padding: 0;
  margin: 0;
  margin-top: 2px;
}

.online img {
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: -2px;
}

.offline-icon {
  margin-right: 3px;
  margin-left: -2px;
}

.horizontal-line {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  margin-top: 20px;

  filter: drop-shadow(0px 0.2px 0.5px rgba(0, 0, 0, 0.2));
}

.chat-options {
  position: absolute;
  top: 30px;
  right: 0px;

  background: white;
  border-radius: 6px;
  padding: 1rem;
  width: 200px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
  filter: drop-shadow(0px 0.2px 5px rgba(0, 0, 0, 0.1));
}

.view-profile,
.option {
  color: black;
  opacity: 1;
  width: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.delete {
  color: #ff5252;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.profile-image {
  object-fit: cover;
  border-radius: 50%;
  border: 0.5px solid #eeeeee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
}

.profile-image:not(:last-child) {
  margin-left: -10px;
}

.profile-images-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.more-icon {
  width: 8px;
}

@media only screen and (max-width: 1400px) {
  .chat-options {
    right: 0px;
  }
}
