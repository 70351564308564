.image {
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.album-photos-container {
  padding: 1rem;
}

.more-icon {
  position: absolute;
  top: 0px;
  right: -30%;
  z-index: 100;
  cursor: pointer;
}

.options {
  background-color: white;
  color: black;
  height: 40px;
  width: 100px;
  padding: 0.4rem;
  position: relative;
  top: 0px;
  right: -10%;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.delete-text {
  color: black;
  font-weight: bold;
  font-size: 14px;
}
