.select-container {
  background: #f8f8f8;
  border-radius: 6px;
  position: relative;
  padding: 0.5rem;
}

.selected-value-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.selected-value {
  padding: 0;
  margin: 0;
}

.options-container {
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 0;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 200px;
}

.options-container::-webkit-scrollbar {
  width: 7px;
}

.options-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.options-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.option {
  cursor: pointer;
  padding: 0.5rem;
  margin: 0;
}
.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}
