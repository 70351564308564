.user-recent-nfts-container {
  background: white;
  border-radius: 10px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 20px 0;
  border: 1px solid #e9ebeb;
}

.see-all-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.see-all-container p {
  text-decoration-line: underline;
  color: #a8adaf;
  margin: 0;
}

.user-recent-nfts-text {
  color: #263238;
  font-size: 16px;
  margin: 0;
}

.user-recent-nfts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}
.recent-sale {
  position: relative;
}

.nft-image {
  width: 100%;
  height: 100%;
  max-width: 183px;
  max-height: 183px;
  min-height: 175px;
  object-fit: cover;
}

.lastBuyNowPrice {
  color: #263238;
  font-weight: 500;
  margin-top: 0.5rem;
}

.owner-photo {
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
}

.to-fullName {
  color: #263238;

  padding: 0;
  margin: 0;
}

.date {
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.buyer-container,
.buyer-container:hover {
  display: flex;
  gap: 6px;
  align-items: center;
  color: #263238;
}
.recent-activity {
  margin-top: 15px;

  height: 150px;
  overflow-y: auto;
}

.recent-activity::-webkit-scrollbar {
  width: 7px;
}

.recent-activity::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.recent-activity::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.recent-activity-content,
.recent-activity-content:hover {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #263238;
  margin-bottom: 15px;
}

.recent-activity-content p {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.no-recent-sale-text,
.no-recent-activity-text {
  grid-column: 1/-1;
  color: #263238;
  display: inline-block;
  opacity: 0.4;
}

.recent-activity-text {
  font-weight: 400;
  color: #263238;
  margin-bottom: 10px;
}

.buy-now-price {
  color: #263238;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 400;
}

.buy-now-price-container {
  background: #263238;
  opacity: 0.8;
  border-radius: 14px;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 6px 10px;
}

.not-listed-text {
  margin: 0;
  padding: 0;
  color: black;
  font-weight: 500;
  margin-top: 5px;
}

.no-recent-nfts-text {
  opacity: 0.7;
  grid-column: 1/-1;
}

.recent-nfts-view-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.view-all {
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  color: #f900b7;
  cursor: pointer;
}

.check-nfts-button {
  background: linear-gradient(
    180deg,
    rgba(255, 124, 220, 0.2) 0%,
    rgba(249, 0, 183, 0.2) 100%
  );
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 2rem;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
}

.check-nfts-text,
.check-nfts-text:hover {
  color: #f900b7;
  margin: 0;
}
