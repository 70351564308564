.search-sidebar-container {
  padding: 1rem 0rem;
}

.results-container {
  overflow-y: auto;
  height: 80vh;
  margin-top: 10px;
}

.user-container,
.nft-container,
.group-container,
.nft-collection-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.3rem 0.2rem;
}

.user-image,
.nft-image,
.group-image,
.nft-collection-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.fullName {
  color: #263238;
  padding: 0;
  margin: 0;
}

.username,
.group-organizer {
  color: #677074;
  padding: 0;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.results-container::-webkit-scrollbar {
  width: 6px;
}

.results-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.results-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-name,
.nft-name,
.nft-collection-name {
  color: #263238;
  margin: 0;
  padding: 0;
}

.verified-icon {
  width: 14px;
  margin-left: 2px;
}
.horizontal-text {
  text-align: center;
  color: #a8adaf;
  font-size: 12px;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #a8adaf;
}
