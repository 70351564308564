.transactions-container {
  background: white;
  border-radius: 10px;
  margin-top: 10px;
  padding: 1rem;
}

.transactions-text {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.transactions-table {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.transactions-table p {
  color: black;
  font-weight: 500;

  padding: 0;
  margin: 0;
}
.table-horizontal-line {
  grid-column: 1/-1;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: 10px;

  margin-bottom: 15px;
}

.transaction {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin-bottom: 15px;

  align-items: center;
}

.transaction-action,
.nft-title {
  color: #000000;
  opacity: 0.4;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.nft-image {
  object-fit: cover;
  border-radius: 50%;
}

.green-price {
  color: #00b432 !important;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.red-price {
  color: #ff5252 !important;
  font-weight: 400;
}

.transaction-date {
  color: #000000;
  opacity: 0.4;
  font-weight: 400;

  padding: 0;
  margin: 0;
}
