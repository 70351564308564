.nft-collections-page-container {
  min-height: 100vh;
  padding-right: 1rem;
}

.user-sidebar-container {
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
}

.collections-container {
  padding-top: 2rem;
}

.create-collection {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.6rem;
  background: white;
  border-radius: 10px;
  width: 200px;
  cursor: pointer;
}

.search-create-collection-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-container {
  cursor: pointer;
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  display: inline-block;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  position: relative;
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-collection-text {
  color: #f900b7;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.nft-collections-list {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  margin-top: 15px;
  border-radius: 12px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.create-monetize-reward-container {
  margin-bottom: 20px;
}

.collection-airdrop-filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 40px;
  padding: 0.75rem;
  border: 1px solid #e9ebeb;
}

.collection-filter-container,
.airdrop-filter-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.collection-filter-container p,
.airdrop-filter-container p {
  padding: 0;
  margin: 0;
  color: #000000;
  font-weight: 500;
  opacity: 0.4;
  cursor: pointer;
}
.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
}

.featured-all-filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 40px;
  padding: 0.75rem;
  border: 1px solid #e9ebeb;
}

.featured-filter-container,
.all-filter-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.featured-filter-container p,
.all-filter-container p {
  padding: 0;
  margin: 0;
  color: #000000;
  font-weight: 500;
  opacity: 0.4;
  cursor: pointer;
}

.search-input {
  background: #f4f5f5;
  border-radius: 121px;
  padding: 0.5rem 1rem;
  border: none;
  border: 1px solid transparent;
  flex: 1;
}

.search-input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.nft-collection:hover {
  background: #e9ebeb;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .nft-collections-page-container {
    grid-template-columns: 1fr;
  }
  .nft-collections-list {
    justify-items: center;
    align-content: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .search-create-collection-container {
    flex-direction: column;
  }

  .user-sidebar-container {
    width: 100vw;
    padding: 0;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .collections-container {
    margin-left: 6%;
    margin-right: 6%;
  }
}
