.nft-container {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  max-width: 330px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #e9ebeb;
}

.owner-text {
  color: #999999;
  font-size: 12px;
}

.owner-fullName {
  color: black;
  font-weight: 500;
}

.owner-text,
.owner-fullName {
  padding: 0;
  margin: 0;
}

.owner-photo {
  border-radius: 50%;
  border: 0.5px solid #ffffff;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.owner-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nr-followers-text,
.nr-following-text {
  color: black;
  opacity: 0.4;

  font-size: 12px;

  padding: 0;
  margin: 0;
}

.nft-image {
  width: 330px;
  height: 360px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
}

.nft-information {
  padding: 0.5rem;
}

.title {
  color: black;
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0;
  padding: 0;
}

.title-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.price {
  color: #00b432;
  font-weight: 500;

  margin: 0;
  padding: 0;
}
.not-listed {
  color: black;
  opacity: 0.4;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.tags {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tag {
  color: black;
  opacity: 0.4;

  font-size: 12px;

  margin: 0;
  padding: 0;
}

.tags-usd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.usd-price {
  color: black;
  opacity: 0.7;

  font-size: 12px;
  font-weight: 400;

  margin: 0;
  padding: 0;
}

.description {
  height: 35px;
  overflow-y: auto;

  color: black;
  opacity: 0.7;

  font-size: 12px;

  margin-top: 10px;
  width: 100%;
  display: inline-block;
  overflow-wrap: break-word;
}

.description::-webkit-scrollbar {
  width: 4px;
}

.description::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.description::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.likes-watchlist-container {
  margin-left: 10px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.likes-container,
.watchlist-container {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 42px;
  cursor: pointer;
}

.watchlist-container {
  position: relative;
  cursor: pointer;
  gap: 8px;
  align-items: center;
}
.share-text {
  font-size: 12px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.share-icons-container {
  position: absolute;
  bottom: 0;
  right: 32%;
  padding: 1rem;
  background: white;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 100;
}
.share-icon {
  cursor: pointer;
}

.number-likes-text {
  padding: 0;
  margin: 0;
  color: white;
}

.number-watchlist-text {
  padding: 0;
  margin: 0;
  color: white;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -5px;
}

.nft-information-container {
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 5px;
}

.nft-title {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.nft-owner-container {
  display: flex;
  align-items: center;
  gap: 7px;
}

.owner-fullName {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
}

.price-container {
  background: rgba(38, 50, 56, 0.8);
  border-radius: 42px;
  width: 80px;
  height: 43px;
  position: relative;
  margin-left: auto;
}

.usd-price {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.watchlist-share-visit-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.watchlist-share-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
