.user-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 0.5rem;
}
.user-container:not(:last-child) {
  margin-bottom: 1rem;
}
.user-container:hover {
  background-color: #253042;
}

.user-image {
  height: 45px;
  width: 45px;
  border-radius: 100px;
}

.user-text {
  margin-right: auto;
  color: white;
  margin-left: 20px;
}
.show-to-selected-followers-text,
.select-followers-text {
  padding: 0;
  margin: 0;
}

.select-followers-text {
  font-weight: 500;
  color: black;
}

.show-to-selected-followers-text {
  opacity: 0.7;
}

.input-search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-left: -35px;
  cursor: pointer;
  width: 18px;
}

.followers-container {
  height: 240px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.followers-container::-webkit-scrollbar {
  width: 7px;
}

.followers-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.followers-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.follower-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.follower-photo {
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  filter: drop-shadow(0px 1.5px 3px rgba(0, 0, 0, 0.05));
}

.add-container {
  /* border-radius: 50%; */
  /* padding: 0.1rem; */
  margin-left: auto;
  /* position: relative; */
  margin-right: 5px;
  /* cursor: pointer; */
}

.follower-fullName {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.groups-list {
  overflow-y: auto;
  max-height: 300px;
}

.no-groups-found {
  margin: 0;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.cover-photo {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.group-name {
  color: black;
  opacity: 1;
  font-weight: 500;
  margin: 0;
}

.group {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0.1rem 0.6rem;
  margin-left: -0.6rem;
  transition: all 0.2s ease;
}

.selected-group {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 8px 0px 0px 8px;
  margin-left: 0rem;
}

.group-list {
  height: 350px;
  overflow-y: auto;
}

.group-list::-webkit-scrollbar {
  width: 7px;
}

.group-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-list > * {
  margin-top: 15px;
}

.no-groups-found {
  opacity: 1;
  margin-top: 20px;
}

.thin-text {
  color: black;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.associated-groups-text {
  font-size: 16px;
  margin-top: 10px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}
