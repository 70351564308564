.nft-offer-container {
  border-radius: 10px;
  background: #ffffff;
  max-width: 330px;
  overflow: hidden;
  border: 1px solid #e9ebeb;
}

.nft-image {
  width: 330px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.buyer-info-container {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 10px 0;
}

.buyer-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
}

.offer-info-container {
  padding: 0.5rem;
}
.buyer-fullName {
  margin: 0;
  opacity: 1;
  color: black;
}

.title-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nft-title {
  color: black;
  font-weight: 500;
  margin: 0;
}

.price {
  margin: 0;
  color: #00b432;
  font-weight: 500;
}

.received-on,
.usd-price {
  color: #999999;
  font-size: 12px;
  margin: 0;
}

.received-usd-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-text {
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
  margin-top: 10px;
  height: 50px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.comment-text::-webkit-scrollbar {
  width: 7px;
}

.comment-text::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.comment-text::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.actions-container {
  display: flex;
  align-items: center;
  gap: 20px;
}
