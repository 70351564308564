.header {
  position: absolute;
  top: 15px;
  left: 120px;
  z-index: 2;
}
.actions-container {
  position: absolute;
  top: 0;
  right: 50px;
  top: 15px;
}

.feature-point {
  color: #ef00d7;
  margin: 0;
  text-align: center;
}

.landing-first-section-image {
  width: 100%;
  height: 95vh;
}

.glimpse-logo-icon {
  width: 80px;
}

.create-share-sell-container {
  position: absolute;
  top: 70px;
  left: 120px;
}

.create-share-sell-text {
  font-weight: 700;
  font-size: 80px;
  width: 40%;
  color: #ffffff;
  margin: 0;
  line-height: 120%;
}

.create-nfts-text {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  width: 35%;
  font-size: 16px;
}

.request-beta-access-text {
  font-weight: 500;

  color: #ffffff;
  margin-top: 25px;
  font-size: 30px;
}

.email-container {
  background: rgba(255, 255, 255, 0.2);
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  padding: 0.75rem;
  width: 60%;
  color: white;
}

.email-container:focus {
  border: 1.5px solid white;
  outline: none;
}

.email-container::placeholder {
  color: white;
  opacity: 0.7;
}

.mobile-app-image {
  position: absolute;
  top: 50px;
  right: 170px;
  height: 720px;
}

.third-section-image {
  width: 100%;
}
.third-section {
  position: relative;
}

.make-money-text {
  position: absolute;
  top: 10%;
  left: 17%;
  font-weight: 700;
  font-size: 25px;
  width: 70%;
  text-align: center;
}

.apps-description {
  position: absolute;
  top: 20%;
  left: 30%;
  width: 45%;
  font-size: 16px;
  text-align: center;
}

.money-credit-card-image {
  height: 500px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.apply-as-creator-container {
  width: 90vw;
  margin: 0 auto;
  position: relative;
}

.creator-landing {
  width: 100%;
}

.content-creator-image {
  position: absolute;
  top: 0%;
  left: 5%;
  width: 50%;
}

.apply-text-container {
  position: absolute;
  left: 55%;
  top: 40%;
}

.apply-as-creator-text {
  font-weight: 700;
  font-size: 40px;
  text-transform: capitalize;
  background: linear-gradient(
    96.77deg,
    #ff63ef -10.47%,
    #ff62ef -10.45%,
    #ef00d7 127.35%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 70%;
}

.join-text {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  width: 80%;
  opacity: 0.7;
}

.creator-application-container {
  background: linear-gradient(180deg, #ff7cdc 0%, #f900b7 100%);
  box-shadow: 0px 14px 30px rgba(249, 0, 183, 0.25);
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 230px;
  gap: 10px;
  text-align: center;
  justify-content: center;
}

.creator-application-text {
  color: white;
  margin: 0;
}

.footer {
  position: relative;
}

.footer-image {
  height: 50vh;
  width: 100%;
  margin-top: 10%;
}

.glimpse-logo-footer {
  position: absolute;
  top: 45%;
  left: 10%;
  object-fit: cover;
  height: 80px;
}

.socials-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.glimpse-description-container {
  position: absolute;
  top: 65%;
  left: 10%;
}

.glimpse-description {
  color: white;
  margin: 0;
  width: 50%;
}

.join-the-community-container {
  position: absolute;
  top: 45%;
  right: 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join-the-community-text {
  color: white;
  font-weight: 500;
  font-size: 30px;
  margin: 0;
}

.all-rights-reserved {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.second-section {
  width: 90%;
  margin: 10% auto 5% auto;
  position: relative;
  padding-top: 12rem;
  background: #ffffff;
  box-shadow: 0px 14px 200px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.social-media-image {
  width: 100%;
  height: 35vh;
}

.social-media-text {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 40px;
  background: linear-gradient(
    96.77deg,
    #ff63ef -10.47%,
    #ff62ef -10.45%,
    #ef00d7 127.35%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.social-media-description {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);

  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000000;
  width: 50%;
  opacity: 0.7;
}

.question-container {
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 0 20%;
}
.feature-container p {
  margin: 0;
  font-weight: 500;
  color: white;
}

.features-grid {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 100%;
}

.success-request {
  color: white;
}

.error-request {
  color: #ff5252;
}

.success-request,
.error-request {
  font-weight: 500;
  margin-top: 10px;
  font-size: 16px;
}

.terms-of-use-privacy-policy-container {
  display: flex;
  align-items: center;
  gap: 10%;
}

.terms-of-use-text,
.terms-of-use-text:hover,
.privacy-policy-text,
.privacy-policy-text:hover {
  color: white;
  margin: 0;
}

@media only screen and (max-width: 1440px) {
  .money-credit-card-image {
    bottom: -150px;
  }

  .mobile-app-image {
    right: 120px;
    top: 100px;
  }
}

@media only screen and (max-width: 1350px) {
  .money-credit-card-image {
    bottom: -100px;
    height: 400px;
  }
}

@media only screen and (max-width: 1260px) {
  .mobile-app-image {
    right: 30px;
  }

  .apply-as-creator-text {
    font-size: 30px;
    width: 100%;
  }

  .feature-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0 20%;
  }
}

@media only screen and (max-width: 1150px) {
  .mobile-app-image {
    right: 0px;
  }

  .social-media-text {
    width: 100%;
    text-align: center;
  }

  .money-credit-card-image {
    height: 350px;
  }
}

@media only screen and (max-width: 1100px) {
  .create-share-sell-text {
    width: 80%;
  }

  .mobile-app-image {
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translateX(-52%);
    height: 720px;
  }

  .second-section {
    margin-top: 400px;
    margin-left: 5%;
  }

  .social-media-description {
    width: 70%;
  }

  .feature-container {
    margin: 0 8%;
  }

  .apps-description {
    position: absolute;
    top: 24%;
    left: 20%;
    width: 65%;
    font-size: 16px;
    text-align: center;
  }

  .create-nfts-text {
    width: 80%;
    margin-top: 20px;
  }

  .request-beta-access-text {
    margin-top: 50px;
  }

  .third-section {
    margin-top: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .make-money-text {
    font-size: 22px;
  }

  .apps-description {
    font-size: 14px;
  }

  .apply-text-container {
    top: 15%;
    left: 50%;
  }
  .join-text {
    width: 100%;
  }
  .content-creator-image {
    width: 350px;
  }

  .create-share-sell-text {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .money-credit-card-image {
    height: 300px;
  }

  .request-beta-access-text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 890px) {
  .create-nfts-text {
    width: 85%;
  }
  .create-share-sell-text {
    width: 90%;
  }

  .mobile-app-image {
    top: 600px;
  }

  .social-media-text {
    font-size: 30px;
  }

  .make-money-text {
    font-size: 20px;
  }

  .glimpse-description {
    width: 67%;
  }
  .join-the-community-container {
    right: 6%;
  }

  .glimpse-logo-footer {
    height: 65px;
  }

  .third-section {
    margin-top: 475px;
  }
}

@media only screen and (max-width: 840px) {
  .apply-text-container {
    position: relative;
    top: 0;
    left: 0;
  }
  .content-creator-image {
    left: 25%;
  }
  .creator-landing {
    height: 400px;
  }

  .join-the-community-container {
    position: absolute;
    left: 30%;
    top: 50%;
  }

  .glimpse-logo-footer {
    top: 25%;
  }
  .glimpse-description-container {
    top: 25%;
    left: 35%;
  }
  .glimpse-description {
    width: 90%;
  }

  .apps-description {
    width: 73%;
    left: 17%;
  }
  .money-credit-card-image {
    height: 280px;
  }

  .email-container {
    width: 80%;
  }
}

@media only screen and (max-width: 770px) {
  .make-money-text {
    font-size: 18px;
  }

  .third-section-image {
    height: 600px;
  }

  .money-credit-card-image {
    height: 320px;
  }
}

@media only screen and (max-width: 700px) {
  .social-media-image {
    height: 45vh;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .glimpse-logo-footer {
    height: 55px;
  }
}

@media only screen and (max-width: 600px) {
  .third-section-image {
    height: 700px;
  }
  .make-money-text {
    font-size: 16px;
  }

  .apps-description {
    width: 90%;
    left: 5%;
  }

  .money-credit-card-image {
    height: 340px;
  }
}

@media only screen and (max-width: 550px) {
  .glimpse-description-container {
    top: 40%;
    left: 10%;
  }
  .join-the-community-container {
    top: 60%;
    left: 10%;
  }

  .header,
  .create-share-sell-container {
    left: 60px;
  }

  .social-media-description {
    width: 95%;
  }

  .second-section {
    padding-top: 14rem;
  }
}

@media only screen and (max-width: 485px) {
  .content-creator-image {
    left: 15%;
  }
  .request-beta-access-text {
    margin-top: 20px;
  }

  .social-media-text {
    font-size: 26px;
  }
  .social-media-description {
    font-size: 16px;
  }

  .create-share-sell-text {
    font-size: 60px;
    line-height: 150%;
    width: 80%;
  }
}

@media only screen and (max-width: 445px) {
  .content-creator-image {
    left: 5%;
  }

  .email-container {
    width: 94%;
  }

  .apps-description {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 410px) {
  .actions-container {
    right: 0px;
  }

  .content-creator-image {
    left: 5%;
  }

  .request-beta-access-text {
    margin-top: 60px;
  }

  .third-section {
    margin-top: 550px;
  }

  .email-container {
    width: 94%;
  }

  .mobile-app-image {
    width: 100%;
  }
}

@media only screen and (max-width: 410px) {
  .content-creator-image {
    left: -5%;
  }
}

@media only screen and (max-width: 380px) {
  .actions-container {
    right: 0px;
  }

  .apps-description {
    margin-top: 30px;
  }

  .money-credit-card-image {
    height: 280px;
  }

  .third-section {
    margin-top: 620px;
  }
}

@media only screen and (max-width: 373px) {
  .join-the-community-text {
    font-size: 20px;
  }

  .all-rights-reserved {
    font-size: 14px;
  }
}
