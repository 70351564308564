.request-nft-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
}

.request-container {
  margin-top: 1rem;
  margin-right: 1rem;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.request-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.request-nft-header {
  border-bottom: 1px solid #e9ebeb;
}

.go-back-container h3 {
  margin: 0;
  padding: 0;
  color: #263238;
}

.go-back-icon {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .request-nft-page-container {
    display: flex;
    flex-direction: column;
  }
}
