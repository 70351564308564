.referrals-progress-container {
  background: white;
  border-radius: 10px;
  width: 98%;
  margin: 1rem 0;
  border: 1px solid #e9ebeb;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.direct-referrals-list {
  height: 400px;
  overflow-y: auto;
  padding: 1rem;
}

.direct-referrals-list::-webkit-scrollbar {
  width: 7px;
}

.direct-referrals-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.direct-referrals-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.referral-container {
  margin-bottom: 15px;
}

.user-image {
  cursor: pointer;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border: 1px solid #eeeeee;
  border-radius: 50%;
}

.user-information-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.referral-fullName {
  cursor: pointer;
  color: #263238;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.hint-container {
  position: absolute;
  top: 200px;
  left: 50%;
}

.hint-label {
  color: black;
  opacity: 0.7;
  font-weight: 500;
  padding: 0;
}

.progress-container {
  align-self: flex-start;
}

.legend-box-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  text-align: right;
  margin-left: 50%;
  margin-right: 5px;
}

.legend-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.legend-text {
  margin: 0;
  color: #263238;
  font-weight: 500;
}

.white-legend-color {
  background: #f8f8f8;
  width: 40px;
  height: 20px;
}

.pink-legend-color {
  background: #f900b7;
  width: 40px;
  height: 20px;
}

.legend-text-percentage-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100px;
}

.percentage-text {
  margin: 0;
  color: #263238;
  font-weight: 500;
}

.no-referrals-icon {
  width: 300px;
  height: 100%;
}
.no-referrals-container {
  padding: 1.5rem;
}

.no-referrals-found-text {
  text-align: center;
  margin: 10px 0;
}

@media only screen and (max-width: 650px) {
  .referrals-progress-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
