.create-group-page {
  display: grid;
  grid-template-columns: 300px 1fr;
  background: #f8f8f8;
  column-gap: 0;
  min-height: 100vh;
}

.create-group-page-container {
  display: flex;
}

.invite-users-container {
  flex: 1;
  align-self: stretch;
  background: #ffffff;
  padding: 1rem;
  border-left: 1px solid #e9ebeb;
}

.invite-group-members-text {
  color: #263238;
}

.create-group-text-container {
  background: white;
  margin: 0;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #e9ebeb;
  display: flex;
  align-items: center;
  gap: 15px;
}
.go-back-icon {
  margin-left: 5px;
  width: 15px;
  cursor: pointer;
}

.create-group-text-container h3 {
  margin: 0;
  padding: 0;
}

.create-group-form-invite-users-container {
  margin: 3rem;
  border: 1px solid #e9ebeb;
}

.actions-container {
  text-align: right;
  margin: 0rem 5rem;
}

@media only screen and (max-width: 1400px) {
  .create-group-form-invite-users-container {
    margin: 3rem 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .create-group-page-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .create-group-page {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 570px) {
  .actions-container {
    text-align: left;
    flex-direction: row;
    display: flex;
  }
}

@media only screen and (max-width: 500px) {
  .actions-container {
    text-align: left;
    flex-direction: column;
    display: flex;
    gap: 10px;
  }

  .actions-container input {
    width: 100%;
    margin: 0 !important;
  }
}
