.sidebar-container,
.closed-sidebar-search-container {
  background: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  width: 260px;
  border: 1px solid #e9ebeb;
  border-top: 0;
}

.firstname-verified-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.verified-icon {
  width: 15px;
}

.create-collectible-container {
  background: #f4f5f5;
  border-radius: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 3.2rem;
  margin: 15px auto;
  cursor: pointer;
}

.create-collectible-container p {
  padding: 0;
  margin: 0;
  font-weight: 500;
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.plus-icon {
  margin-right: 10px;
  height: 11.5px;
  object-fit: cover;
}

.sidebar {
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* padding: 1rem; */
}

.user-image {
  object-fit: cover;
  width: 45px;
  height: 45px;
  border: 2px solid #ffffff;
  border-radius: 100px;
  filter: drop-shadow(0px 3px 50px rgba(0, 0, 0, 0.2));
  position: relative;
}

.edit-icon-container {
  background: white;
  position: absolute;
  bottom: 0%;
  right: -10%;
  padding: 0.1rem;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
}

.edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-container {
  position: relative;
  display: inline-block;
}

.email,
.username {
  color: black;
  opacity: 0.4;
  margin-left: -10px;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
}

.nav {
  align-self: stretch;
  overflow-y: auto;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 5px;
}

.nav > li {
  list-style: none;
  margin: 0 auto;
  padding: 9.5px 0.75rem;
}

.nav > li:not(:last-child) {
  margin-bottom: 5px;
}

.selected-link {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.selected {
  color: white;
  padding: 0;
  margin: 0;
}

.selected-text {
  color: #263238 !important;
  font-weight: 600;
}

.home-image,
.marketplace-icon {
  margin-bottom: 2px;
}

.home-link,
.marketplace-link,
.cart-link,
.events-link,
.leaderboard-link,
.my-profile-link,
.network-link,
.request-nft-link,
.collaborator-requests-link,
.offers-link,
.become-creator-link,
.feedback-link,
.settings-link,
.help-link,
.logout-link,
.get-started-link {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.home,
.marketplace,
.events,
.cart,
.leaderboard,
.my-profile,
.network,
.request-nft,
.collaborator-requests,
.offers,
.become-creator,
.feedback,
.settings,
.help,
.logout,
.get-started {
  color: #677074;
  padding: 0;
  margin: 0;
}

.my-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.my-profile-section {
  /* background: linear-gradient(180deg, #ff7cdc 0%, #f900b7 100%); */
  /* border-radius: 16px; */
  padding: 0.3rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.user-image-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.hello-text {
  color: #263238;
  margin: 0;
  cursor: pointer;
}

.vertical-line {
  width: 95%;
  opacity: 0.2;
  border-bottom: 1.5px solid #ffffff;
  transform: rotate(90deg);
  color: white;
}

.balance-points-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background: linear-gradient(180deg, #ff7cdc 0%, #f900b7 100%); */
  padding: 1.2rem 0rem;
  /* border-radius: 0px 0px 16px 16px; */
  /* position: relative; */
  border-top: 1px solid #e9ebeb;

  /* margin-top: auto; */
}
.balance-container {
  cursor: pointer;
}

.vertical-line {
  width: 10px;
  background: red;
}

.vertical-line-container {
  position: absolute;
  top: 50%;
  left: 44%;
  opacity: 0.2;
  border: 1px solid #ffffff;
  transform: rotate(90deg);
  width: 100%;
  background: #ffffff;
  width: 60px;
  height: 0;
}
.balance-text {
  color: #a8adaf;
  margin: 0;
}

.balance-icon {
  width: 20px;
}

.glimpse-balance {
  color: #263238;
  font-weight: 500;
}
.wallet-balance,
.points {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  flex: 1;
}

.points-text {
  color: #a8adaf;
  margin: 0;
}

.total-points {
  color: #263238;

  margin: 0;
}

.glimpse-balance,
.total-points {
  font-size: 16px;
  margin: 0;
}

.number-of-unread-notifications-container,
.number-of-unread-messages-container {
  background: #ef00d7;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  margin: 0;
}

.number-of-unread-notifications-text,
.number-of-messages-text {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
}

.notifications-container,
.messages-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.glimpse-logo {
  width: 100px;
  height: 40px;
}

.black-horizontal-line {
  color: #e9ebeb;
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
}
.glimpse-logo-container {
  padding: 0.75rem;
}

.up-down-icon {
  margin-left: auto;
  width: 20px;
  cursor: pointer;
}

.number-of-followers-followings {
  display: flex;
  align-items: center;
  padding: 0.3rem 0.75rem;
  gap: 20px;
}
.number-of-followers,
.number-of-followings {
  margin: 0;
  padding: 0;
}
.number-of-followers-value {
  color: #263238;
  font-weight: 500;
  margin-right: 5px;
}
.number-of-followings-value {
  color: #263238;
  font-weight: 500;
  margin-right: 5px;
}
.followers-text {
  color: #677074;
}
.following-text {
  color: #677074;
}

.closed-sidebar-container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100vh;
  /* position: fixed; */
  width: 80px;
  border: 1px solid #e9ebeb;
  border-top: 0;
}

.glimpse-coin-icon {
  width: 25px;
  margin: 15px 0;
  height: 35px;
}

.closed-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

.closed-create-collectible-container {
  background: #fde5fb;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.closed-plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closed-balance-points-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.closed-sidebar-search-container {
  flex-direction: row;
}

.search-container {
  width: 100%;
}

.points-container {
  cursor: pointer;
}
.search-header,
.groups-text-header {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
  margin: 15px 0;
  cursor: pointer;
}

.go-back-icon {
  height: 12px;
  margin-left: 10px;
}

.search-text,
.groups-text {
  color: #263238;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.groups-content,
.groups-header {
  padding: 0.5rem 0.2rem;
}
.groups-content {
  padding-top: 0;
}

.stripe-account-container {
  padding: 0 10px 10px 10px;
}

.referral-profile-link-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

.referral-link-text,
.profile-link-text {
  color: #a8adaf;
  margin: 0;
  cursor: pointer;
}

.share-link-icon {
  margin-left: 6px;
  height: 12px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .sidebar-container,
  .closed-sidebar-search-container {
    position: relative;
    width: 100%;
  }
}
