.news-feed-container {
  display: grid;
  /* grid-template-columns: 300px calc(100vw - 620px) 300px; */

  grid-template-columns: 190px 1fr;

  background: #f8f8f8;
  column-gap: 0;
  min-height: 100vh;
}

.user-sidebar-container {
  width: 100%;
}

.news-feed-content-container,
.recent-sales-sidebar-container {
  padding-top: 1rem;
}

.messages-sidebar-container {
  width: 100%;
}

.recent-sales-sidebar-container {
  padding: 1rem;
  padding-right: 1rem;
}
.recent-sales-sidebar-container {
  position: relative;
}

@media screen and (max-width: 1150px) {
  .messages-sidebar-container {
    width: 0;
    display: none;
  }

  .news-feed-container {
    grid-template-columns: 300px calc(100vw - 320px);
  }
}

@media screen and (max-width: 900px) {
  .news-feed-container {
    display: flex;
    flex-direction: column;
  }
}
