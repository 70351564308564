.no-results {
  padding: 1rem 0;
  opacity: 0.7;
}

.chat-list {
  height: 560px;
  overflow-y: auto;
}
.group-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 25px;
}

.group-name {
  margin: 0;
  font-weight: 400;
  opacity: 1;
  cursor: pointer;
}

.group-cover-photo {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white;
}

.chat-list::-webkit-scrollbar {
  width: 7px;
}

.chat-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.chat-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.circle {
  position: relative;
  width: 22px;
  height: 22px;
  /* background: rgba(153, 153, 153, 0.05); */
  border-radius: 50%;
  cursor: pointer;
  margin: 0;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}

.arrowDown-icon,
.arrowUp-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrowDown-icon {
  width: 11px;
}

.arrowUp-icon {
  width: 11px;
}

.cover-photo-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.channels-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 1rem;
  margin-left: 45px;
}

.channel-name {
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  margin: 0;
  margin-top: 10px;
  cursor: pointer;
}

.private-icon {
  width: 14px;
  margin: 0;
  padding: 0;
  margin-top: 3px;
}

.private-channel-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selected-channel {
  font-weight: 600;
  color: #263238;
}

.selected-channel-border {
  background: #f900b7;
  border-radius: 0px 16px 16px 0px;
  width: 10px;
  height: 15px;
  margin-top: 7px;
}

.heart-icon {
  cursor: pointer;
}

.unread-messages {
  background: #ef00d7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  margin-left: auto;
  margin-right: 10px;
}

.unread-messages-text {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.expanded-community {
  background: #f4f5f5;
  border-radius: 12px 0px 0px 12px;
  padding: 0.25rem;
  padding-right: 0;
  padding-top: 0;
}

.group-container-hover {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 25px;
  padding: 0.25rem 0.3rem;
}
.group-container-hover:hover {
  background: #f4f5f5;
  border: 1px solid #e9ebeb;
}
