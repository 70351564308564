.requested-service-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  position: relative;
}
.from-photo {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.photo-fullName-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem;
}

.from-fullName {
  color: #263238;
  margin: 0;
  font-weight: 500;
}

.created-at {
  color: #263238;
  margin: 0;
  font-size: 12px;
  opacity: 0.7;
}

.service-container {
  background: #f8f8f9;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  margin: 0.5rem 1rem;
}
.service-text {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}

.price {
  color: #263238;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
}

.actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 1rem;
}

.service-icon {
  width: 20px;
  height: 20px;
}

.service-icon-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.finished-status-container,
.pending-status-container,
.rejected-status-container {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 10;
}

.pending-status-container {
  background: #fff1cc;
}

.finished-status-container {
  background: #ccf0d6;
}

.rejected-status-container {
  background: #ff5252;
  color: white;
}

.pending-icon,
.finished-icon,
.rejected-icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rejected-icon {
  width: 8px;
}

.finished-icon {
  width: 18px;
}
