.what-are-you-thinking-container {
  background: #ffffff;
  border-radius: 16px;
  margin: 20px 0;
  padding: 0.4rem 0.8rem;
  border: 1px solid #e9ebeb;
}

.user-image-what-are-you-thinking {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;
}

.what-are-you-thinking {
  border-radius: 10px;
  padding: 0.8rem;
  cursor: pointer;
  background: #f4f5f5;
  border-radius: 116px;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  color: #a8adaf;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1.5px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.horizontal-line {
  opacity: 0.05;
  border-bottom: 1.5px solid #000000;
  width: 100%;
}

.photo-or-video-container,
.feeling-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.photo-or-video-image {
  width: 18px;
}
.feeling-image {
  width: 18px;
}

.photo-or-video-text,
.feeling-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
  font-weight: 500;
}

.photo-video-feeling-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-left: 5px;
}
