.footer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.group-details-arrow,
.group-members-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-details-text,
.group-name-text {
  color: black;
  padding: 0;
  margin: 0;
}
.group-name-text {
  font-size: 12px;
  opacity: 0.4;
  font-weight: 500;
  margin-bottom: 5px;
}

.images {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
}

.others-text {
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
  color: #f900b7;
  margin-left: 15px;
}

.group-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin: 20px 0;
}

.group-members {
  max-height: 180px;
  overflow-y: auto;
}

.member {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.group-members::-webkit-scrollbar {
  width: 7px;
}

.group-members::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-members::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  width: 40px;
  height: 40px;
  z-index: 100;
}

.user-fullName {
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.member-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  z-index: 11;
  padding: 0.75rem;
  padding-bottom: 0;
  min-width: 200px;
}

.option {
  padding: 0.3rem;
  cursor: pointer;
}
.option:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.more-icon-options-container {
  position: relative;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pink-circle {
  position: relative;
  width: 40px;
  height: 40px;
  background: #f900b7;
  border-radius: 50%;
  cursor: pointer;
}
.add-new-member-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.add-member {
  font-weight: 500;
  color: #f900b7;
  padding: 0;
  margin: 0;
}
/*  */
.input-selected-users-container {
  position: relative;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  padding: 0.3rem 0.8rem;
  height: 50px;
  border-radius: 10px;
}
.to-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  margin-right: 10px;
}

.selected-user-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.selected-user-text {
  font-weight: 400;
  color: #f900b7;
  padding: 0;
  margin: 0;
}

.friends-container {
  background: white;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  min-width: 300px;
  position: absolute;
  top: 51px;
  left: 80px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 1rem 0.4rem;
  max-height: 200px;
  overflow-y: auto;
}

.friends-container::-webkit-scrollbar {
  width: 7px;
}

.friends-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.friends-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.follower-fullName {
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.friend-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 0.3rem;
  transition: all 0.2s ease;
}

.friend-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.user-image {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid white;
  filter: drop-shadow(0px 1.5px 5px rgba(0, 0, 0, 0.2));
}

.no-users-found {
  color: black;
  padding: 0;
  margin: 0;
}

.input-send-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.selected-user-information {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  transform: translateY(-10%);

  height: 80%;
}

.selected-user-photo {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  border-radius: 50%;
}

.selected-user-fullName {
  font-weight: 500;
  color: black;

  padding: 0;
  margin: 0;
}

.start-message {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
}

.selected-users-to-join-group-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.add-member-input-text {
  opacity: 0.4;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-size: 12px;
}

.kick {
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.3rem;
  cursor: pointer;
}
