.most-recent-nfts-container {
  position: relative;
  width: 100%;
}
.create {
  border-radius: 10px;
  position: relative;
  background: white;
  height: 230px;
  width: 134px;
}

.rectangle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-container {
  cursor: pointer;
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  display: inline-block;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-text {
  color: #f900b7;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}

.most-recent-nfts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-icon {
  background: #ffffff;
  border: 2px solid #e9ebeb;
  border-radius: 77px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  width: 30px;
  height: 30px;
  position: absolute;
  right: -3%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.right-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 700px) {
  .most-recent-nfts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}
