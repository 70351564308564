.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.horizontal-line {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

.available-balance-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
}

.glimpse-balance,
.available-balance {
  padding: 0;
  margin: 0;
}

.available-balance {
  color: black;
  font-weight: 400;
}

.glimpse-balance {
  font-weight: 500;
  color: #f900b7;
}

.glimpse-balance-usd {
  color: black;
  opacity: 0.4;
  margin-left: 5px;
  font-size: 12px;
}
