.signup-unstoppable-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 56px;
  padding: 0.6rem 1.7rem;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}
.signup-unstoppable-text {
  color: #a8adaf;
  font-weight: 400;
  opacity: 1;
  margin: 0;
}

.unstoppable-icon {
  width: 25px;
  height: 25px;
}
