.post-container {
  background: white;
  border-radius: 8px;
  margin-bottom: 15px;
  width: calc(100vw - 920px);
  border: 1px solid #e9ebeb;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.post-image-container,
.post-videos-container {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 1000px;
  cursor: pointer;
  margin: 5px 0;
}

.user {
  display: flex;
  align-items: center;
  gap: 15px;
}

.fullName {
  color: black;
  font-weight: 500;
  margin: 0;
}

.created-at {
  color: #8d8d8d;
  font-size: 12px;
  margin: 0;
}

.more {
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.post-content-container {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  color: #263238;
}

.post-content-text {
  color: #263238;
  font-weight: 400;
  overflow-wrap: break-word;
}

.mention-word,
.mention-word:hover {
  font-weight: 500;
  color: #263238;
  cursor: pointer;
}

.post-image {
  width: 100%;
  object-fit: cover;
  margin: 5px 0;

  max-height: 1000px;
  cursor: pointer;
}

.post-video {
  margin-top: 5px;
}

.horizontal-line {
  background: #ffffff;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  width: 100%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

.likes-comments-save {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.likes-comments {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.like-container {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 40px;
  flex: 1;

  justify-content: center;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  padding: 10px;
  cursor: pointer;
  border-right: 1px solid #e9ebeb;
}
.like-container:hover {
  background: #f4f5f5;
}

.like-container img {
  cursor: pointer;
}

.like-container p {
  margin: 0;
  padding: 0;
  color: black;
  opacity: 0.4;

  margin-top: 0.1rem;
}

.comment-container {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  flex: 1;
}

.comment-container p {
  margin: 0;
  padding: 0;
  color: black;
  opacity: 0.4;
}

.tip-container {
  flex: 1;

  border-left: 1px solid #e9ebeb;
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: center;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  padding: 10px;
  cursor: pointer;
}

.tip-icon {
  height: 15px;
}

.tip-text {
  margin: 0;
  color: #263238;
}

.tip-options-container {
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}
.box-option {
  background: #f8f8f9;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.6rem;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  position: relative;
}

.box-option:hover {
  background: #fde5fb;
}
.box-option:hover p {
  color: #ef00d7;
}
.go-back-tip-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 15%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  padding: 10px;
  cursor: pointer;
  border-right: 1px solid #e9ebeb;
}
.go-back-icon {
  height: 12px;
}

.points-value {
  color: #a8adaf;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  display: flex;
  align-items: center;
}
.diamond-icon {
  width: 18px;
  margin-right: 7px;
}

.save {
  display: flex;
  align-items: center;
  gap: 10px;
}

.save-text {
  color: black;
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.post-author-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.is-with {
  opacity: 0.4;
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.tags-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.tagged-user,
.tagged-user:hover {
  padding: 0;
  margin: 0;
  margin-left: 5px;
  color: black;
  opacity: 1;
}

.and {
  opacity: 0.4;
  margin: 0 4px;
}

.fullName-feeling-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feeling-container {
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 5px;
}

.feeling-text {
  padding: 0;
  margin: 0;
  color: #f2994a;
}

.circle {
  background: #f2994a;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 5px;
}

.see-who-have-liked-text {
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-weight: 500;
  opacity: 1;
}

.user-image {
  width: 21px;
  height: 21px;
  object-fit: cover;
  border: 1px solid white;
  border-radius: 50%;
  margin-bottom: 1px;
}

@media only screen and (max-width: 800px) {
  .post-container {
    max-width: 99vw;
    padding: 0.3rem;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1360px) {
  .post-container {
    max-width: 99vw;
    padding: 0.3rem;
    overflow-wrap: break-word;
  }

  .post-container {
    min-width: 100%;
  }
}

.preview-link-image {
  width: 100%;
  max-height: 1000px;
}

.preview-link-container {
  width: 100%;
  border-radius: 16px;
}
.preview-info {
  background: rgba(196, 196, 196, 0.05);
  padding: 1rem 0;
}

.preview-info h3,
.preview-info p {
  margin: 0;
}

.visit-link,
.visit-link:hover {
  border-radius: 6px;
  padding: 0.5rem 45%;
  width: 100%;
  background: #ef00d7;
  border-radius: 17px;
  color: white;
}

.visit-link-container {
  width: 100%;
  text-align: center;
}

.recycle-bin {
  color: #ff5252;
  padding: 0.3rem;
  margin: 0;
}

.option {
  padding: 0.3rem;
  margin: 0;
}

.more-options {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 200px;
  background: white;
  padding: 0.5rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 10;
}

.more {
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: pointer;
}

.two-images-container {
  display: flex;
  align-items: stretch;
  gap: 1px;
}
.two-images-container img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.three-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1px;
}

.three-images-0 {
  grid-column: 1/2;
  grid-row: 1/-1;
  width: 100%;
  height: 600px;
  object-fit: cover;
  cursor: pointer;
}

.three-images-1 {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.three-images-2 {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.four-images-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1px;
}
.four-images-0 {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}
.four-images-1 {
  grid-column: 2/3;
  grid-row: 1/2;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}
.four-images-2 {
  grid-row: 2/3;
  grid-column: 1/2;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}
.four-images-3 {
  grid-row: 2/3;
  grid-column: 2/3;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.more-images-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  cursor: pointer;
}

.more-images-number {
  color: white;
  font-weight: 600;
  font-size: 36px;
  z-index: 10;
}

.empty-space {
  margin: 0;
}
.liked-by-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 0.3rem;
}

.liked-by-user {
  position: relative;
}
.liked-icon {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 2px;
  right: 1px;
}

.more {
  position: relative;
}

.liked-by-user-photo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.remaining-number-of-likes-container {
  background: #f4f5f5;
  position: relative;
  border: 1px solid #e9ebeb;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
.remaining-number-of-likes-container p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #a8adaf;
}

.follow-text {
  color: #ef00d7;
  padding: 0;
  margin: 0;
  font-size: 14px;
  align-self: flex-start;
  margin-right: auto;
  margin-left: 20px;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .liked-by-container {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2800px) {
  .post-container {
    width: 750px;
    margin: 0 auto;
  }
}
