.members-container {
  margin: 1rem 0;
}
.members-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.others-text {
  color: black;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.circle {
  background: rgba(0, 0, 0, 0.4);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.number-of-members {
  opacity: 0.4;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.members-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-container {
  padding: 0;
  margin: 0;
  position: relative;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-77%);
  z-index: 3;
}
