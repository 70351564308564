.search-input {
  background: #f4f5f5;
  border-radius: 121px;
  padding: 0.5rem 1rem;
  border: none;
  border: 1px solid transparent;
  width: 100%;
  margin: 10px 0;
}

.search-input:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.brand-items-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  row-gap: 30px;
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tags-container {
  background: white;
  border-radius: 42px;
  align-items: center;
  padding: 0.75rem;
  width: 200px;
  border: 1px solid #e9ebeb;
  position: relative;
  min-width: 230px;
}

.tag-text {
  color: #999999;
  font-weight: 400;
  padding: 0;
  margin: 0;
  width: 100%;
  padding: 0.2rem;
  cursor: pointer;
}

.tags {
  display: grid;
  grid-template-columns: repeat(6, minmax(min-content, 110px));

  column-gap: 20px;
}

.selected-tag-option-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.category-value {
  padding: 0;
  margin: 0;
  color: #677074;
}
.selected-tag-value {
  color: #263238 !important;
  font-weight: 500 !important;
}

.tag-value {
  padding: 0;
  margin: 0;
  color: #677074;
}
.tags-container {
  position: relative;
  min-width: 230px;
}
.tag-options-container {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
  height: 250px;
  overflow-y: auto;
  z-index: 2;
}

.tag-options-container::-webkit-scrollbar {
  width: 5px;
}

.tag-options-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.tag-options-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.arrows-tags {
  display: flex;
  align-items: center;
  gap: 10px;
}
