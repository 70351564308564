.polls-about-container {
  display: flex;
  align-items: stretch;
  gap: 10px;
}

.polls-container {
  flex: 1;
  max-width: calc(100vw - 520px);
}

.about-container {
  margin-top: 10px;
}

.qa-container {
  background: white;
  border-radius: 8px;
  padding: 0.75rem;
  margin-top: 10px;
  cursor: pointer;
  flex: 1;
}

.create-poll-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.qa-text {
  margin: 0;
  color: #000000;
  opacity: 0.4;
}

@media only screen and (max-width: 1350px) {
  .polls-about-container {
    flex-direction: column-reverse;
  }

  .polls-container {
    max-width: 90vw !important;
  }
}

@media only screen and (max-width: 700px) {
  .polls-about-container {
    width: 90vw;
    margin-right: 5%;
  }

  .polls-container {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1750px) {
  .polls-create-list-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
