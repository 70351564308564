@import '../../GeneralStyles/colors.scss';
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf');
}

.nft {
  color: white;
  display: flex;
  flex-wrap: wrap;
  .sidebar-nft-container {
    color: white;
    flex-basis: 25%;
    .sidebar-nft {
      border-radius: 15px;
      min-height: calc(100% - 100px);
      text-align: center;
      .content-sidebar-nft-botton {
        background-color: $DarkGlimpse;
        padding: 2em 1.5em;
        border-radius: 13px;
        margin: 2.5em 0;
        text-align: left;
      }
      .title {
        color: white;
        font-weight: bold;
        margin-bottom: 2em;
      }
      .description {
        margin-bottom: 1em;
      }
      .item-list {
        color: white;
        font-weight: bold;
        margin: 1em 0;
        display: flex;
        justify-content: space-between;
      }
      .followers-nft {
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
        font-weight: bold;
      }
      hr {
        opacity: 0.1;
      }
      .vl {
        border-left: 3px solid #212936;
      }
    }
  }

  .content-nft-container {
    padding: 2em;
    color: white;
    flex: 1;
    h3 {
      color: white;
    }
    .title {
      font-size: 28px;
      font-weight: bold;
      font-family: 'Montserrat-Bold', sans-serif;
      margin: 0;
      color: white;
    }
    .sub-title {
      color: white;
      font-size: 20px;
      font-weight: bold;
      font-family: 'Montserrat-Bold', sans-serif;
      margin: 0;
    }
    hr {
      opacity: 0.1;
    }
    .price {
      color: $PinkGlimpse;
      font-size: 28px;
      font-weight: bold;
      font-family: 'Montserrat-Bold', sans-serif;
    }

    .section-2-table-content-nft {
      background-color: #0a1322;
      border-radius: 13px;
      padding: 2em;
    }
    .card-nft {
      padding: 1em 2em;
      width: 100%;
      min-height: 200px;
      background-color: #0a1322;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .container-img-nft {
        padding: 0.5em;
        width: 140px;
        height: 140px;
        border: 1px solid #2b323f;
        border-radius: 73px;
      }
      .title-nft-card-litte {
        font-weight: bold;
        text-align: left;
        color: white;
        margin: 0.5em 0;
      }
      .info-nft-litte {
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
        .vl {
          border-left: 3px solid #212936;
        }
        .price {
          color: $PinkGlimpse;
          font-weight: bold;
          margin-left: 1em;
        }
      }

      .img-card-nft-large {
        width: 100%;
        border-radius: 15px;
      }
      .data-nft-card-large {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
          font-weight: bold;
          font-size: 16px;
        }
        .price {
          color: $PinkGlimpse;
        }
      }
      hr {
        opacity: 0.1;
      }
    }
    .container-heart {
      width: 100%;
      margin-bottom: 0.5em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .img-heart {
        width: 15px;
        margin: 0 0.5em;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .nft-edit-container {
    background-color: $DarkGlimpse;
    border-radius: 15px;
    min-height: calc(100vh - 100px);
    text-align: center;
    padding: 4em 6em;

    .title {
      color: white;
      font-weight: bold;
      text-align: left;
      margin-bottom: 2.5em;
      margin-left: 1em;
    }
    .input-form-container-padding {
      padding: 0 1em;
      input {
        background-color: #0e1a2f;
      }
      select {
        background-color: #0e1a2f;
      }
      textarea {
        background-color: #0e1a2f;
        height: 100px;
        padding: 1em;
      }
    }
    hr {
      opacity: 0.1;
    }
  }
}
