.group-container {
  /* padding: 2rem; */
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 30px;
  min-height: 100vh;
  margin-right: 10%;
}

.group-information-container {
  margin-top: 1rem;
}
