.collection-details-text {
  margin: 0;
  font-size: 400;
  padding: 0;
  color: #263238;
  font-weight: 500;
}

.user-image {
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  /* filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2)); */
  border-radius: 50%;
  object-fit: cover;
}

.user-profile-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.fullName,
.creator-text {
  padding: 0;
  margin: 0;
}
.creator-text {
  color: #8d8d8d;
  font-size: 12px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #677074;
  font-size: 12px;
  margin-top: 15px;
}

.categories-input {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #f4f5f5;
}

.category {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  font-weight: 400;
  color: #f900b7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.remove-icon {
  cursor: pointer;
  color: white;
}

.trending-categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 5px 0;
}

.trending-category-container {
  border-radius: 20px;
  padding: 0.5rem 0.8rem;
  border: 1px solid #999999;
  cursor: pointer;
}

.trending-category-text {
  color: #999999;
  margin: 0;
}
