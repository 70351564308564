.creator-services-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px 12px 0px 0px;
  margin: 20px 0;
}

.creator-services-edit-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #e9ebeb;
  margin-bottom: 10px;
}

.creator-services-text {
  color: #263238;
  margin: 0;
}

.edit-text {
  color: #ef00d7;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  overflow-y: auto;
}
.custom-non-custom-benefits-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 5px;
  row-gap: 0px;
}

.custom-benefits-container {
  background: white;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.5rem;
  border: 1px solid #e9ebeb;
}

.non-custom-benefits-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.5rem;
}
.non-custom-benefits-container img {
  width: 25px;
}

.non-custom-benefit-text-bold {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}

.custom-benefits-text {
  color: #263238;
  margin: 0;
  text-align: left;
}
.custom-benefits-text-bold {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}
.benefits-text {
  color: #263238;
  margin: 0;
  text-align: left;
}
.star-icon {
  width: 25px;
}

.services-container::-webkit-scrollbar {
  width: 5px;
}

.services-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.services-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.no-services-text {
  margin: 0;
  color: #677074;
  text-align: left;
  margin-top: 10px;
}

.no-services-icon {
  width: 120px;
  height: 120px;
  margin-top: 30px;
}
