.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.textarea {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
  margin-top: 10px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.user-post-status {
  display: flex;
  gap: 10px;
}
.user-fullName {
  padding: 0;
  margin: 0;
  color: black;
  font-weight: 500;
}
.post-status-text {
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.post-status-container {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 0.1rem 0.5rem;
  align-self: flex-start;
}

.post-status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.input-container {
  position: relative;
}

.emoji-icon {
  position: absolute;
  bottom: 10px;
  width: 18px;
  right: 20px;
  cursor: pointer;
}

.tag-icon {
  position: absolute;
  bottom: 10px;
  width: 18px;
  right: 80px;
  cursor: pointer;
}

.image-post-icon {
  position: absolute;
  bottom: 10px;
  width: 18px;
  right: 50px;
  cursor: pointer;
}

.emoji-container {
  position: absolute;
  bottom: -50px;
  right: 0px;
  z-index: 100;
}

.fullName-feeling-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.circle {
  background: #f2994a;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.feeling-container {
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  gap: 5px;
}

.feeling-text {
  padding: 0;
  margin: 0;
  color: #f2994a;
}

.fullName-post-status-container {
  display: flex;
  flex-direction: column;
}

.upload-image-container {
  background: rgba(0, 0, 0, 0.02);
  border: 1.5px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 93%;
  height: 225px;
  position: relative;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.4rem;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.upload-image-container::-webkit-scrollbar {
  width: 7px;
}

.upload-image-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.upload-image-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.uploaded-image {
  width: 100px;
  height: 155px;
  object-fit: cover;
  margin-top: 27px;
}

.image-container,
.video-container {
  position: relative;
}

.remove-video-icon-container {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 150px;
  left: 262px;
}

.remove-icon-container {
  cursor: pointer;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  bottom: 150px;
  left: 70px;
}
.remove-video-icon-container::after,
.remove-icon-container::after {
  content: '';
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 7px;
  transform: translate(-50%, -50%);
}

.is-with {
  opacity: 0.4;
  color: black;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
.tags-container {
  display: flex;
  align-items: center;
}

.tagged-user {
  padding: 0;
  margin: 0;
  margin-left: 5px;
}

.mentions-send-message-container {
  position: relative;
}

.mention-container {
  background: white;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  width: 340px;
  z-index: 1000;
  position: absolute;
  top: 100px;
  left: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.mention-container::-webkit-scrollbar {
  width: 7px;
}

.mention-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mention-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.no-users-found {
  color: black;
  opacity: 1;

  padding: 0;
  margin: 0.5rem 0;
}

.message-content {
  min-width: 80px;
}

.my-message-text {
  margin: 0;
}

.topics-container {
  position: absolute;

  background: white;
  border-radius: 10px;
  background: white;
  border-radius: 6px;
  height: 150px;
  overflow-y: auto;
  width: 240px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);

  position: absolute;
  bottom: -130px;
  left: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-topics-found {
  padding: 0.5rem;
  margin: 0;
}

.topics-container::-webkit-scrollbar {
  width: 7px;
}

.topics-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.topics-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.topic-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 0;
  padding: 0.2rem 0.5rem;
}

.topic-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}

.topic-container:hover .topic-text {
  color: white;
}

.topic-text {
  margin: 0;
  padding: 0.4rem;
}
