.upload-requested-nft-container {
  padding: 2rem;
  background: white;
  border-radius: 10px;
  margin-top: 1rem;
  position: relative;
}

.user-image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
  object-fit: cover;
}

.from-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.from-fullName-text {
  color: #000000;
  font-weight: 500;
  opacity: 1;
}

.from-fullName-text,
.received-on-text {
  margin: 0;
}

.received-on-text {
  color: #999999;
  font-size: 12px;
}

.occasion-container {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 0.3rem;
  width: 150px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.occasion-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}

.occasion-image {
  width: 20px;
  margin: 0;
  margin-bottom: 3px;
}

.description-container {
  margin-top: 20px;
}

.description-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
}

.description {
  color: black;
  opacity: 1;
  margin: 0;
  margin-top: 5px;
}

.deliver-order-container {
  margin-top: 30px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-img-container {
  background: #f8f8f8;
  border-radius: 10px;
  border: 1.5px dashed #cbcbcb;
  width: 280px;
  height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-drop-text {
  color: #999999;
  font-weight: 400;
  width: 60%;

  padding: 0;
  margin: 0;
  margin-left: 20px;
}

.nft-image,
.nft-video {
  width: 280px;
  height: 250px;
  object-fit: cover;
}

.upload-img {
  margin-bottom: 10px;
}

.select-text {
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  color: #f900b7;
  border-bottom: 1px solid #f900b7;
}
.upload-image-video-text {
  font-weight: 500;
  padding: 0;
  margin: 0;

  margin-bottom: 20px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.category-text,
.royalte-fee-text,
.description-text {
  margin-top: 20px;
}

.description-container {
  grid-column: 1/-1;
}

.categories-input {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #f8f8f8;
}

.category {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.5rem 0.8rem;

  font-weight: 400;
  color: #f900b7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.remove-icon {
  cursor: pointer;
  color: white;
}

.deliver-button-container {
  text-align: right;
  margin-left: 50%;
}

.uploaded-nft-container {
  margin-top: 30px;
  border-radius: 8px;

  height: auto;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
}

.uploadedNft-title {
  color: black;
  font-weight: 500;

  font-size: 16px;

  margin: 0;
  padding: 0;
}

.finished-status-container,
.pending-status-container,
.rejected-status-container {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 10;
}

.rejected-status-container {
  background: #ff5252;
  color: white;
}

.pending-status-container {
  background: #fff1cc;
}

.finished-status-container {
  background: #ccf0d6;
}

.pending-icon,
.finished-icon,
.rejected-icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rejected-icon {
  width: 8px;
}

.finished-icon {
  width: 18px;
}

.nft-fee-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.nft-fee-text {
  color: #999999;
}

.nft-fee,
.nft-fee-text {
  margin: 0;
  font-weight: 500;
}

.nft-fee {
  color: #f900b7;
}

.content {
  display: flex;
  gap: 30px;
  margin-top: 15px;
  background: #f8f8f8;
}

.nft-image-container {
  width: 100%;
  background: white;
  max-width: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.nft-image {
  width: 300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.full-nft-image {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.title-text {
  font-size: 22px;
  color: black;
  margin-top: 20px;
}

.tags {
  display: flex;
  align-items: center;
  gap: 15px;
}

.tag {
  color: black;
  opacity: 0.4;
}

.nft-description-container {
  height: 50px;
  max-width: 326px;
  overflow-y: auto;
}

.nft-description {
  color: black;
  font-size: 16px;
  width: 97%;
  display: inline-block;
  overflow-wrap: break-word;
}

.nft-description-container::-webkit-scrollbar {
  width: 4px;
}

.nft-description-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.nft-description-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.uploaded-nft-information-container {
  width: 100%;
}

.royalte-fee-container {
  display: flex;
  gap: 10px;
  margin-top: 6px;
  align-items: center;
}
.royalte-fee-text {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.royalte-fee-value {
  color: black;
  opacity: 0.7;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
}

@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .deliver-button-container {
    padding-right: 2rem;
    padding-top: 1rem;
    text-align: left;
    margin-left: 0;
  }

  .upload-img-container {
    width: auto;
  }

  .nft-image {
    width: auto;
  }
}
