.users-list {
  height: 450px;
  padding-right: 6px;
  overflow-y: auto;
}

.users-list::-webkit-scrollbar {
  width: 7px;
}

.users-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.users-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
