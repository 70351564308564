.nft-collections-list {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  margin-top: 15px;
  border-radius: 12px;
}

.no-collections-found-container {
  background: white;
  padding: 0.8rem;
  border-radius: 10px;
  margin-top: 20;
  width: 100%;
}

.no-collections-text {
  margin: 0;
  opacity: 0.7;
}
