.address-text,
.amount-text {
  color: black;
  opacity: 0.4;
  font-weight: 400;
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.amount-text {
  margin-top: 5px;
}

.glimpse-balance,
.available-balance,
.transfer-amount-text,
.remaining-balance-text {
  padding: 0;
  margin: 0;
}

.available-balance,
.transfer-amount-text,
.remaining-balance-text {
  color: black;
  font-weight: 500;
}

.glimpse-balance {
  font-weight: 500;
  font-size: 16px;
  color: #f900b7;
  text-align: center;
}

.glimpse-balance-usd {
  color: black;
  opacity: 0.4;
  margin-left: 5px;
  font-size: 12px;
  text-align: center;
}

.footer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.horizontal-line {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.amount-container {
  margin-top: 10px;
}

.amount-container,
.remaining-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-transfer-amount-container {
  display: flex;
  gap: 10px;
}

.glimpse-balance-fee {
  color: #000000;
  margin: 0;
  opacity: 0.7;
  font-size: 12px;
}

.available-balance-container,
.amount-container,
.remaining-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 20px;
  padding: 1rem;
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 16px;
  flex: 1;
}

.users-container {
  background: white;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  width: 80%;

  position: absolute;
  top: 135px;
  left: 15px;
  overflow-x: hidden;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.users-container::-webkit-scrollbar {
  width: 7px;
}

.users-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.users-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;

  padding: 0;
  margin: 0;
}
