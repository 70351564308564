.voted-by-list {
  height: 400px;
  overflow-y: auto;
  padding-right: 8px;
}

.voted-by-list::-webkit-scrollbar {
  width: 7px;
}

.voted-by-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.voted-by-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
