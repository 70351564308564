.notification {
  background: white;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;

  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.pink-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f900b7;
}

.user-photo {
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #eeeeee;
}

.notification-information {
  display: flex;
  flex-direction: column;
}

.notification-message {
  color: #263238;
  padding: 0;
  margin: 0;
}

.date {
  font-size: 12px;
  color: #8d8d8d;

  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accept-reject-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accept,
.reject {
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 500;
}

.accept {
  color: #00b432;
}

.reject {
  color: #ff5252;
}

.notification-message-read {
  color: black;
  opacity: 0.7;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.extra-icon-container {
  background: #f8f8f8;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
}

.extra-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23px;
}

@media only screen and (max-width: 450px) {
  .notification {
    height: 70px;
    width: 95vw;
  }
}

@media only screen and (max-width: 340px) {
  .notification {
    max-height: 100px;
    width: 95vw;
  }
}
