.nft-collection-container {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
}

.profile-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.profile-photo-creator-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nft-collection-name {
  color: #263238;
  font-weight: 500;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.creator-username {
  color: #263238;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  font-size: 12px;
}

.group-type-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.group-container,
.type-container,
.new-collection-container,
.live-container {
  background: #f4f5f5;
  border-radius: 119px;
  width: 150px;
  padding: 0.75rem 0.5rem;
  text-align: center;
}
.glimpse-collection-link-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.associated-group-text,
.limited-text,
.unlimited-text,
.live-text {
  color: #263238;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 400;
}

.new-collection-text {
  color: #263238;
  margin: 0;
  padding: 0;
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
}

.verified-icon {
  margin-left: 4px;
}

.nft-list {
  height: 50px;
  width: 350px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  flex-direction: row-reverse;
}

.nft-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.next-icon,
.next-icon-2 {
  background: #ffffff;
  border: 2px solid #e9ebeb;
  border-radius: 77px;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  margin-left: -15px;
  z-index: 2;
}
.next-icon-2 {
  display: none;
  width: 0;
}
.right-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}

@media only screen and (max-width: 1340px) {
  .group-container,
  .type-container {
    display: none;
    width: 0;
  }
}

@media only screen and (max-width: 650px) {
  .nft-collection-container {
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
  }

  .nft-list {
    flex-direction: row;
  }

  .next-icon-2 {
    display: block;
    width: 30px;
  }

  .next-icon {
    display: none;
    width: 0;
  }
}

@media only screen and (max-width: 450px) {
  .search-input {
    width: 80%;
  }
}
