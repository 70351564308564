.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
  -ms-transform: scale(1.35);
  -webkit-transform: scale(1.35);
  transform: scale(1.35);
}

.anyone-container,
.only-admins-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
}
.anyone-container p,
.only-admins-container p {
  margin: 0;
  padding: 0;
}
