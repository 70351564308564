.notifications-page-container {
  display: grid;
  /* grid-template-columns: 260px calc(100vw - 620px) 260px; */
  grid-template-columns: 300px 1fr;

  column-gap: 0;
}

/* Added after removal of messages sidebar */
.notifications-container {
  margin: 0 10%;
  max-width: 920px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.notification-text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  padding: 0;
  margin: 0;
}

.go-back-mark-as-read-container {
  display: flex;
  padding: 0.75rem;
  justify-content: space-between;
  align-items: center;
}
.mark-as-read-text {
  text-align: right;
  text-decoration-line: underline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration-line: underline;
  color: #a8adaf;
  font-weight: 400;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  border-radius: 12px;
}

.notification-horizontal-line {
  color: #e9ebeb;
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
}

.notifications-container {
  /* margin-left: 5rem; */
  background: white;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  margin-top: 1rem;
  /* padding-right: 2rem; */
  /* margin-top: 1rem; */
  min-height: 90vh;
}

@media only screen and (max-width: 900px) {
  .notifications-page-container {
    grid-template-columns: 1fr;
  }
  .user-sidebar-container {
    width: 100vw;
    padding: 0;
  }
}

@media only screen and (max-width: 550px) {
  .notifications-container {
    padding: 0;
    margin-top: 1rem;
  }
  .mark-as-read-text {
    margin-right: 1rem;
  }
}

@media only screen and (min-width: 1600px) {
  .notifications-container {
    margin-left: auto;
    margin-right: auto;
    min-width: 1000px;
  }
}

@media only screen and (max-width: 1250px) {
  .messages-sidebar-container {
    display: none;
    width: 0;
  }

  /* .notifications-page-container {
    grid-template-columns: 260px 1fr;
    margin-right: 4%;
  } */
}

@media only screen and (max-width: 900px) {
  .notifications-page-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .notifications-container {
    margin: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .notifications-container {
    margin: 0.25rem 0rem;
  }
}
