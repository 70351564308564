.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thin-text {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
}

.socials-container {
  height: 350px;
  overflow-y: auto;
  padding-right: 6px;
}

.socials-container::-webkit-scrollbar {
  width: 7px;
}

.socials-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.socials-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.social-icon-name-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-icon {
  width: 16px;
}

.social-text {
  padding: 0;
  margin: 0;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 500;
}

.social-details-text {
  color: #263238;
  font-weight: 500;
  margin: 10px 0;
  padding: 0;
}
