.user-container {
  display: flex;
  align-items: center;

  gap: 15px;

  margin-bottom: 15px;
}

.user-image {
  cursor: pointer;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;
}

.user-text {
  cursor: pointer;
  color: black;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.follow-state-container {
  margin-left: auto;
}
