.posts-section-container {
  max-width: 60%;
}

.posts-section-container {
  grid-column: 2/-1;
}

.posts-container::-webkit-scrollbar {
  width: 11px;
}

.posts-container::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 8px;
}

.posts-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.create-post-life-event-container {
  background: rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
  border-radius: 20px;
  padding: 0.1rem;
}

.posts-page-container {
  gap: 30px;
  display: flex;
  align-items: stretch;
}

.about {
  background: white;
  padding: 1rem;
  border-radius: 16px;
}

.no-posts-found-container {
  position: relative;
  height: 400px;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
}

.no-posts-found-icon {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-posts-found-text {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d4d6d7;
  font-weight: 500;
}

@media only screen and (max-width: 1500px) {
  .posts-section-container {
    min-width: 50% !important;
  }
}

@media only screen and (max-width: 1350px) {
  .posts-page-container {
    flex-direction: column;
  }

  .posts-section-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 1150px) {
  .posts-section-container {
    min-width: 50% !important;
  }
}
