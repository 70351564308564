.users-transactions-statistics-cotainer {
  flex: 1;
  display: flex;
  gap: 20px;
}

@media (max-width: 1240px) {
  .users-transactions-statistics-cotainer {
    flex-direction: column;
  }
}
