.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.success-message {
  color: #00b432;
}

.error-message {
  color: #ff5252;
}

.expires-in {
  color: #f900b7;
  border-bottom: 1px solid #f900b7;
  margin: 0;
  margin-top: 10px;
  display: inline-block;
}

.phone-input-container {
  background: #ffffff;
  border: 2px solid #eeeeee;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  position: relative;
}

.arrow-down-icon {
  width: 10px;
}

.phone-select-container {
  display: flex;
  align-items: center;
  margin: 10px;
  height: 100%;
  gap: 10px;
  background: #ffffff;
  width: 85px;
  justify-content: space-between;
}

.input {
  padding: 10px;
  border: none;
  background: #ffffff;
  width: 100%;
}

.input:focus {
  text-decoration: none;
  outline: none;
}

.prefixes-container {
  background: white;
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  width: 70%;

  position: absolute;
  top: 50px;
  left: 0px;
  overflow-x: hidden;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.prefixes-container::-webkit-scrollbar {
  width: 7px;
}

.prefixes-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.prefixes-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.phone-country-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0;
}

.phone-country-container:hover {
  background: #f8f8f8;
}

.country-name,
.phone-text {
  margin: 0;
}

.selected-prefix {
  margin: 0;
}
