.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.transfer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nft-image {
  width: 280px;
  height: 280px;
  border-radius: 16px;
  object-fit: cover;
}

.transfer-to-container {
  position: relative;
}

.users-container {
  background: white;
  border-radius: 6px;
  max-height: 140px;
  overflow-y: auto;
  width: 100%;

  position: absolute;
  bottom: 62px;
  left: 0px;
  overflow-x: hidden;
  z-index: 1000;
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.users-container::-webkit-scrollbar {
  width: 7px;
}

.users-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.users-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.no-users-found,
.loading-users {
  color: black;
  opacity: 1;
  padding: 0;
  margin: 0.5rem 0;
}

.fee-text {
  color: #f900b7;
  margin: 0;
  font-weight: 500;
  margin-top: 10px;
}
