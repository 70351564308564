.user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.requests-list {
  overflow-y: auto;
  height: 400px;
  padding-right: 4px;
}

.requests-list::-webkit-scrollbar {
  width: 7px;
}

.requests-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.requests-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-image {
  cursor: pointer;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;
}

.user-info,
.buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-fullName {
  font-size: 16px;
  margin: 0;
}
