.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 10px;
}

.upload-img-container {
  background: #f8f8f8;
  border-radius: 10px;

  width: 150px;
  height: 110px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 2px solid #eeeeee;
}

.upload-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-drop-text {
  color: #999999;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.upload-file-and-nfts-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nft-image,
.nft-video {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  margin-bottom: 20px;
  border-radius: 6px;
}

.more-nfts-container {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
}

.more-text-container {
  background: rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.more-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 500;
}

.nft-container {
  position: relative;
}
.remove-icon {
  position: absolute;
  top: 50%;
  right: 3%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.remove-icon-container {
  position: absolute;
  top: 12%;
  right: -10px;
  z-index: 10;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
