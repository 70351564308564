.requested-services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 15px;

  margin-top: 30px;
  row-gap: 30px;
}

.no-requested-services-container {
  background: white;
  padding: 1rem;
  border-radius: 10px;
  margin: 20px 0;
}

.no-requested-services-text {
  margin: 0;
}
