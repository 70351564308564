.members-container {
  padding: 1rem;
  background: white;
  border-radius: 16px;
  margin-top: 1rem;
}

.members-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.circle {
  background: rgba(0, 0, 0, 0.4);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}
.number-of-members {
  opacity: 0.4;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
}

.members-text {
  color: black;
  opacity: 1;
  font-size: 16px;
  margin: 0;
}

.new-people-text {
  color: #999999;
  margin-top: 10px;
  font-size: 12px;
}

.horizontal-line {
  opacity: 0.1;
  border-bottom: 1px solid #000000;
  margin-top: 20px;
}
