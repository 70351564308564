@import '../../GeneralStyles/colors.scss';
@import 'antd/dist/antd.css';

.site-layout-content {
  min-height: calc(100vh - 65px);
  padding: 24px;
  background: rgb(17, 33, 58);
  background: linear-gradient(
    90deg,
    rgba(17, 33, 58, 1) 24%,
    rgba(11, 22, 40, 1) 49%,
    rgba(5, 12, 22, 1) 77%
  );
  border-top: 1px solid #46505c;
  padding-bottom: 0;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-layout-header {
  background: #060e19;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #060e19;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.item-menu {
  font-weight: bold;
}

.certified-container {
  width: 100%;
  padding: 60px;
}

@media only screen and (max-width: 560px) {
  .certified-container {
    padding: 0;
  }
}

@media (max-width: 400px) {
  .certified-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.section-1-landing {
  color: white;
  min-height: calc(100vh - 180px);
  padding: 2em;
  .titles-bold {
    margin: 0;
  }
  .left {
    margin: auto;
  }
  .right {
    margin: auto;
  }
  h2 {
    color: white;
  }
  img {
    width: 160px;
  }
  .grid-button {
    span:only-of-type {
      text-align: center;
    }
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

.section-2-landing {
  color: white;
  padding: 2em;
  margin-bottom: 3em;
  h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 2em;
  }

  .box-category {
    margin: 0 1em;
    padding: 1em;
    height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #020913;
    border-radius: 15px;

    img {
      width: 50px;
    }
  }
}

.section-3-landing {
  color: white;
  padding: 2em;
  h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 2em;
    text-align: center;
    span {
      color: $PinkGlimpse;
    }
  }

  .box-create {
    padding: 1em;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    .title-card {
      font-weight: bold;
    }
    img {
      width: 50px;
    }
  }
}

.section-4-landing {
  color: white;
  p {
    color: white;
  }
  padding: 2em;
  h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 2em;
    text-align: center;
    span {
      color: $PinkGlimpse;
    }
  }
  span {
    color: $PinkGlimpse;
  }
  .box-create {
    padding: 1em;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    .title-card {
      font-weight: bold;
    }
    img {
      width: 50px;
    }
  }
  .description-publication {
    h4 {
      color: white;
      font-weight: bold;
      text-align: right;
    }
    h6 {
      color: white;
      text-align: right;
    }
  }
  .buy-publication {
    margin-bottom: 2em;
    h4 {
      color: white;
      font-weight: bold;
      text-align: left;
    }
    h2 {
      color: $PinkGlimpse;
    }
    h6 {
      color: white;
    }
  }
  hr {
    border-width: 0.5px;
    border-color: #79808c;
  }
}

.section-5-landing {
  color: white;
  min-height: 550px;
  padding: 2em;
  background-size: cover;
  margin-left: -24px;
  margin-right: -24px;
  padding: 10em;
  display: flex;
  align-items: center;
  .titles-bold {
    margin: 0;
  }
  .left {
    margin: auto;
  }
  .right {
    margin: auto;
  }
  h2 {
    color: white;
  }
  img {
    width: 160px;
  }
}

@media (max-width: 500px) {
  .section-5-landing {
    padding: 50px;

    h1,
    h2 {
      font-size: 20px;
    }
  }
}

.section-6-landing {
  color: white;
  padding: 2em;
  margin-bottom: 3em;
  h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 2em;
  }
  img {
    width: 80px;
  }
  .box-certified {
    margin: 0 1em;
    padding: 1em;
    height: 180px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #020913;
    border-radius: 15px;

    button {
      width: 70%;
    }
    .user {
      width: 90px;
      margin-top: -50px;
    }
  }
}

.section-7-landing {
  color: white;
  background-color: $DarkGlimpse;
  padding: 1em 10em;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 0;
  .titles-bold {
    margin: 0;
  }
  .left {
    margin: auto;
  }
  .right {
    margin: auto;
  }
  h2 {
    color: white;
  }
  img {
    width: 160px;
  }
}

.section-footer-landing {
  color: white;
  background-color: #02070f;
  padding: 5em 10em;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 0;
  .title {
    font-weight: bold;
    margin: 0.7em 0;
  }
  .titles-bold {
    margin: 0;
  }
  .left {
    margin: auto;
  }
  .right {
    margin: auto;
  }
  h2 {
    color: white;
  }
  img {
    width: 160px;
  }
  .social-media-logo {
    width: 25px;
    height: 25px;
    margin: 0 0.5em;
  }
  .socialMedia-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    grid-auto-rows: minmax(40px, 1fr);
  }
  .footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
    gap: 15px;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.certified-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  grid-auto-rows: 225px;
  margin-top: 2rem;
}

@media (max-width: 760px) {
  .banner-container > h1 {
    font-size: 28px;
  }
}

.eth-icon {
  object-fit: contain;
}
#eth-icon-container {
  width: 100%;
}
@media (max-width: 1400px) {
  .section-7-landing {
    padding: 1em 5em;
  }
}
@media screen and (min-width: 760px) and (max-width: 1120px) {
  #eth-icon-container {
    :nth-child(even) {
      display: none;
    }
  }
}
@media (max-width: 760px) {
  .section-footer-landing {
    padding: 2.5em 2em;
  }
  #eth-icon-container {
    :nth-child(odd) {
      display: none;
    }
  }
  .section-4-landing {
    padding: 0em;
  }
}
@media (max-width: 430px) {
  .btn-white-glimpse {
    margin-top: 15px;
  }
}

@media (max-width: 500px) {
  #eth-icon-container {
    :nth-child(2) {
      display: none;
    }
  }
  .grid-button {
    span:only-of-type {
      display: block;
    }
  }
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  display: none;
}
