.marketplace-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 10px;

  margin-top: 30px;
}
.no-nfts-found-text {
  color: #999999;
}
