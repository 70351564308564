.referrals-page-container {
  display: grid;
  grid-template-columns: 300px 1fr;
}
.referrals-container {
  padding: 0.75rem 0.25rem;
  margin: 0 5%;
  margin-top: 10px;
}

.referrals-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ebeb;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.go-back-icon {
  cursor: pointer;
}

.referrals-text {
  margin: 0;
  padding: 0;
  color: #263238;
}

.referrals-sections {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-left: 25px;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #263238 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;
  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  background: #263238;
  border-radius: 222px 222px 0px 0px;
}

.nft-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
}

.copied-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.4rem;
  width: 40%;
  margin-top: 10px;
}

.copied-text {
  padding: 0;
  margin: 0;
  color: #f900b7;
  font-weight: 500;
}

.copy-icon {
  cursor: pointer;
  width: 14px;
}

.refer-friends-container {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.referral-link-container,
.referral-id-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.referral-link-text,
.referral-link,
.referral-id,
.referral-id-text {
  margin: 0;
  font-size: 12px;
  color: black;
  opacity: 0.4;
}

.referral-link {
  opacity: 1;
  color: black;
  font-size: 12px;
  opacity: 0.4;
}

.referral-link-copy-icon-container,
.referral-id-copy-icon-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.share-text {
  font-size: 12px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.share-icons-container {
  border-radius: 8px;
  display: flex;
  gap: 5px;
  z-index: 100;
}

.share-icon-container {
  display: flex;
  align-items: center;
}

.share-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 900px) {
  .referrals-page-container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 550px) {
  .referrals-container {
    padding: 0;
    margin-top: 1rem;
  }
}
