.sidebar-container {
  background-color: #0a1322;
  color: white;
  flex-basis: 15%;
  padding: 0.5rem;
}
.sidebar-container ul {
  width: 100%;
  padding: 0.5rem;
}

.sidebar-item {
  list-style: none;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  display: inline-block;
  padding: 0.5rem 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.sidebar-item:hover {
  color: #f92baf;
}
