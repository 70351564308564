.image {
  width: 180px;
  height: 180px;
}

.album-photos-container {
  padding: 1rem;
}

.more-icon {
  position: absolute;
  top: 0px;
  right: -30%;
  z-index: 100;
  cursor: pointer;
}

.go-back-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.albums-go-back-text {
  color: black;
  padding-top: 0.5rem;
}

.options {
  background-color: white;
  color: black;
  height: 40px;
  width: 100px;
  padding: 0.4rem;
  position: relative;
  top: 0px;
  right: -10%;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.no-images-container {
  background: white;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  padding: 0.7rem;
  margin: 1rem 0;
}

.no-images-text {
  color: black;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.delete-text {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.album-photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, max-content));
  gap: 7px;
}
