.nft-messages-container {
  grid-column: 2/-1;
  padding: 0.4rem 0.2rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  height: 300px;
}
.textarea {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  border: none;
  border: 1px solid transparent;
  width: 85%;
  resize: none;
  height: 48px;
}

.textarea:focus {
  border: none;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
}

.textarea::-webkit-scrollbar {
  width: 7px;
}

.textarea::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.messages {
  height: 150px;
  overflow-y: scroll;
  width: 100%;
}

.messages::-webkit-scrollbar {
  width: 7px;
}

.messages::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.input-send-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.message-createdAt {
  color: white;
  padding: 0;
  margin: 0;
  text-align: left;
  display: inline-block;
  text-align: right;
  display: block;
  padding: 1px 5px;
  font-size: 10.5px;
  align-self: flex-end;
}

.sender-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
}

.typing-message {
  color: rgb(216, 215, 215);
  padding: 0;
  height: 10px;
}

.other-message-container p {
  width: 100%;
  display: inline-block;
}

.emoji-container {
  position: absolute;
  bottom: 0;
  left: -33.75%;
}
.input-message-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 3em;
}

.input-send-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.input-message-container input {
  background: #253042;
  outline: none;
  border: none;
  border-radius: 100px;
  padding: 0.5rem 0.7rem;
  width: 100%;
  color: white;
  margin-right: 4px;
}

.my-message-container {
  background-color: #f92baf;
  border-radius: 6px;
  padding: 2px 10px;
  padding-top: 10px;
  padding-bottom: 0;
  color: white;
  display: inline-block;
  margin: 5px;
  max-width: 50%;
}

.other-message-container {
  background-color: #1c2a3d;
  border-radius: 6px;
  padding: 2px 10px;
  padding-top: 10px;
  padding-bottom: 0;
  color: white;
  display: inline-block;
  margin: 5px;
  max-width: 50%;
}

.my-message-container .message,
.other-message-container .message {
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%;
}

.my-message-container .message {
  text-align: left;
}

.online {
  background: green;
  border-radius: 50%;
  margin-top: 27.5px;
  margin-left: -4px;
  width: 10px;
  height: 10px;
}

.offline {
  background: #727171;
  border-radius: 50%;
  margin-top: 27.5px;
  margin-left: -4px;
  width: 10px;
  height: 10px;
}

.messages-messagesStatus-container {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.message-status {
  align-self: flex-end;
  padding: 0.1rem 0.3rem 0 0;
}

.chat-actions-container {
  position: relative;
}

.userOption-container {
  display: flex;
  align-items: center;
}
.userImage {
  width: 28px;
  height: 28px;
  border-radius: 100px;
}
.user-container {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.user-img {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.user-container:hover {
  background: #253042;
}

.user-text {
  color: white;
  margin: 10px;
}

@media (max-width: 800px) {
  .my-message-container {
    max-width: 90%;
  }
  .emoji-container {
    position: absolute;
    bottom: -10%;
    left: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .nft-messages-container {
    grid-column: 1/-1;
  }
}

@media only screen and (max-width: 450px) {
  .nft-messages-container {
    width: 90vw;
  }
}

.nft-messages-container ::-webkit-scrollbar {
  width: 7px;
}

.nft-messages-container ::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.nft-messages-container ::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.input-container {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.messages-container {
  height: 240px;
  overflow-y: auto;
}

.sender-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #e9ebeb;
}

.sender-fullName {
  padding: 0;
  margin: 0;
  color: #263238;
}

.message-container {
  display: flex;
  gap: 10px;
}

.no-messages {
  margin: 1rem;
  color: #000000;
  opacity: 0.4;
}

.mention-input-container {
  position: relative;
}

.mention-container {
  background: white;
  border-radius: 6px;
  max-height: 210px;
  overflow-y: auto;
  width: 340px;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));

  position: absolute;
  bottom: 66px;

  left: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.5rem;
}

.user-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid white;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
}

.user-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.4rem;
  cursor: pointer;
}

.user-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
}
.user-container:hover .user-text {
  color: white;
}

.user-text {
  color: black;
  opacity: 1;
  font-weight: 400;

  padding: 0;
  margin: 0;
}

.mention-container::-webkit-scrollbar {
  width: 7px;
}

.mention-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.mention-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.no-users-found {
  color: black;
  opacity: 1;

  padding: 0;
  margin: 0.5rem 0;
}
.mention-text {
  color: black !important;
  opacity: 1 !important;
  font-weight: 500;
  cursor: pointer;

  font-size: 12px;
  padding: 0;
  margin: 0;
}

.message {
  color: #677074;
  font-weight: 400;
  font-size: 12px;
  padding: 0;
  margin: 0;
  overflow-wrap: anywhere;
}

.date {
  font-size: 12px;
  font-weight: 400;
  color: #a8adaf;
}

.no-users-found {
  color: black;
  opacity: 1;
}

.no-messages-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.4rem 0.2rem;
  height: 240px;
}
