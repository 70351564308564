.share-slider-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 20px;
}

.capped-description {
  padding: 0;
  margin: 0 auto;
  font-size: 12px;
  color: #999999;
}

.schedule-live-date {
  margin: 0;
}
.schedule-live-date-slidder-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-input,
.date-input:focus {
  border: 1px solid #999999;
  padding: 0.3rem;
  border-radius: 4px;
  outline: white;
}
.groups-list {
  overflow-y: auto;
  max-height: 300px;
}

.no-groups-found {
  margin: 0;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.cover-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.group-name {
  color: #263238;
  font-weight: 500;
  margin: 0;
}

.group {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0.6rem;
  margin-left: -0.6rem;
  transition: all 0.2s ease;
}

.selected-group {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 8px 0px 0px 8px;
  margin-left: 0rem;
}

.group-list {
  height: 500px;
  overflow-y: auto;
}

.group-list::-webkit-scrollbar {
  width: 7px;
}

.group-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-list > * {
  margin-top: 15px;
}

.no-groups-found {
  opacity: 1;
  margin-top: 20px;
}

.thin-text {
  color: black;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.slider-text {
  margin: 0;
  color: #a8adaf;
  font-size: 12px;
}

.associated-groups-text {
  font-size: 16px;
  margin-top: 10px;
  color: #677074;
  font-weight: 400;
  font-size: 14px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.1);
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

.nft-gated-community-text {
  color: #677074;
  padding: 0;
  margin: 10px 0;
  width: 100%;
  font-size: 12px;
}

.createdAt {
  color: #a8adaf;
  padding: 0;
  margin: 0;
  font-size: 12px;
}
