.buying-method-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.glimpse-icon-container,
.credit-card-icon-container,
.usd-icon-container {
  padding: 3rem 1rem;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background: #fde5fb;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
}

.glimpse-icon,
.credit-card-icon {
  height: 40px;
}

.glms-text,
.credit-card-text {
  color: #ef00d7;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
}
