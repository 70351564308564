.metamask-nft-container {
  border-radius: 16px;
  width: 330px;
}

.nft-image {
  width: 330px;
  height: 360px;
  object-fit: cover;
  border-radius: 16px 16px 0px 0px;
}

.nft-info-container {
  background: #ffffff;
  border-radius: 0px 0px 16px 16px;
  padding: 1rem;
}

.nft-name {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.collection-chain-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.collection-text {
  color: #999999;
}

.collection-value {
  color: #f900b7;
}

.collection-text,
.collection-value {
  font-size: 12px;
  margin: 0;
}

.chain-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chain-text {
  margin: 0;
  color: black;
  font-weight: 500;
}

.chain-symbol {
  height: 18px;
}
