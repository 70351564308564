.footer {
  display: flex;
  align-items: center;
  gap: 20px;
}
.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}
