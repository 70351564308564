.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 440px;
  min-width: 500px;
  background: white;
  border-radius: 10px;
  z-index: 1000000;
}

.modal::-webkit-scrollbar {
  width: 7px;
}

.modal::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.modal::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

@media only screen and (max-width: 700px) {
  .modal {
    min-height: 90%;
    min-width: 90%;
  }
}

.title {
  font-weight: 400;
  color: #263238;
  padding: 1rem;
  margin: 0.5rem 0;
  margin-bottom: 0;
}

.modal-content {
  padding: 1rem;
  color: black;
  min-height: 370px;
  overflow-y: auto;
  padding-top: 0.2rem;
}

.modal-content::-webkit-scrollbar {
  width: 9px;
}

.modal-content::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modal-footer {
  position: absolute;
  bottom: 2.5%;
  right: 2.5%;
  display: block;
  align-items: flex-end;
}

.close-icon-container {
  width: 25px;
  height: 25px;
  position: relative;
  margin: 1.5rem 0;
  margin-right: 1rem;
  cursor: pointer;
}

.close-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  padding: 0;
  margin: 0;
}

.go-back-title {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 1rem;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  margin-top: -10px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 450px) {
  .modal {
    max-width: 95vw;
  }
}
