.no-followings {
  opacity: 0.7;
  font-weight: 500;
}

.followings-container {
  height: 500px;
  overflow-y: auto;
  padding-right: 0.1rem;
}

.followings-container::-webkit-scrollbar {
  width: 7px;
}

.followings-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.followings-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
