.user-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 0.5rem;
}
.user-container:not(:last-child) {
  margin-bottom: 1rem;
}
.user-container:hover {
  background-color: #253042;
}

.user-image {
  height: 45px;
  width: 45px;
  border-radius: 100px;
}

.user-text {
  margin-right: auto;
  color: white;
  margin-left: 20px;
}
.show-to-selected-followers-text,
.select-followers-text {
  padding: 0;
  margin: 0;
}

.select-followers-text {
  font-weight: 500;
  color: black;
}

.show-to-selected-followers-text {
  opacity: 0.7;
}

.input-search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  margin-left: -35px;
  cursor: pointer;
  width: 18px;
}

.followers-container {
  height: 380px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}
.followers-container::-webkit-scrollbar {
  width: 7px;
}

.followers-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.followers-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.follower-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.follower-photo {
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  filter: drop-shadow(0px 1.5px 3px rgba(0, 0, 0, 0.05));
}

.add-container {
  border-radius: 50%;
  padding: 0.1rem;
  width: 22px;
  height: 22px;
  margin-left: auto;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.follower-fullName {
  color: #263238;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
}

.follower-username {
  color: #a8adaf;
  margin: 0;
  padding: 0;
}
