.cover-photo-container {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.13) 100%
  );
  border-radius: 0px;
  height: 120px;
  position: relative;
}

.cover-camera-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  cursor: pointer;
}

.cover-photo {
  height: 120px;
  width: 100%;
  object-fit: cover;
}

.photo-container {
  background: #ffffff;
  border: 2px solid #e9ebeb;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
}

.photo-camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  cursor: pointer;
}

.photo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.intro-container {
  margin-top: 60px;
}

.intro-text {
  color: #677074;
  margin: 0;
}
