.all-events-container {
  background: #0a1322;
  padding: 30px;
  color: white;
}

.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-events-heading {
  color: #f92baf;
  font-weight: bold;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0;
}

.pagination-col {
  font-weight: bold;
}
.reports-container {
  background: #0a1322;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  padding-bottom: 0;
  margin-top: 25px;
  width: 1000px;
  overflow-x: scroll;
}

.reports-container::-webkit-scrollbar {
  width: 8px;
}

.reports-container::-webkit-scrollbar-track {
  background: #b6b6b6;
  border-radius: 8px;
}

.reports-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
