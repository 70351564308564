.nft-content-container {
  margin-top: 1rem;
  display: flex;
  align-items: stretch;
  gap: 20px;
  flex: 1;
}

.nft-image-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.nft-content-page-container {
  margin-top: 1rem;
  display: flex;
  align-items: stretch;
  gap: 20px;
  padding: 0.75rem 0.25rem;
  margin: auto;
  margin-top: 10px;
}
.nft-image-container {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  border: 1px solid #e9ebeb;
  overflow: hidden;
}

.nft-image {
  width: 100%;
  height: 100%;
  max-width: 500px;
  min-width: 400px;
  object-fit: cover;
  border-radius: 16px;
  border: 1px solid #e9ebeb;
}

.watchlist-likes {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  width: 100%;
}

.likes-container,
.watchlist-container {
  border-radius: 10px;
  padding: 0.4rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}

.watchlist-container {
  position: relative;
  cursor: pointer;
}
.share-text {
  font-size: 12px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.share-icons-container {
  position: absolute;
  top: -225px;
  right: 0px;
  padding: 1rem;
  background: white;
  border: 1px solid #e9ebeb;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 100;
}

.number-likes,
.number-watchlist {
  margin: 0;
  padding: 0;
  color: white;
  width: 15px;
}

.nft-actions-container {
  border-radius: 16px;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e9ebeb;
}

.nft-price {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  margin-top: 5px;
  color: #ef00d7;
}

.usd-price {
  color: #263238 !important;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  margin-top: 5px;
}

.price-text {
  margin: 0;
  margin-top: 5px;
  color: #a8adaf;
}

.royalte-fee-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.royalte-fee-text,
.royalte-fee-value {
  font-weight: 400;
  margin: 0;
  color: #677074;
}
.blockchain-text {
  color: #677074;
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
}

.horizontal-line {
  border-bottom: 1px solid #e9ebeb;
  width: 100%;
  height: 5px;
}

.buy-add-to-watchlist-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.title-tags-collection-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-tags-container {
  padding: 1rem;
  flex: 1;
}
.title-text {
  color: #263238;
  font-weight: 600;
  margin: 0;
}

.author-firstName,
.owner-firstName {
  color: #a8adaf;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.creator-text,
.owner-text {
  color: #677074;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}

.author-owner-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
  object-fit: cover;
}

.author-owner-container {
  display: flex;
  gap: 30px;
  position: relative;
}

.nft-information-container {
  flex: 1;
}

.creator-container,
.owner-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.nft-collection {
  background: #f8f8f8;
  border-radius: 16px;
}

.nft-collection-text,
.nft-collection-text:hover {
  color: #a8adaf;
  margin: 0;
}

.nft-collection-name,
.nft-collection-name:hover {
  color: #263238;
  font-weight: 500;
  margin: 0;
}

.nft-collection-profile-photo {
  border: 1px solid #e9ebeb;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.nft-collection-profile-photo-name-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.tags {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.tag {
  color: #a8adaf;
  padding: 0.25rem 0.4rem;
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 54px;
}

.description-container {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.description {
  font-size: 16px;
  width: 97%;
  display: inline-block;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  color: #677074;
}

.description-container::-webkit-scrollbar {
  width: 4px;
}

.description-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.description-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.more-options-container {
  margin-left: auto;
}
.more-options {
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 1001;
  width: 200px;
  background: white;
  padding: 0.25rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.report-option {
  color: #ff5252;
  margin: 0;
  cursor: pointer;
  padding: 0.4rem;
}

.option {
  cursor: pointer;
  padding: 0;
  margin: 0;
  padding: 0.4rem;
}

.nft-details-container {
  padding: 1rem;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #263238 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.section-text {
  color: #677074;
  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 3px;
  background: #263238;
  border-radius: 222px 222px 0px 0px;
}

.nft-sections-container {
  display: flex;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  flex: 1;
}

.horizontal-line {
  width: 100%;

  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

.nft-information-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 480px;
}

.nft-info-container {
  align-self: stretch;
  width: 100%;
  flex: 1;
  height: 100%;
  margin: auto;
}

.nft-messages-details-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  width: 480px;
}

.claimable-container {
  background: linear-gradient(
    180deg,
    rgba(255, 124, 220, 0.2) 0%,
    rgba(249, 0, 183, 0.2) 100%
  );
  border-radius: 16px;
  color: #f900b7;
  padding: 0.5rem 2rem;
  text-align: center;
}

.claimable-text {
  margin: 0;
}

.more-icon-container {
  background: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #e9ebeb;
  position: relative;
  cursor: pointer;
}
.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.benefits-unlockable-content-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.benefits-container,
.unlockable-content-container {
  background: linear-gradient(225deg, #ff057c 0%, #7c64d5 47.87%, #4cc3ff 100%);
  border-radius: 12px;
  width: 100%;
  margin-bottom: 15px;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
}
.benefits-container p,
.unlockable-content-container p {
  margin: 0;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.custom-non-custom-benefits-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  column-gap: 10px;
  row-gap: 0px;
}

.custom-benefits-container {
  background: white;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.5rem;
}

.non-custom-benefits-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 0.5rem;
}
.non-custom-benefits-container img {
  width: 25px;
}

.non-custom-benefit-text-bold {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}

.custom-benefits-text {
  color: #263238;
  margin: 0;
  text-align: left;
}
.custom-benefits-text-bold {
  background: linear-gradient(
    103.45deg,
    #ff7ea1 4.26%,
    #f643c6 30.75%,
    #a074f2 55.56%,
    #71b9d8 85%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
  margin: 0;
}
.benefits-text {
  color: #263238;
  margin: 0;
  text-align: left;
}
.star-icon {
  width: 25px;
}

.only-access-text {
  color: white;
  font-size: 10px;
  margin: 0;
}

@media only screen and (max-width: 1300px) {
  .nft-content-page-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 900px) {
  .nft-content-container {
    flex-direction: column;
  }

  .nft-content-page-container {
    flex-direction: column;
  }
  .nft-image {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .title-container {
    flex-direction: column;
  }

  .nft-image,
  .nft-image-container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .nft-image-container {
    width: 100%;
  }

  .nft-messages-details-container {
    width: 100%;
  }

  .nft-actions-container {
    width: 100%;
  }

  .buy-add-to-watchlist-container {
    flex-direction: column;
  }

  .benefits-unlockable-content-container {
    flex-direction: column;
  }

  .benefits-container,
  .unlockable-content-container {
    flex: 1;
    width: 100%;
  }
}

@media only screen and (min-width: 1800px) and (max-width: 2500px) {
  .nft-image {
    width: 1000px;
  }
  .nft-messages-details-container {
    width: 600px;
  }

  .author-owner-container {
    width: 580px;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2800px) {
  .nft-image {
    width: 1000px;
  }

  .nft-messages-details-container {
    width: 100%;
  }

  .nft-messages-details-container {
    width: 800px;
  }
  .author-owner-container {
    width: 780px;
  }
}
