.feed-content-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}

.posts-container {
  width: 530px;
}

.featured-all-filter-container {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 0.25rem 0;
  margin-bottom: 20px;
  margin-left: auto;
}

.featured-filter-container,
.all-filter-container,
.collectors-filter-container {
  background: #ffffff;
  border: 1px solid #e9ebeb;
  border-radius: 54px;
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 1rem;
  width: 100%;
  margin: 0;
}

.featured-filter-container p,
.all-filter-container p,
.collectors-filter-container p {
  color: #a8adaf;
  margin: 0;
  padding: 0;
}
.checkbox {
  accent-color: #f900b7;
  cursor: pointer;
}

.featured-checked,
.all-checked,
.collectors-checked {
  background: #fde5fb;
  border-radius: 54px;
  border: 1px solid #fde5fb;
}

.featured-checked p,
.all-checked p,
.collectors-checked p {
  color: #ef00d7 !important;
}

.top-creators-container {
  margin: 20px 0;
  width: 200px;
}

@media only screen and (max-width: 1440px) {
  .top-creators-container {
    width: 190px;
  }

  .posts-container {
    width: 500px;
  }
}

@media only screen and (max-width: 1400px) {
  .top-creators-container {
    display: none;
    width: 0;
  }

  .posts-container {
    width: 100%;
    max-width: 800px;
  }
}

@media only screen and (max-width: 1150px) {
  .posts-container {
    width: 100%;
    max-width: 800px;
  }
}
