.user-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.user-image {
  cursor: pointer;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border: 2px solid #eeeeee;
  border-radius: 50%;

  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
}

.user-text {
  cursor: pointer;
  color: black;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.follow-state-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-container {
  background: rgba(249, 0, 183, 0.2);
  border-radius: 16px;
  padding: 0.1rem 0.5rem;
}

.admin-text {
  color: #f900b7;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
}
