.groups-as-admin-container {
  margin-top: 20px;
}

.groups-list {
  overflow-y: auto;
  max-height: 300px;
}

.no-groups-found {
  margin: 0;
}

.groups-list::-webkit-scrollbar {
  width: 7px;
}

.groups-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.groups-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-name,
.last-active {
  padding: 0;
  margin: 0;
}
.group-name {
  color: black;
  opacity: 1;
  font-weight: 500;
}

.last-active {
  font-size: 12px;
  color: #8d8d8d;
}

.cover-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.group {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0.6rem;
  margin-left: -0.6rem;
  transition: all 0.2s ease;
}

.selected-group {
  background: rgba(249, 0, 183, 0.05);
  border-radius: 8px 0px 0px 8px;
  margin-left: 0rem;
}

.group-list {
  height: 500px;
  overflow-y: auto;
}

.group-list::-webkit-scrollbar {
  width: 7px;
}

.group-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.group-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.group-list > * {
  margin-top: 15px;
}

.no-groups-found {
  opacity: 1;
  margin-top: 20px;
}

.thin-text {
  color: #677074;
  margin: 0;
  padding: 0;
}

.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
