.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content-text,
.uploaded-file-text {
  margin: 0;
  color: #263238;
  opacity: 0.7;
}

.uploaded-text {
  opacity: 1 !important;
  font-weight: 500;
  color: black;
}

.file-container {
  margin: 10px 0;
}

.uploaded-image {
  object-fit: cover;
}
