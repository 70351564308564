.categories-subCategories-container {
  padding: 1rem;
  background: #0a1322;
  color: white;
  flex: 1;
}

.heading {
  color: white;
  text-align: center;
  font-weight: bold;
  margin: 0.5rem;
}
