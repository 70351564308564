.user-post-container {
  display: grid;
  grid-template-columns: 300px calc(100vw - 640px) 300px;
  min-height: 100vh;
}

.post-container {
  max-width: 800px;
  margin: 1rem auto;
}

.user-sidebar-container {
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  .messages-sidebar {
    display: none;
    width: 0;
  }

  .user-post-container {
    grid-template-columns: 300px 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .user-post-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
