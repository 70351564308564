.no-chats {
  padding: 1rem 0;
  opacity: 0.7;
}

.chat-list {
  height: 560px;
  overflow-y: auto;
}

.chat-list::-webkit-scrollbar {
  width: 7px;
}

.chat-list::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.chat-list::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

@media only screen and (max-width: 1400px) {
  .chat-list {
    grid-row: 1/2;
  }
}

@media only screen and (max-width: 1000px) {
  .chat-list {
    grid-column: 1/-1;
    grid-row: 1/2;
  }
}
