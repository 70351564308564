.user-feed-container {
  display: grid;
  grid-template-columns: 260px calc(100vw - 640px) 300px;
  column-gap: 30px;
  min-height: 100vh;
}

.about-container {
  padding: 1rem;
  background: white;
  border-radius: 14px;
  margin: 1rem;
  position: fixed;
  width: 360px;
  top: 60px;
  right: 0;
  height: 90%;
  overflow-y: auto;
  margin-right: 1rem;
  margin-top: 2rem;
}

.username {
  padding: 0;
  margin: 0;
  margin-top: -7px;
  color: #b2b2b2;
}

@media only screen and (max-width: 1400px) {
  .about-container {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
}

.about-container::-webkit-scrollbar {
  width: 7px;
}

.about-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.about-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.cover-photo {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.no-cover-photo-container {
  width: 100%;
  height: 300px;
  background: white;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.2));
  border-radius: 14px;
}

.user-feed-content-container {
  position: relative;
}

.user-image {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.user-information {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 2;
  width: 100%;
  margin-left: 0px;
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  border-radius: 10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 10px;
}
.user-fullName {
  font-weight: 500;
  color: white;
  margin-top: 20px;
}

.followers-following-container {
  background: transparent;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(196, 196, 196, 0.05);
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 2rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  gap: 30px;
  height: 70px;
  margin-left: auto;
}

.number-followers-text,
.number-followings-text {
  font-weight: 500;
  color: #f900b7;
  font-size: 16px;
  z-index: 10;

  padding: 0;
  margin: 0;
}
.followers-text,
.following-text {
  opacity: 0.7;
  color: black;
  font-size: 12px;

  padding: 0;
  margin: 0;
}

.follow-message-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  margin-right: 20px;
}

.follow-btn,
.following-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background: #fde5fb;
  cursor: pointer;
  border: 1px solid white;
}

.following-btn {
  background: #e9ebeb;
  border: 1px solid white;
}

.message-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #ef00d7;
  background: white;
}

.follow-text,
.message-text {
  padding: 0;
  margin: 0;
  color: white;
}
.follow-icon,
.message-icon {
  padding: 0;
  margin: 0;
  margin-top: 1px;
  object-fit: cover;
}

.follow-icon,
.message-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* z-index: 2; */
}
.follow-icon {
  width: 17px;
  object-fit: cover;
  top: 49%;
  left: 53%;
}

.vertical-line {
  transform: rotate(180deg);
  width: 2px;
  height: 80%;
  background: #eaeaea;
}

.followers-container,
.followings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.message-container {
  cursor: pointer;
}

.request-nft-container {
  border-radius: 26px;
  padding: 0.5rem 1rem;
  background: #ef00d7;
  border: 1px solid #ffffff;
}

.menu-options-container {
  background: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ef00d7;
  cursor: pointer;
  position: relative;
}

.up-hand-icon {
  margin-bottom: 4px;
}

.request-nft-container p {
  color: white;
  margin: 0;
}

.message-text {
  color: #f900b7;
}

.message-icon {
  margin: 0;
}

.section-container {
  display: inline-block;
  cursor: pointer;
}

.selected-section {
  color: #263238 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}

.section-text {
  color: black;
  opacity: 0.4;

  padding: 0;
  margin: 0;
}

.selected-border {
  width: 100%;
  height: 4px;
  color: #263238;
  border-radius: 222px 222px 0px 0px;
}

.user-sections-container {
  display: flex;
  align-items: center;
  gap: 28px;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: 30px;
  z-index: 100;
  margin-left: 30px;
  width: 100%;
}

.horizontal-line {
  width: 100%;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-top: -1px;
}

.section-content {
  margin-top: 10px;
}

.introduction-text {
  color: black;
  font-weight: 400;
  font-size: 18px;
}

.follow-more-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
}

.recycle-bin {
  color: #ff5252;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.option {
  cursor: pointer;
  color: #ff5252;
  padding: 0;
  margin: 0;
  padding: 0.4rem;
}
.more-options {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 10;
  width: 200px;
  background: white;
  padding: 0.25rem;
  border: 1.5px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.user-image-fullName-container {
  display: flex;
  gap: 20px;
}

.unblock-user-container {
  background: #ef00d7;
  border: 1px solid #ffffff;
  border-radius: 26px;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selected-section-down-icon-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.selected-section-down-icon-container p {
  margin: 0;
  padding: 0;
  color: #677074;
}
.selected-container-text {
  font-weight: 500;
  color: #263238;
}

.listings-purchases-collections-container {
  position: relative;
  margin-left: auto;
  margin-right: 30px;
}
.listings-purchases-collections-options-container {
  position: absolute;
  top: 25px;
  left: 0;
  background: white;
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
  z-index: 2;
}

.user-feed-content-container {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

@media only screen and (max-width: 1205px) {
  .messages-sidebar-container {
    width: 0;
    display: none;
  }
  .user-sections-container {
    margin-left: 0;
    margin-right: 0;
  }

  .user-feed-container {
    grid-template-columns: 300px calc(100vw - 330px);
    column-gap: 0;
  }

  .user-information {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 900px) {
  .user-feed-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
}

@media screen and (max-width: 550px) {
  .user-information {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    position: absolute;
    top: 130px;
    left: 0;
    z-index: 2;
    width: 100%;
    margin-left: 10px;
    flex-wrap: wrap;
  }
}
