.liked {
  cursor: pointer;
  color: #677074;
  font-weight: 500;
  font-size: 12px;
  margin-top: 3px;
  margin-right: 15px;
}
.like,
.reply {
  cursor: pointer;
  font-size: 12px;
  margin-top: 3px;
  color: #a8adaf;
}

.like {
  margin-right: 15px;
}
