.someone-else-text,
.myself-text {
  margin: 0;
  color: #000000;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 500;
}

.who-is-for-container,
.who-is-from-container,
.what-is-occasion-for-container,
.title-section-container,
.what-nft-type-container {
  background: white;
  border-radius: 12px;
  padding: 2rem;
}

.who-is-from-container,
.what-is-occasion-for-container,
.what-nft-type-container {
  margin-top: 1rem;
}

.someone-else-myself-container,
.occasions-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.someone-else-container,
.myself-container,
.occasion-container {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 1rem;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;
  margin-top: 10px;
  cursor: pointer;
}

.to-text {
  color: #000000;
  margin: 0;
  opacity: 0.4;
  font-size: 12px;
  font-weight: 500;
  margin-top: 20px;
}

.selected-occasion {
  border: 1.5px solid #f900b7;
  box-shadow: 0px 10px 40px rgba(249, 0, 183, 0.1);
  border-radius: 16px;
}

.actions-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  margin-left: 50%;
  gap: 10px;
}

.nft-for-options {
  position: absolute;
  bottom: -300px;
  left: 0;
  padding: 1rem;
  width: 70%;
  height: 300px;
  overflow-y: auto;
  background: white;
  border-radius: 8px;
  z-index: 10;
}
.nft-for-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0.5rem;
}
.user-option-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem;
  cursor: pointer;
}

.nft-for-options::-webkit-scrollbar {
  width: 7px;
}

.nft-for-options::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.nft-for-options::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}

.user-option-container:hover {
  background: linear-gradient(96.77deg, #ff63ef -10.47%, #ef00d7 127.35%);
  box-shadow: 0px 4px 50px rgba(255, 99, 239, 0.5);
  border-radius: 6px;
  color: white;
}

.user-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
}

.user-fullName {
  margin: 0;
}

.selected-container {
  border: 1.5px solid #f900b7;
  box-shadow: 0px 10px 40px rgba(249, 0, 183, 0.1);
  border-radius: 16px;
}

.new-request-for-text {
  color: #000000;
  opacity: 1;
  font-weight: 500;
  margin: 0;
}

.new-request-for-container {
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 1rem;
  margin: 20px 0;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
  margin-top: 15px;
}

.title-section-container {
  margin: 20px 0;
}

.nft-type-text {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  .actions-container {
    margin-left: 0;
  }
}
