.album-container {
  background-color: white;
  overflow: hidden;
  height: 180px;
  width: 180px;
  text-align: center;
  border: 1px solid #ffffff;
  position: relative;
  cursor: pointer;
}

.user-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.album-name-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 64px;
  padding: 0.3rem;
}

.album-name {
  font-weight: 500;
  color: white;
  opacity: 1;
  margin: 0;
}

.empty-image {
  background-color: #eee;
}
