.footer {
  display: flex;
  gap: 10px;
}
.current-offer-container,
.new-offer-container {
  display: flex;
  justify-content: space-between;
}
.new-offer-container {
  margin-top: 20px;
}

.offer-price-container {
  margin: 0;
}

.offer-price-text {
  color: #f900b7;
  margin-right: 10px;
  font-weight: 500;
}

.usd-price {
  color: #000000;
  opacity: 0.4;
  font-weight: 500;
}

.label {
  padding: 0;
  margin: 0;
  font-weight: 500;
  opacity: 0.4;
  color: black;
  font-size: 12px;
}
