.no-followers {
  opacity: 0.7;
  font-weight: 500;
}

.followers-container {
  height: 500px;
  overflow-y: auto;
}

.followers-container::-webkit-scrollbar {
  width: 7px;
}

.followers-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 4px;
}

.followers-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 4px;
}
