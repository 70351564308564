.filter-container {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0.75rem 1rem;
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 60%;
  transform: translateY(-70%);
  z-index: 3;
}
.input-container {
  position: relative;
  width: 100%;
}

.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 330px));
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  row-gap: 30px;
}

.sorting-container {
  background: #f8f8f8;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  flex-basis: 20%;
  padding: 0.65rem;

  position: relative;
  border-radius: 6px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
  z-index: 7;
}

.sort-options {
  position: absolute;
  top: 45px;
  left: 0;
  background: white;
  border-radius: 6px;
  width: 100%;
  padding: 0.5rem;
  z-index: 1000;
  overflow: hidden;
}

.sorting-text {
  margin: 0;
  padding: 0;
  width: 100%;

  color: black;
  font-weight: 500;
  font-size: 14px;
}

.sort-option {
  color: black;
  opacity: 0.7;

  padding: 0;
  margin: 0;
  padding: 0.4rem;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 700px) {
  .nft-list {
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
  }

  .filter-container {
    flex-wrap: wrap;
  }

  .sorting-container {
    width: 100%;
    flex: 1;
  }
}
