.chat-container {
  background: white;
  border-radius: 16px;
  margin-top: 1rem;
  display: flex;
  border: 1px solid #e9ebeb;
}

@media only screen and (max-width: 600px) {
  .chat-container {
    flex-direction: column;
  }
}
