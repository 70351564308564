.brand-item-container {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  max-width: 330px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #e9ebeb;
}

.brand-item-image {
  width: 330px;
  height: 360px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
}

.brand-item-information-container {
  background: linear-gradient(180deg, rgba(38, 50, 56, 0) 0%, #263238 100%);
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 5px;
}
.brand-item-title {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.brand-item-owner-container {
  display: flex;
  align-items: center;
  gap: 7px;
}

.price-container {
  background: rgba(38, 50, 56, 0.8);
  border-radius: 42px;
  width: 80px;
  height: 43px;
  position: relative;
  margin-left: auto;
}

.usd-price {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.owner-text {
  color: #999999;
  font-size: 12px;
}

.owner-fullName {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
}

.owner-text,
.owner-fullName {
  padding: 0;
  margin: 0;
}

.owner-photo {
  border-radius: 50%;
  border: 0.5px solid #ffffff;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.owner-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.brand-item-title {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
