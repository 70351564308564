.footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.prices-table {
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.services-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 345px;
  overflow-y: auto;
  padding-right: 3px;
}

.services-container::-webkit-scrollbar {
  width: 6px;
}

.services-container::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 3px;
}

.services-container::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 3px;
}

.prices-table p {
  color: #677074;
  padding: 0;
  margin: 0;
}
.table-horizontal-line {
  grid-column: 1/-1;
  opacity: 0.1;
  border-bottom: 1.5px solid #000000;
  margin-bottom: 5px;
}

.service {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 15px;
  align-items: center;
  border-bottom: 1px solid #e9ebeb;
  padding-bottom: 5px;
}

.service-text {
  color: #677074;
  margin: 0;
}
